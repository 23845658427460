import { Link } from 'react-router-dom'
import Layout from '../components/Layout'
/*
interface Session {
    sid: string
    dt: {
        value: string
    }
    path: string[]
    time: {
        value: string
    }[]
    vars: string[]
} */

export default function Path() {
    async function handleSubmit(e: any) {
        e.preventDefault()
    }

    return (
        <Layout>
            <div className="bg-body-light">
                <div className="content content-full">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                        <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                            Unsubscribe from our WhatsApp service
                        </h1>
                        <Link to={{ pathname: '/phrase' }} className="btn btn-light">
                            <i className="si si-action-undo"></i>{' '}
                            <span className="d-none d-sm-inline-block">Back</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <div className="col-lg-5 col-xl-5">
                    <div className="block-content">
                        <form className="js-validation-signin" onSubmit={handleSubmit}>
                            <div className="form-group form-row">
                                <label htmlFor="example-text-input">Phone</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="(205) 123-4567"
                                    id="name"
                                    name="name"
                                />
                            </div>

                            <div className="form-group form-row">
                                <label htmlFor="example-text-input">Reason</label>
                                <select className="form-control" id="reason" name="reason">
                                    <option value="PT-BR">
                                        I no longer wish to receive messages in my whatsapp
                                    </option>
                                    <option value="EN-US">My number changed</option>
                                    <option value="EN-US">Other</option>
                                </select>
                            </div>

                            {/* <div className="form-group form-row">
                <label htmlFor="terms">
                <input type="checkbox" id="terms" name="terms" className="mr-2"/>
                  I aggre with the <a href="#">terms and conditions</a> and would like to receive messages
                </label>
              </div> */}

                            <div className="form-group form-row">
                                <button type="submit" className="btn btn-hero-info mt-2 mb-2">
                                    Unsubscribe
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <div className="d-flex justify-content-center">
        <div className="col-lg-5 col-xl-5">
          <div className="block-content">
            <form className="js-validation-signin" onSubmit={handleSubmit}>
              <div className="form-group form-row">
                <label htmlFor="example-text-input">Name</label>
                <input type="text" className="form-control" placeholder="John Doe" id="name" name="name" />
              </div>

              <div className="form-group form-row">
                <label htmlFor="example-text-input">E-mail</label>
                <input type="email" className="form-control" placeholder="john@doe.com" id="email" name="email" />
              </div>

              <div className="form-group form-row">
                <label htmlFor="example-text-input">Phone</label>
                <input type="tel" className="form-control" placeholder="(205) 123-4567" id="name" name="name" />
              </div>

              <div className="form-group form-row">
                <label htmlFor="terms">
                <input type="checkbox" id="terms" name="terms" className="mr-2"/>
                  I aggre with the <a href="#">terms and conditions</a> and would like to receive messages
                </label>
              </div>

              <div className="form-group form-row">
                <button type="submit" className="btn btn-hero-info mt-2 mb-2">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
        </Layout>
    )
}
