export const descriptions: any = {
  BRAND_NAME: 'Your Brand name',
  FIRST_ACT: 'Select the first app action',
  HIGHLIGHTS_FIRST: 'Highlights are the first act of the app',
  NOTIFICATIONS_IS_ENABLED: 'Activate app notifications',
  NOTIFICATIONS_TIME: 'Set the time of notifications',
  HAS_EMAIL_WELCOME_MESSAGE: 'A welcome email is sent when the user activates the email permission',
  EMAIL_WELCOME_MESSAGE: 'Welcome e-mail content',
  EMAIL_WELCOME_SUBJECT: 'Welcome e-mail Subject',
  HAS_NOTIFICATION_WELCOME_MESSAGE:
    'A welcome notification is sent when the user activates the email permission',
  NOTIFICATION_WELCOME_MESSAGE: 'Welcome notification content',
  SOCCER_CLUB_NAME: 'Club name',
  SOCCER_CLUB_FAN_NAME: 'Fan dialect',
  FACEBOOK_PAGE_ID: 'The Facebook page id',
  INSTAGRAM_PAGE_ID: 'The Instagram page id',
  SOCCER_CLUB_API_URL: 'The API URL',
  SOCCER_CLUB_APP_TOKEN: 'The API access token',
  DEFAULT_LANGUAGE: 'App default language',
  YOUTUBE_PLAYLIST_ID: 'YouTube podcast playlist id',
  TICKET_TYPE: 'Select the type of ticket to be opened',
  EMAIL_RECEIVER: 'The ticket email receiver',
  ZENDESK_URL: 'Zendesk app URL for opening tickets',
  ZENDESK_USERNAME: 'Zendesk Username',
  ZENDESK_PASSWORD: 'Zendesk password',
  VTEX_ACCOUNT_NAME: 'Vtex Account name',
  VTEX_SITE_URL: 'The URL of the VTEX store website',
  VTEX_API_URL: 'The URL from VTEX API',
  VTEX_API_APP_KEY: 'The VTEX API access key',
  VTEX_API_APP_TOKEN: 'The VTEX API access token',
  SOCIAL_MEDIA_TYPE: 'Type of social Media',
  STRING_FILTER: 'The text to filter',
  NOTIFICATIONS_DAYS: 'The days of the week on which notifications will be sent.',
  NOTIFICATIONS_TIMES: 'The times of day when notifications will be sent.',
  REMOVE_LINKS: 'if true, remove the links of the text',
  REMOVE_HASTAGS: 'if true, remove the hastags of the text',
  TEXT_LENGTH_FILTER: 'Mininum size of the text.',
  HAS_STRING_FILTERS: 'If true, filter the string.',
  SOCIAL_NETWORK: 'Social network to get the notifications data',
}
