import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'

//import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

import Login from './pages/Login'

import Dashboard from './pages/Dashboard'

import Intent from './pages/Intents'
import AddIntent from './pages/Intents/add'
import EditIntent from './pages/Intents/edit'

import ConditionalAdd from './pages/Intents/ConditionalAdd'

// import Card from './pages/Cards'
// import AddCard from './pages/Cards/add'
// import EditCard from './pages/Cards/edit'

// import Speech from './pages/Speeches'
// import AddSpeech from './pages/Speeches/add'
// import EditSpeech from './pages/Speeches/edit'

import Analytics from './pages/Analytics'
import CreateUser from './pages/CreateUser'
import Path from './pages/Path'
import Phrase from './pages/Phrases'
import AddPhrase from './pages/Phrases/add'
import AddVariation from './pages/Phrases/addVariation'
import EditPhrase from './pages/Phrases/edit'
// import Accounts from './pages/Accounts'
import AddMagentoInfo from './pages/AddMagentoInfo'
import Apps from './pages/Apps'
import ConfirmAccount from './pages/ConfirmAccount/ConfirmAccount'
import CreateAccount from './pages/CreateAccount'
import CreateEcommerceVtexTemplate from './pages/CreateEcommerceVtexTemplate/CreateEcommerceVtexTemplate'
import CreateSoccerClubTemplate from './pages/CreateSoccerClubTemplate/CreateSoccerClubTemplate'
import CreateFreeTemplate from './pages/CreateFreeTemplate'
import CreatePodcastTemplate from './pages/CreatePodcast/createPodcastTemplate'
import CreateFreeTemplateWithFaq from './pages/CreateFreeTemplateWithFaq'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import Templates from './pages/Templates'
// import Page from './components/Page'
import { ToastContainer } from 'react-toastify'
import GlobalProvider from './contexts/GlobalContext'
import AppCertificate from './pages/AppCertificate'
import AppDistribute from './pages/AppDistribute'
import AppSettings from './pages/AppSettings'
import Attributes from './pages/Attributes/Attributes'
import Highlights from './pages/Highlights'
import Notifications from './pages/Notifications'
import Faqs from './pages/Faqs'
import EditHighlights from './pages/Highlights/edit'
import EditNotifications from './pages/Notifications/edit'
import EditFaqs from './pages/Faqs/edit'
import NewHighlights from './pages/Highlights/Highlights'
import NewFaqs from './pages/Faqs/faqs'
import NewNotifications from './pages/Notifications/Notifications'
import Homepage from './pages/Homepage/Homepage'
import Upgrade from './pages/Plans/Upgrade'
import Choose from './pages/Plans/Choose'
import VerballTemplates from './pages/VerballTemplates'
import Layout from './components/Layout'
import Page from './components/Page'
import AnalyticsLogs from './pages/AnalyticsLog/AnalyticsLog'
import ActivitiesLog from './pages/ActivitiesLog/ActivitiesLog'
import Conversational from './pages/Conversational/Conversational'
import Modal from 'react-modal'
Modal.setAppElement('#root')
function App() {
  return (
    <Router>
      <GlobalProvider>
        <AuthProvider>
          <Route exact path="/create-account" component={CreateAccount} />
          <Route exact path="/" component={Login} />

          <ToastContainer autoClose={3000} />
          <Switch>
            <Route path="/dashboard" component={Dashboard} />

            <Route exact path="/intent" component={Intent} />
            <Route exact path="/attributes" component={Attributes} />
            <Route exact path="/:appId/conversational" component={Conversational} />
            <Route exact path="/intent/add" component={AddIntent} />
            <Route path="/intent/edit/:id" component={EditIntent} />
            <Route exact path="/conditional/add/:id" component={ConditionalAdd} />
            <Route exact path="/conditional/edit/:id" component={ConditionalAdd} />

            {/* <Route exact path="/speech" component={Speech} /> */}
            {/* <Route exact path="/speech/add" component={AddSpeech} /> */}
            {/* <Route exact path="/speech/edit/:id" component={EditSpeech} /> */}

            {/* <Route exact path="/card" component={Card} />
            <Route exact path="/card/add" component={AddCard} />
            <Route path="/card/edit/:id" component={EditCard} /> */}

            <Route exact path="/phrase" component={Phrase} />
            <Route exact path="/phrase/add" component={AddPhrase} />
            <Route path="/phrase/edit/:id" component={EditPhrase} />
            <Route path="/phrase/variation/:id" component={AddVariation} />

            <Route exact path="/:appId/analytics" component={Analytics} />
            <Route exact path="/:appId/logs" component={ActivitiesLog} />
            <Route exact path="/:appId/analytics-log" component={AnalyticsLogs} />
            <Route path="/analytics/path" component={Path} />

            <Route exact path="/sign-up" component={CreateUser} />
            <Route exact path="/confirm-account" component={ConfirmAccount} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/homepage" component={Homepage} />
            {/* <Link to={'/forgot-password?t'}>ForgotPassword</Link> */}

            <Route path="/new-highlight" component={NewHighlights} />
            <Route path="/new-faq" component={NewFaqs} />
            <Route path="/:appId/new-notification" component={NewNotifications} />
            <Route path="/:appId/highlights" component={Highlights} />
            <Route path="/:appId/notifications" component={Notifications} />
            <Route path="/:appId/faqs" component={Faqs} />
            <Route path="/:appId/settings" component={AppSettings} />
            <Route path="/:appId/:appVersion/settings" component={AppSettings} />
            <Route path="/:appId/distribute" component={AppDistribute} />
            <Route path="/:appId/:appVersion/distribute" component={AppDistribute} />
            <Route path="/:appId/certificate" component={AppCertificate} />
            <Route path="/:appId/upgrade-plan" component={Upgrade} />
            <Route path="/choose-plan" component={Choose} />

            <Route path="/highlight/edit/:highlightId" component={EditHighlights} />
            {/* <Route path="/notification/edit/:notificationId" component={EditNotifications} /> */}
            <Route path="/faq/edit/:faqId" component={EditFaqs} />
            {/* <Route exact path="/accounts" component={Accounts} /> */}
            <Route exact path="/accounts/:accountId" component={Apps} />
            <Route exact path="/apps" component={Apps} />
            <Route exact path="/accounts/add" component={CreateAccount} />
            <Route exact path="/templates" component={Templates} />
            <Route exact path="/verball-templates" component={VerballTemplates} />
            <Route
              exact
              path="/create-ecommerce-template"
              component={CreateEcommerceVtexTemplate}
            />

            <Route exact path="/create-free-template" component={CreateFreeTemplate} />
            <Route
              exact
              path="/create-free-template-with-faq"
              component={CreateFreeTemplateWithFaq}
            />
            <Route exact path="/create-podcast-template" component={CreatePodcastTemplate} />
            <Route exact path="/create-soccer-club-template" component={CreateSoccerClubTemplate} />
            <Route exact path="/add-magento-info" component={AddMagentoInfo} />
          </Switch>
        </AuthProvider>
      </GlobalProvider>
    </Router>
  )
}

export default App
