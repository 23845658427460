import axios from 'axios'

import { FormEvent, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { useGlobalContext } from '../../contexts/GlobalContext'

import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import { isPropertyVisible } from '../../utils/appProperties'
import { WarningCircle } from 'phosphor-react'
import './styles.css'
import { descriptions } from '../../utils/strings/configsDescriptions'
//import { firestore } from '../../utils/firestore'

const {
  REACT_APP_APPS_API,
  REACT_APP_AUTOMATED_NOTIFICATIONS_API,
  REACT_APP_URL_SOCIAL_MEDIA_API,
  REACT_APP_SOCIAL_MEDIA_AUTHORIZATION_TOKEN,
} = process.env

interface IPropertiesTypeGet {
  name: string
  value: string
  is_visible: boolean
}

interface IPropertiesTypePost {
  name?: string
  value?: string
  isVisible?: boolean
}

export default function AppSettings() {
  const { selectedAppId, selectedAppName }: any = useGlobalContext()
  const [isLoading, setIsLoading]: any = useState(false)
  const [isSaving, setIsSaving]: any = useState(false)
  const [isDataSetted, setIsDataSetted]: any = useState<IPropertiesTypeGet[]>([])
  const [plan, setPlan] = useState<IPropertiesTypePost>({
    name: 'PLAN',
    value: '',
    isVisible: true,
  })
  const [type, setType] = useState<IPropertiesTypePost>()
  const [isHighlightFirst, setIsHighlightFirst] = useState<IPropertiesTypePost>()
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState<IPropertiesTypePost>()
  const [notificationsTime, setNotificationsTime] = useState<IPropertiesTypePost>()
  const [brandName, setBrandName] = useState<IPropertiesTypePost>()
  const [clubName, setClubName] = useState<IPropertiesTypePost>()
  const [clubFanName, setClubFanName] = useState<IPropertiesTypePost>()
  const [clubApiUrl, setClubApiUrl] = useState<IPropertiesTypePost>()
  const [clubApiToken, setClubApiToken] = useState<IPropertiesTypePost>()
  const [firstAct, setFirstAct] = useState<IPropertiesTypePost>()
  const [vtexAccountName, setVtexAccountName] = useState<IPropertiesTypePost>()
  const [vtexApiUrl, setVtexApiUrl] = useState<IPropertiesTypePost>()
  const [vtexSiteUrl, setVtexSiteUrl] = useState<IPropertiesTypePost>()
  const [vtexAppKey, setVtexAppKey] = useState<IPropertiesTypePost>()
  const [vtexAppToken, setVtexAppToken] = useState<IPropertiesTypePost>()
  const [podcastPlaylistId, setPodcastPlaylistId] = useState<IPropertiesTypePost>()
  const [defaultLanguage, setDefaultLanguage] = useState<IPropertiesTypePost>()
  const [day, setDay] = useState('')
  const [faqType, setFaqType] = useState<IPropertiesTypePost>()
  const [instagramPageId, setInstagramPageId] = useState<IPropertiesTypePost>()
  const [emailReceiver, setEmailReceiver] = useState<IPropertiesTypePost>()
  const [zendeskUsername, setZendeskUsername] = useState<IPropertiesTypePost>()
  const [zendeskPassword, setZendeskPassword] = useState<IPropertiesTypePost>()
  const [zendeskUrl, setZendeskUrl] = useState<IPropertiesTypePost>()
  const [settingsCategory, setSettingsCategory] = useState<string>('primary')
  const [hour, setHour] = useState('')
  const [offset, setOffset] = useState(getOffset())

  const [emailWelcomeMessage, setEmailWelcomeMessage] = useState<IPropertiesTypePost>({
    isVisible: true,
  })
  const [emailWelcomeSubject, setEmailWelcomeSubject] = useState<IPropertiesTypePost>({
    isVisible: true,
  })
  const [hasEmailWelcomeMessage, setHasEmailWelcomeMessage] = useState<IPropertiesTypePost>({
    isVisible: true,
  })
  const [notificationWelcomeMessage, setNotificationWelcomeMessage] = useState<IPropertiesTypePost>(
    { isVisible: true }
  )
  const [hasNotificationWelcomeMessage, setHasNotificationWelcomeMessage] =
    useState<IPropertiesTypePost>({ isVisible: true })
  //Social Media Configs
  const [facebookPageId, setFacebookPageId] = useState<string>()
  const [socialNetwork, setSocialNetwork] = useState<string>()
  const [socialMediaType, setSocialMediaType] = useState<string>()
  const [hasStringFilter, setHasStringFilters] = useState<boolean>()
  const [stringFilter, setStringFilters] = useState<string>()
  const [hasTextLengthFilter, setHasTextLengthFilter] = useState<string>()
  const [textLengthFilter, setTextLengthFilter] = useState<string>()
  const [removeHastag, setRemoveHastag] = useState<boolean>()
  const [removeLinks, setRemoveLinks] = useState<boolean>()
  const [linkText, setLinkText] = useState<string>()
  const [stage, setStage] = useState<string>('all')
  const [actions, setActions] = useState<string>('highlights,notifications')
  const [notificationsDays, setNotificationsDays] = useState<string>()
  const [notificationsTimes, setNotificationsTimes] = useState<string>()
  const selectedAppVersion = localStorage.getItem('selectedAppVersion')

  async function handleSubmitSocialMediaData() {
    try {
      await axios.post(
        `${REACT_APP_URL_SOCIAL_MEDIA_API}/${selectedAppId}/configs`,
        {
          pageId: facebookPageId,
          type: socialMediaType,
          stage,
          actions: actions.replace(' ', '')?.split(',') || [],
        },
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      let days = notificationsDays?.replace(' ', '')?.split(',') || []
      let times = notificationsTimes?.replace(' ', '')?.split(',') || []

      await axios.post(
        `${REACT_APP_URL_SOCIAL_MEDIA_API}/${selectedAppId}/feed-verification-times`,
        {
          days,
          times,
        },

        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      await axios.post(
        `${REACT_APP_URL_SOCIAL_MEDIA_API}/${selectedAppId}/filters`,
        {
          filters: {
            stringFilter: stringFilter || false,
            textLengthFilter: textLengthFilter || false,
            removeHastag,
            removeLinks,
            linkText: linkText || false,
          },
        },
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )
    } catch (error) {
      console.log({ error })
    }
  }

  async function getSocialMediaData() {
    const { data } = await axios.get(`${REACT_APP_URL_SOCIAL_MEDIA_API}/${selectedAppId}/configs`, {
      headers: {
        authorization: REACT_APP_SOCIAL_MEDIA_AUTHORIZATION_TOKEN,
      },
    })
    console.log('Social Media => ', data)
    setFacebookPageId(data?.page_id)
    setSocialNetwork(data?.social_network)
    setSocialMediaType(data.type)

    setHasStringFilters(data?.filters?.stringFilter)
    setStringFilters(data?.filters?.stringFilter)
    setHasTextLengthFilter(data?.filters?.textLengthFilter)
    setTextLengthFilter(data?.filters?.textLengthFilter)
    setRemoveLinks(data?.filters?.removeLinks)
    setLinkText(data?.filters?.linkText)
    setRemoveHastag(data?.filters?.removeHastag)
    setNotificationsDays(data.days.join())
    setNotificationsTimes(data.times.join())
  }

  function getOffset() {
    let date = new Date()

    return String(date.getTimezoneOffset())
  }

  async function handleSubmit(e: FormEvent) {
    console.log({ notificationsTime })

    setIsSaving(true)
    e.preventDefault()
    await handleSubmitSocialMediaData()
    let properties = []
    let notificationsProperties = []
    if (type.value === 'VTEX' || type.value === 'VTEX-BASIC') {
      properties = [
        plan,
        type,
        isHighlightFirst,
        defaultLanguage,
        vtexAccountName,
        vtexSiteUrl,
        vtexApiUrl,
        vtexAppKey,
        vtexAppToken,
        firstAct,
        emailReceiver,
        faqType,
        zendeskPassword,
        zendeskUrl,
        zendeskUsername,
        instagramPageId,
        hasEmailWelcomeMessage,
        hasNotificationWelcomeMessage,
        emailWelcomeMessage,
        notificationWelcomeMessage,
        emailWelcomeSubject,
        isNotificationsEnabled,
      ]
      notificationsProperties = [
        {
          name: 'NOTIFICATIONS_TIME',
          value: day + ' ' + hour + ' ' + offset,
          isVisible: true,
        },
      ]
    } else if (type.value === 'PODCAST') {
      properties = [
        plan,
        type,
        isHighlightFirst,
        firstAct,
        defaultLanguage,
        podcastPlaylistId,
        hasEmailWelcomeMessage,
        hasNotificationWelcomeMessage,
        emailWelcomeMessage,
        emailWelcomeSubject,
        notificationWelcomeMessage,
        isNotificationsEnabled,
      ]
    } else if (type.value === 'FREE' || type.value === 'FREE-WITH-FAQ') {
      properties = [
        brandName,
        type,
        isHighlightFirst,
        faqType,
        zendeskPassword,
        emailReceiver,
        zendeskUrl,
        zendeskUsername,
        firstAct,
        defaultLanguage,
        hasEmailWelcomeMessage,
        hasNotificationWelcomeMessage,
        emailWelcomeMessage,
        emailWelcomeSubject,
        notificationWelcomeMessage,
        isNotificationsEnabled,
      ]
    } else if (type.value === 'SOCCER-CLUB') {
      properties = [
        clubName,
        clubFanName,
        clubApiUrl,
        clubApiToken,
        firstAct,
        type,
        isHighlightFirst,
        defaultLanguage,
        instagramPageId,
        hasEmailWelcomeMessage,
        hasNotificationWelcomeMessage,
        emailWelcomeMessage,
        emailWelcomeSubject,
        notificationWelcomeMessage,
        isNotificationsEnabled,
      ]
    }

    try {
      properties.map(async (property) => {
        await axios.post(`${REACT_APP_APPS_API}/${selectedAppId}/properties`, property, {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        })
        setIsSaving(false)
      })

      const { data } = await axios.post(
        `${REACT_APP_AUTOMATED_NOTIFICATIONS_API}/v1/${selectedAppId}/properties`,
        {
          properties: notificationsProperties,
        }
      )

      console.log('Notifications data', data)
    } catch (error: any) {
      console.log('ERRO', error.response)
      toast.error(`An error ocurred: ${error.response.data.message}`)
    }
    toast.success('Configs saved successfully')

    localStorage.setItem('appStatus', 'preDistributed')
  }

  function getNormalizedPropertie(propertyName: string, propertiesArray: IPropertiesTypeGet[]) {
    const property = propertiesArray.find((property) => property.name === propertyName)
    if (property) {
      const { name, value, is_visible: isVisible } = property
      return { name, value, isVisible }
    }
    return { name: propertyName, value: '', isVisible: true }
  }

  async function handleLoadContent() {
    try {
      setIsLoading(true)
      await getSocialMediaData()

      const { data } = await axios.get<IPropertiesTypeGet[]>(
        `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      const hasFirstAct = data.find((property) => property.name === 'FIRST_ACT')
      console.log({ hasFirstAct })

      selectedAppVersion && hasFirstAct
        ? setFirstAct(getNormalizedPropertie('FIRST_ACT', data))
        : setFirstAct({
            isVisible: true,
            name: 'FIRST_ACT',
            value: '',
          })

      console.log({ firstAct })
      setType(getNormalizedPropertie('TYPE', data))
      setIsHighlightFirst(getNormalizedPropertie('HIGHLIGHTS_FIRST', data))
      setIsNotificationsEnabled(getNormalizedPropertie('NOTIFICATIONS_IS_ENABLED', data))
      setEmailWelcomeMessage(getNormalizedPropertie('EMAIL_WELCOME_MESSAGE', data))
      setEmailWelcomeSubject(getNormalizedPropertie('EMAIL_WELCOME_SUBJECT', data))
      setNotificationWelcomeMessage(getNormalizedPropertie('NOTIFICATION_WELCOME_MESSAGE', data))
      setHasEmailWelcomeMessage(getNormalizedPropertie('HAS_EMAIL_WELCOME_MESSAGE', data))
      setHasNotificationWelcomeMessage(
        getNormalizedPropertie('HAS_NOTIFICATION_WELCOME_MESSAGE', data)
      )
      setDefaultLanguage(getNormalizedPropertie('DEFAULT_LANGUAGE', data))
      console.log(data)

      if (type?.value === 'VTEX' || type?.value === 'VTEX-BASIC') {
        setPlan(getNormalizedPropertie('PLAN', data))
        setFaqType(getNormalizedPropertie('TICKET_TYPE', data))
        setEmailReceiver(getNormalizedPropertie('EMAIL_RECEIVER', data))
        setZendeskUrl(getNormalizedPropertie('ZENDESK_URL', data))
        setZendeskUsername(getNormalizedPropertie('ZENDESK_USERNAME', data))
        setZendeskPassword(getNormalizedPropertie('ZENDESK_PASSWORD', data))
        setVtexAccountName(getNormalizedPropertie('VTEX_ACCOUNT_NAME', data))
        setVtexApiUrl(getNormalizedPropertie('VTEX_API_URL', data))
        setVtexSiteUrl(getNormalizedPropertie('VTEX_SITE_URL', data))
        setVtexAppKey(getNormalizedPropertie('VTEX_API_APP_KEY', data))
        //setFacebookPageId(getNormalizedPropertie('FACEBOOK_PAGE_ID', data))
        setInstagramPageId(getNormalizedPropertie('INSTAGRAM_PAGE_ID', data))
        setVtexAppToken(getNormalizedPropertie('VTEX_API_APP_TOKEN', data))
        setNotificationsTime(getNormalizedPropertie('NOTIFICATIONS_TIME', data))
        setDay(getNormalizedPropertie('NOTIFICATIONS_TIME', data).value.split(' ')[0])
        setHour(getNormalizedPropertie('NOTIFICATIONS_TIME', data).value.split(' ')[1])
        setOffset(getNormalizedPropertie('NOTIFICATIONS_TIME', data).value.split(' ')[2])
      } else if (type?.value === 'PODCAST') {
        setPlan(getNormalizedPropertie('PLAN', data))
        setPodcastPlaylistId(getNormalizedPropertie('YOUTUBE_PLAYLIST_ID', data))
        console.log('YOUTUBE_PLAYLIST_ID', podcastPlaylistId)
      } else if (type?.value === 'FREE' || type?.value === 'FREE-WITH-FAQ') {
        setBrandName(getNormalizedPropertie('BRAND_NAME', data))
        console.log('BRAND NAME', brandName)
      } else if (type?.value === 'SOCCER-CLUB') {
        console.log('Dados', { data })
        setClubName(getNormalizedPropertie('SOCCER_CLUB_NAME', data))
        setClubFanName(getNormalizedPropertie('SOCCER_CLUB_FAN_NAME', data))
        setClubApiUrl(getNormalizedPropertie('SOCCER_CLUB_API_URL', data))
        setInstagramPageId(getNormalizedPropertie('INSTAGRAM_PAGE_ID', data))
        //setFacebookPageId(getNormalizedPropertie('FACEBOOK_PAGE_ID', data))
        setClubApiToken(getNormalizedPropertie('SOCCER_CLUB_APP_TOKEN', data))

        console.log({ clubName, clubFanName, clubApiToken, clubApiUrl })
      }

      setIsDataSetted(true)

      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  function handleChange(e: FormEvent) {
    if ((e.target as HTMLInputElement).type === 'checkbox') {
      const newProperty = {
        name: (e.target as HTMLInputElement).name,
        value: String((e.target as HTMLInputElement).checked),
        isVisible: isPropertyVisible((e.target as HTMLInputElement).name),
      } as IPropertiesTypePost

      return newProperty
    }
    return {
      name: (e.target as HTMLInputElement).name,
      value: (e.target as HTMLInputElement).value,
      isVisible: isPropertyVisible((e.target as HTMLInputElement).name),
    }
  }

  useEffect(() => {
    handleLoadContent()
  }, [isDataSetted])

  return (
    <Layout>
      <div className="content mb-0">
        <ToastContainer />
        <h2 className=" pt-0 mt-0">
          <i className="si si-wrench font-size-26 mr-2"></i>
          <strong>Settings</strong>
        </h2>
        <div className="btn-group btn-group-toggle mb-4">
          <label className={`btn btn-light ${settingsCategory === 'primary' ? 'active' : ''}`}>
            <input
              type="radio"
              value="primary"
              name="category"
              onChange={(e) => {
                setSettingsCategory(e.target.value)
              }}
            />{' '}
            Primary Details
          </label>
          <hr />
          <label
            className={`btn btn-light ${settingsCategory === 'notifications' ? 'active' : ''}`}
          >
            <input
              type="radio"
              value="notifications"
              name="category"
              onChange={(e) => {
                setSettingsCategory(e.target.value)
              }}
            />{' '}
            Notifications
          </label>
          <label className={`btn btn-light ${settingsCategory === 'socialMedia' ? 'active' : ''}`}>
            <input
              type="radio"
              value="socialMedia"
              name="category"
              onChange={(e) => {
                setSettingsCategory(e.target.value)
              }}
            />{' '}
            App Social Media
          </label>
          <label className={`btn btn-light ${settingsCategory === 'integrations' ? 'active' : ''}`}>
            <input
              type="radio"
              value="integrations"
              name="category"
              onChange={(e) => {
                setSettingsCategory(e.target.value)
              }}
            />{' '}
            Api Integrations
          </label>
        </div>
        {isLoading && <Loading />}
        {(type?.value === 'FREE' || type?.value === 'FREE-WITH-FAQ') && !isLoading && (
          <>
            <div className="block block-themed">
              <div className="block-content">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: 250 }}></th>
                      <th className="text-center" style={{ width: 500 }}></th>
                      <th className="text-center" style={{ width: 100 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingsCategory === 'primary' && (
                      <>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="defaultLanguage">
                              App Default Language
                            </label>
                          </td>
                          <td className="text-left">
                            <select
                              onChange={(e) => setDefaultLanguage(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              id="defaultLanguage"
                              name="DEFAULT_LANGUAGE"
                              value={defaultLanguage?.value}
                            >
                              <option value="" selected={true} disabled>
                                App Default Language
                              </option>
                              <option value="PT-BR">Portuguese Brasil</option>
                              <option value="EN-US">English</option>
                            </select>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.DEFAULT_LANGUAGE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            {selectedAppVersion ? (
                              <label className="font-w600 p-2" htmlFor="firstAct">
                                First Act?
                              </label>
                            ) : (
                              <label className="font-w600 p-2" htmlFor="isHighightFirst">
                                Is highlight First?
                              </label>
                            )}
                          </td>
                          {selectedAppVersion ? (
                            <td className="text-left">
                              <select
                                onChange={(e) => setFirstAct(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="firstAct"
                                name="FIRST_ACT"
                                value={firstAct?.value}
                              >
                                <option value="" disabled>
                                  App first act
                                </option>
                                <option value="highlights">Highlights</option>
                                <option value="offers">Offers</option>
                              </select>
                            </td>
                          ) : (
                            <td className="text-right">
                              <div className="form-check form-switch p-2 mr-6">
                                <input
                                  onChange={(e) => setIsHighlightFirst(handleChange(e))}
                                  className="form-check-input font-w500 h-300"
                                  name="HIGHLIGHTS_FIRST"
                                  type="checkbox"
                                  id="isHighightFirst"
                                  checked={isHighlightFirst?.value === 'true' ? true : false}
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                selectedAppVersion
                                  ? descriptions.FIRST_ACT
                                  : descriptions.HIGHLIGHTS_FIRST
                              }
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="isHighightFirst">
                              Brand Name
                            </label>
                          </td>
                          <td className="text-left">
                            <input
                              onChange={(e) => setBrandName(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              name="BRAND_NAME"
                              type="text"
                              id="brandName"
                              value={brandName?.value}
                            />
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.BRAND_NAME}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="font-w600 p-2" htmlFor="ticketType">
                              FAQ type
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch ml-10 mr-6">
                              <select
                                onChange={(e) => setFaqType(handleChange(e))}
                                className="ml-16 mr-2"
                                id="ticketType"
                                name="TICKET_TYPE"
                                value={faqType?.value}
                              >
                                <option value="default_email">Default</option>
                                <option value="email_ticket">Email Ticket</option>
                                <option value="zendesk_api_ticket">Zendesk</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.TICKET_TYPE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {faqType?.value === 'email_ticket' && (
                          <tr>
                            <td>
                              <label className="font-w600 p-2" htmlFor="emailReceiver">
                                Email receiver
                              </label>
                            </td>
                            <td className="text-left0">
                              <input
                                onChange={(e) => setEmailReceiver(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="emailReceiver"
                                name="EMAIL_RECEIVER"
                                value={emailReceiver?.value}
                                type="text"
                                placeholder="Your e-mail receiver"
                              />
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.EMAIL_RECEIVER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {settingsCategory === 'notifications' && (
                      <>
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="isNotificationsEnabled">
                              Is Notifications Enabled?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setIsNotificationsEnabled(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="NOTIFICATIONS_IS_ENABLED"
                                type="checkbox"
                                id="isNotificationsEnabled"
                                checked={isNotificationsEnabled?.value === 'true' ? true : false}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_IS_ENABLED}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="font-w600 p-2" htmlFor="notificationsTime">
                              Next Notifications Date
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch ml-10 mr-6">
                              <select
                                onChange={(e) => setDay(e.target.value)}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                className="ml-16 mr-2"
                                id="notificationTimeDay"
                                value={day}
                              >
                                <option value="sunday">Sunday</option>
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                                <option value="saturday">Saturday</option>
                              </select>
                              <input
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                onChange={(e) => setHour(e.target.value)}
                                className=""
                                name="NOTIFICATIONS_TIME"
                                type="time"
                                min="08:00"
                                max="18:00"
                                id="notificationsTimeHour"
                                value={hour}
                              />
                              <small className="ml-2 mr-2">8am to 6pm</small>
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_TIME}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="hasEmailWelcomeMessage">
                              Has E-mail Welcome Message?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasEmailWelcomeMessage(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="HAS_EMAIL_WELCOME_MESSAGE"
                                type="checkbox"
                                id="hasEmailWelcomeMessage"
                                value={hasEmailWelcomeMessage.value}
                                checked={hasEmailWelcomeMessage?.value === 'true' ? true : false}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.HAS_EMAIL_WELCOME_MESSAGE}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasEmailWelcomeMessage.value === 'true' && (
                          <>
                            <tr>
                              <td className="font-w600">
                                <label className="font-w600 p-2" htmlFor="emailWelcomeMessage">
                                  E-mail Welcome Message
                                </label>
                              </td>
                              <td className="text-left">
                                <div className="form-check form-switch p-2 mr-6 text-right">
                                  <textarea
                                    onChange={(e) => setEmailWelcomeMessage(handleChange(e))}
                                    className="form-control form-control-sm form-input text-left"
                                    name="EMAIL_WELCOME_MESSAGE"
                                    id="emailWelcomeMessage"
                                    value={emailWelcomeMessage?.value || ''}
                                  />
                                </div>
                              </td>
                              <td>
                                <i className="btn" title={descriptions.EMAIL_WELCOME_MESSAGE}>
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-w600">
                                <label className="font-w600 p-2" htmlFor="emailWelcomeSubject">
                                  E-mail Welcome Subject
                                </label>
                              </td>
                              <td className="text-left">
                                <div className="form-check form-switch p-2 mr-6 text-right">
                                  <textarea
                                    onChange={(e) => setEmailWelcomeSubject(handleChange(e))}
                                    className="form-control form-control-sm form-input text-left"
                                    name="EMAIL_WELCOME_SUBJECT"
                                    id="emailWelcomeSubject"
                                    value={emailWelcomeSubject?.value || ''}
                                  />
                                </div>
                              </td>
                              <td>
                                <i className="btn" title={descriptions.EMAIL_WELCOME_SUBJECT}>
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="font-w600">
                            <label
                              className="font-w600 p-2"
                              htmlFor="hasNotificationWelcomeMessage"
                            >
                              Has Notification Welcome Message?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasNotificationWelcomeMessage(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="HAS_NOTIFICATION_WELCOME_MESSAGE"
                                type="checkbox"
                                id="hasNotificationWelcomeMessage"
                                value={hasNotificationWelcomeMessage.value}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                checked={
                                  hasNotificationWelcomeMessage?.value === 'true' ? true : false
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              title={descriptions.HAS_NOTIFICATION_WELCOME_MESSAGE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasNotificationWelcomeMessage.value === 'true' && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="notificationWelcomeMessage">
                                Notification Welcome Message
                              </label>
                            </td>
                            <td className="text-right">
                              <div className="form-check form-switch p-2">
                                <textarea
                                  onChange={(e) => setNotificationWelcomeMessage(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  name="NOTIFICATION_WELCOME_MESSAGE"
                                  id="notificationWelcomeMessage"
                                  value={notificationWelcomeMessage?.value || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i className="btn" title={descriptions.NOTIFICATION_WELCOME_MESSAGE}>
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {settingsCategory === 'socialMedia' && (
                      <>
                        {' '}
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="facebookPageId">
                              Facebook Page Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setFacebookPageId(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Facebook page Id"
                                name="FACEBOOK_PAGE_ID"
                                type="text"
                                id="facebookPageId"
                                value={facebookPageId || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.FACEBOOK_PAGE_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="instagramPageId">
                              Instagram Page Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setInstagramPageId(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                placeholder="Instagram page Id"
                                name="INSTAGRAM_PAGE_ID"
                                type="text"
                                id="instagramPageId"
                                value={instagramPageId?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.INSTAGRAM_PAGE_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="socialMediaType">
                              Social Media Type
                            </label>
                          </td>
                          <td className="text-left">
                            <select
                              onChange={(e) => setSocialMediaType(e.target.value)}
                              className="form-control form-control-sm form-input"
                              id="socialMediaType"
                              name="SOCIAL_MEDIA_TYPE"
                              value={socialMediaType}
                            >
                              <option value="default" selected={false} disabled>
                                Social Media Type
                              </option>
                              <option value="public">Public</option>
                            </select>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.SOCIAL_MEDIA_TYPE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="socialNetwork">
                              Social Network
                            </label>
                          </td>
                          <td className="text-left">
                            <select
                              onChange={(e) => setSocialNetwork(e.target.value)}
                              className="form-control form-control-sm form-input"
                              id="socialNetwork"
                              name="SOCIAL_NETWORK"
                              value={socialNetwork}
                            >
                              <option value="default" selected={false} disabled>
                                Select the social network
                              </option>
                              <option value="FACEBOOK">Facebook</option>
                              <option value="INSTAGRAM">Instagram</option>
                            </select>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.SOCIAL_NETWORK}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="hasStringFilter">
                              String Filters?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasStringFilters(!hasStringFilter)}
                                className="form-check-input font-w500 h-300"
                                name="HAS_STRING_FILTERS"
                                type="checkbox"
                                id="hasStringFilter"
                                checked={hasStringFilter}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.HAS_STRING_FILTERS}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasStringFilter && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="stringFilter">
                                String Filter
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <input
                                  onChange={(e) => setStringFilters(e.target.value)}
                                  className="form-control form-control-sm form-input"
                                  placeholder="String Filter"
                                  name="STRING_FILTER"
                                  type="text"
                                  id="stringFilter"
                                  value={stringFilter || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.STRING_FILTER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="hasTextLengthFilter">
                              Text Length Filters?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasTextLengthFilter(e.target.value)}
                                className="form-check-input font-w500 h-300"
                                name="HAS_TEXT_LENGTH_FILTER"
                                type="checkbox"
                                id="hasTextLengthFilter"
                                checked={hasTextLengthFilter ? true : false}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_IS_ENABLED}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasTextLengthFilter && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="textLengthFilter">
                                Text Length Filter
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <input
                                  onChange={(e) => setTextLengthFilter(e.target.value)}
                                  className="form-control form-control-sm form-input"
                                  placeholder="Minimum text size"
                                  name="TEXT_LENGTH_FILTER"
                                  type="number"
                                  id="textLengthFilter"
                                  value={textLengthFilter || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.TEXT_LENGTH_FILTER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="removeLinks">
                              Remove text links?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setRemoveLinks(!removeLinks)}
                                className="form-check-input font-w500 h-300"
                                name="REMOVE_LINKS"
                                type="checkbox"
                                id="removeLinks"
                                checked={removeLinks}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.REMOVE_LINKS}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {removeLinks && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="linkText">
                                Link Text
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <input
                                  onChange={(e) => setLinkText(e.target.value)}
                                  className="form-control form-control-sm form-input"
                                  placeholder="Text to replace links"
                                  name="LINK_TEXT"
                                  type="text"
                                  id="linkText"
                                  value={linkText || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.STRING_FILTER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="removeHastag">
                              Remove hastags?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setRemoveHastag(!removeHastag)}
                                className="form-check-input font-w500 h-300"
                                name="REMOVE_HASTAGS"
                                type="checkbox"
                                id="removeHastag"
                                checked={removeHastag}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.REMOVE_HASTAGS}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="notificationsDays">
                              Notifications Days
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setNotificationsDays(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Insert days separated by commas. Ex: 1,2,3"
                                name="NOTIFICATIONS_DAYS"
                                type="text"
                                id="notificationsDays"
                                value={notificationsDays || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.NOTIFICATIONS_DAYS}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="notificationsTimes">
                              Notifications Times
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setNotificationsTimes(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Insert times separated by commas. Ex: 10:00,11:00"
                                name="NOTIFICATIONS_TIMES"
                                type="text"
                                id="notificationsTimes"
                                value={notificationsTimes || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.NOTIFICATIONS_TIMES}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                    {settingsCategory === 'integrations' && (
                      <>
                        {' '}
                        {faqType?.value === 'zendesk_api_ticket' && (
                          <>
                            <tr>
                              <td>
                                <label className="font-w600 p-2" htmlFor="zendeskUrl">
                                  Zendesk URL
                                </label>
                              </td>
                              <td className="text-left0">
                                <input
                                  onChange={(e) => setZendeskUrl(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  id="zendeskUrl"
                                  name="ZENDESK_URL"
                                  value={zendeskUrl?.value}
                                  type="text"
                                  placeholder="Your Zendesk URL"
                                />
                              </td>
                              <td>
                                <i
                                  className="btn"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={descriptions.ZENDESK_URL}
                                >
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="font-w600 p-2" htmlFor="zendeskUsername">
                                  Zendesk Username
                                </label>
                              </td>
                              <td className="text-left0">
                                <input
                                  onChange={(e) => setZendeskUsername(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  id="zendeskUsername"
                                  name="ZENDESK_USERNAME"
                                  value={zendeskUsername?.value}
                                  type="text"
                                  placeholder="Your Zendesk Username"
                                />
                              </td>
                              <td>
                                <i
                                  className="btn"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={descriptions.ZENDESK_USERNAME}
                                >
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="font-w600 p-2" htmlFor="zendeskPassword">
                                  Zendesk Password
                                </label>
                              </td>
                              <td className="text-left0">
                                <input
                                  onChange={(e) => setZendeskPassword(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  id="zendeskPassword"
                                  name="ZENDESK_PASSWORD"
                                  value={zendeskPassword?.value}
                                  type="password"
                                  placeholder="Your Zendesk Password"
                                />
                              </td>
                              <td>
                                <i
                                  className="btn"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={descriptions.ZENDESK_PASSWORD}
                                >
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="mb-2 text-center" style={{ width: '100%' }}>
                <button
                  type="submit"
                  className="btn btn-hero-primary my-2 text-center"
                  onClick={handleSubmit}
                >
                  {isSaving || <i className="fa fa-check mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  <span>Save Configs</span>
                </button>
                {/*             <button className="btn btn-hero-primary my-2 text-center" onClick={handleSubmit}>
              <i className="si si-check mr-1"></i>

            </button> */}
              </div>
            </div>
          </>
        )}
        {(type?.value === 'VTEX' || type?.value === 'VTEX-BASIC') && !isLoading && (
          <>
            <div className="block block-themed">
              <div className="block-content">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: 250 }}></th>
                      <th className="text-center" style={{ width: 500 }}></th>
                      <th className="text-center" style={{ width: 50 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingsCategory === 'primary' && (
                      <>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="defaultLanguage">
                              App Default Language
                            </label>
                          </td>
                          <td className="text-left">
                            <select
                              onChange={(e) => setDefaultLanguage(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              id="defaultLanguage"
                              name="DEFAULT_LANGUAGE"
                              value={defaultLanguage?.value}
                            >
                              <option value="" selected={true} disabled>
                                App Default Language
                              </option>
                              <option value="PT-BR">Portuguese Brasil</option>
                              <option value="EN-US">English</option>
                            </select>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.DEFAULT_LANGUAGE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            {selectedAppVersion ? (
                              <label className="font-w600 p-2" htmlFor="firstAct">
                                First Act?
                              </label>
                            ) : (
                              <label className="font-w600 p-2" htmlFor="isHighightFirst">
                                Is highlight First?
                              </label>
                            )}
                          </td>
                          {selectedAppVersion ? (
                            <td className="text-left">
                              <select
                                onChange={(e) => setFirstAct(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="firstAct"
                                name="FIRST_ACT"
                                value={firstAct?.value || 'highlights'}
                              >
                                <option value="" disabled>
                                  App first act
                                </option>
                                <option value="highlights">Highlights</option>
                                <option value="offers">Offers</option>
                              </select>
                            </td>
                          ) : (
                            <td className="text-right">
                              <div className="form-check form-switch p-2 mr-6">
                                <input
                                  onChange={(e) => setIsHighlightFirst(handleChange(e))}
                                  className="form-check-input font-w500 h-300"
                                  name="HIGHLIGHTS_FIRST"
                                  type="checkbox"
                                  id="isHighightFirst"
                                  checked={isHighlightFirst?.value === 'true' ? true : false}
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                selectedAppVersion
                                  ? descriptions.FIRST_ACT
                                  : descriptions.HIGHLIGHTS_FIRST
                              }
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="font-w600 p-2" htmlFor="ticketType">
                              FAQ type
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch ml-10 mr-6">
                              <select
                                onChange={(e) => setFaqType(handleChange(e))}
                                className="ml-16 mr-2"
                                id="ticketType"
                                name="TICKET_TYPE"
                                value={faqType?.value || 'default_email'}
                              >
                                <option value="default_email">Default</option>
                                <option value="email_ticket">Email Ticket</option>
                                <option value="zendesk_api_ticket">Zendesk</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.TICKET_TYPE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {faqType?.value === 'email_ticket' && (
                          <tr>
                            <td>
                              <label className="font-w600 p-2" htmlFor="emailReceiver">
                                Email receiver
                              </label>
                            </td>
                            <td className="text-left0">
                              <input
                                onChange={(e) => setEmailReceiver(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="emailReceiver"
                                name="EMAIL_RECEIVER"
                                value={emailReceiver?.value}
                                type="text"
                                placeholder="Your e-mail receiver"
                              />
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.EMAIL_RECEIVER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {settingsCategory === 'notifications' && (
                      <>
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="isNotificationsEnabled">
                              Is Notifications Enabled?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setIsNotificationsEnabled(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="NOTIFICATIONS_IS_ENABLED"
                                type="checkbox"
                                id="isNotificationsEnabled"
                                checked={isNotificationsEnabled?.value === 'true' ? true : false}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_IS_ENABLED}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="font-w600 p-2" htmlFor="notificationsTime">
                              Next Notifications Date
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch ml-10 mr-6">
                              <select
                                onChange={(e) => setDay(e.target.value)}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                className="ml-16 mr-2"
                                id="notificationTimeDay"
                                value={day}
                              >
                                <option value="sunday">Sunday</option>
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                                <option value="saturday">Saturday</option>
                              </select>
                              <input
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                onChange={(e) => setHour(e.target.value)}
                                className=""
                                name="NOTIFICATIONS_TIME"
                                type="time"
                                min="08:00"
                                max="18:00"
                                id="notificationsTimeHour"
                                value={hour}
                              />
                              <small className="ml-2 mr-2">8am to 6pm</small>
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_TIME}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="hasEmailWelcomeMessage">
                              Has E-mail Welcome Message?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasEmailWelcomeMessage(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="HAS_EMAIL_WELCOME_MESSAGE"
                                type="checkbox"
                                id="hasEmailWelcomeMessage"
                                value={hasEmailWelcomeMessage.value}
                                checked={hasEmailWelcomeMessage?.value === 'true' ? true : false}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.HAS_EMAIL_WELCOME_MESSAGE}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasEmailWelcomeMessage.value === 'true' && (
                          <>
                            <tr>
                              <td className="font-w600">
                                <label className="font-w600 p-2" htmlFor="emailWelcomeMessage">
                                  E-mail Welcome Message
                                </label>
                              </td>
                              <td className="text-left">
                                <div className="form-check form-switch p-2 mr-6 text-right">
                                  <textarea
                                    onChange={(e) => setEmailWelcomeMessage(handleChange(e))}
                                    className="form-control form-control-sm form-input text-left"
                                    name="EMAIL_WELCOME_MESSAGE"
                                    id="emailWelcomeMessage"
                                    value={emailWelcomeMessage?.value || ''}
                                  />
                                </div>
                              </td>
                              <td>
                                <i className="btn" title={descriptions.EMAIL_WELCOME_MESSAGE}>
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-w600">
                                <label className="font-w600 p-2" htmlFor="emailWelcomeSubject">
                                  E-mail Welcome Subject
                                </label>
                              </td>
                              <td className="text-left">
                                <div className="form-check form-switch p-2 mr-6 text-right">
                                  <textarea
                                    onChange={(e) => setEmailWelcomeSubject(handleChange(e))}
                                    className="form-control form-control-sm form-input text-left"
                                    name="EMAIL_WELCOME_SUBJECT"
                                    id="emailWelcomeSubject"
                                    value={emailWelcomeSubject?.value || ''}
                                  />
                                </div>
                              </td>
                              <td>
                                <i className="btn" title={descriptions.EMAIL_WELCOME_SUBJECT}>
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="font-w600">
                            <label
                              className="font-w600 p-2"
                              htmlFor="hasNotificationWelcomeMessage"
                            >
                              Has Notification Welcome Message?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasNotificationWelcomeMessage(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="HAS_NOTIFICATION_WELCOME_MESSAGE"
                                type="checkbox"
                                id="hasNotificationWelcomeMessage"
                                value={hasNotificationWelcomeMessage.value}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                checked={
                                  hasNotificationWelcomeMessage?.value === 'true' ? true : false
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              title={descriptions.HAS_NOTIFICATION_WELCOME_MESSAGE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasNotificationWelcomeMessage.value === 'true' && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="notificationWelcomeMessage">
                                Notification Welcome Message
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <textarea
                                  onChange={(e) => setNotificationWelcomeMessage(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  name="NOTIFICATION_WELCOME_MESSAGE"
                                  id="notificationWelcomeMessage"
                                  value={notificationWelcomeMessage?.value || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i className="btn" title={descriptions.NOTIFICATION_WELCOME_MESSAGE}>
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {settingsCategory === 'socialMedia' && (
                      <>
                        {' '}
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="facebookPageId">
                              Facebook Page Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setFacebookPageId(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Facebook page Id"
                                name="FACEBOOK_PAGE_ID"
                                type="text"
                                id="facebookPageId"
                                value={facebookPageId || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.FACEBOOK_PAGE_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="instagramPageId">
                              Instagram Page Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setInstagramPageId(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                placeholder="Instagram page Id"
                                name="INSTAGRAM_PAGE_ID"
                                type="text"
                                id="instagramPageId"
                                value={instagramPageId?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.INSTAGRAM_PAGE_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                    {settingsCategory === 'integrations' && (
                      <>
                        {faqType?.value === 'zendesk_api_ticket' && (
                          <>
                            <tr>
                              <td>
                                <label className="font-w600 p-2" htmlFor="zendeskUrl">
                                  Zendesk URL
                                </label>
                              </td>
                              <td className="text-left0">
                                <input
                                  onChange={(e) => setZendeskUrl(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  id="zendeskUrl"
                                  name="ZENDESK_URL"
                                  value={zendeskUrl?.value}
                                  type="text"
                                  placeholder="Your Zendesk URL"
                                />
                              </td>
                              <td>
                                <i
                                  className="btn"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={descriptions.ZENDESK_URL}
                                >
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="font-w600 p-2" htmlFor="zendeskUsername">
                                  Zendesk Username
                                </label>
                              </td>
                              <td className="text-left0">
                                <input
                                  onChange={(e) => setZendeskUsername(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  id="zendeskUsername"
                                  name="ZENDESK_USERNAME"
                                  value={zendeskUsername?.value}
                                  type="text"
                                  placeholder="Your Zendesk Username"
                                />
                              </td>
                              <td>
                                <i
                                  className="btn"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={descriptions.ZENDESK_USERNAME}
                                >
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="font-w600 p-2" htmlFor="zendeskPassword">
                                  Zendesk Password
                                </label>
                              </td>
                              <td className="text-left0">
                                <input
                                  onChange={(e) => setZendeskPassword(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  id="zendeskPassword"
                                  name="ZENDESK_PASSWORD"
                                  value={zendeskPassword?.value}
                                  type="password"
                                  placeholder="Your Zendesk Password"
                                />
                              </td>
                              <td>
                                <i
                                  className="btn"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={descriptions.ZENDESK_PASSWORD}
                                >
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="isHighightFirst">
                              Vtex Account Name
                            </label>
                          </td>
                          <td className="text-left">
                            <input
                              onChange={(e) => setVtexAccountName(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              name="VTEX_ACCOUNT_NAME"
                              type="text"
                              id="vtexAccountName"
                              value={vtexAccountName?.value}
                            />
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.VTEX_ACCOUNT_NAME}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="isHighightFirst">
                              Vtex Api Url
                            </label>
                          </td>
                          <td className="text-left">
                            <input
                              onChange={(e) => setVtexApiUrl(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              name="VTEX_API_URL"
                              type="url"
                              id="vtexApiUrl"
                              value={vtexApiUrl?.value}
                            />
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.VTEX_API_URL}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="isHighightFirst">
                              Vtex Site Url
                            </label>
                          </td>
                          <td className="text-left">
                            <input
                              onChange={(e) => setVtexSiteUrl(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              name="VTEX_SITE_URL"
                              type="url"
                              id="vtexSiteUrl"
                              value={vtexSiteUrl?.value}
                            />
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.VTEX_SITE_URL}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="vtexAppKey">
                              Vtex Api Key
                            </label>
                          </td>
                          <td className="text-left">
                            <input
                              onChange={(e) => setVtexAppKey(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              name="VTEX_API_APP_KEY"
                              type="password"
                              id="vtexAppKey"
                              value={vtexAppKey?.value}
                            />
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.VTEX_API_APP_KEY}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="vtexAppToken">
                              Vtex Api Token
                            </label>
                          </td>
                          <td className="text-left">
                            <input
                              onChange={(e) => setVtexAppToken(handleChange(e))}
                              className="form-control form-control-sm form-input"
                              name="VTEX_API_APP_TOKEN"
                              type="password"
                              id="vtexAppToken"
                              value={vtexAppToken?.value}
                            />
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.VTEX_API_APP_TOKEN}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="mb-2 text-center" style={{ width: '100%' }}>
                <button
                  type="submit"
                  className="btn btn-hero-primary my-2 text-center"
                  onClick={handleSubmit}
                >
                  {isSaving || <i className="fa fa-check mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  <span>Save Configs</span>
                </button>
                {/*             <button className="btn btn-hero-primary my-2 text-center" onClick={handleSubmit}>
              <i className="si si-check mr-1"></i>

            </button> */}
              </div>
            </div>
          </>
        )}
        {type?.value === 'PODCAST' && !isLoading && (
          <>
            <div className="block block-themed">
              <div className="block-content">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: 250 }}></th>
                      <th className="text-center" style={{ width: 500 }}></th>
                      <th className="text-center" style={{ width: 50 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingsCategory === 'primary' && (
                      <>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="defaultLanguage">
                              App Default Language
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <select
                                onChange={(e) => setDefaultLanguage(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="defaultLanguage"
                                name="DEFAULT_LANGUAGE"
                                value={defaultLanguage?.value}
                              >
                                <option value="" selected={true} disabled>
                                  App Default Language
                                </option>
                                <option value="PT-BR">Portuguese Brasil</option>
                                <option value="EN-US">English</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.DEFAULT_LANGUAGE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            {selectedAppVersion ? (
                              <label className="font-w600 p-2" htmlFor="firstAct">
                                First Act?
                              </label>
                            ) : (
                              <label className="font-w600 p-2" htmlFor="isHighightFirst">
                                Is highlight First?
                              </label>
                            )}
                          </td>
                          {selectedAppVersion ? (
                            <td className="text-left">
                              <select
                                onChange={(e) => setFirstAct(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="firstAct"
                                name="FIRST_ACT"
                                value={firstAct?.value}
                              >
                                <option value="" disabled>
                                  App first act
                                </option>
                                <option value="highlights">Highlights</option>
                                <option value="offers">Offers</option>
                              </select>
                            </td>
                          ) : (
                            <td className="text-right">
                              <div className="form-check form-switch p-2 mr-6">
                                <input
                                  onChange={(e) => setIsHighlightFirst(handleChange(e))}
                                  className="form-check-input font-w500 h-300"
                                  name="HIGHLIGHTS_FIRST"
                                  type="checkbox"
                                  id="isHighightFirst"
                                  checked={isHighlightFirst?.value === 'true' ? true : false}
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                selectedAppVersion
                                  ? descriptions.FIRST_ACT
                                  : descriptions.HIGHLIGHTS_FIRST
                              }
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="plan">
                              Plan
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-5">
                              <a href={`/${selectedAppId}/upgrade-plan`} title="Click to upgrade">
                                {plan?.value}
                              </a>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </>
                    )}
                    {settingsCategory === 'notifications' && <></>}
                    {settingsCategory === 'socialMedia' && <></>}
                    {settingsCategory === 'integrations' && (
                      <>
                        {' '}
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="soccerClubName">
                              Youtube Playlist Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setPodcastPlaylistId(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                name="YOUTUBE_PLAYLIST_ID"
                                type="text"
                                id="youtubePlaylistId"
                                value={podcastPlaylistId?.value}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.YOUTUBE_PLAYLIST_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="mb-2 text-center" style={{ width: '100%' }}>
                <button
                  type="submit"
                  className="btn btn-hero-primary my-2 text-center"
                  onClick={handleSubmit}
                >
                  {isSaving || <i className="fa fa-check mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  <span>Save Configs</span>
                </button>
                {/*             <button className="btn btn-hero-primary my-2 text-center" onClick={handleSubmit}>
              <i className="si si-check mr-1"></i>

            </button> */}
              </div>
            </div>
          </>
        )}
        {type?.value === 'SOCCER-CLUB' && !isLoading && (
          <>
            <div className="block block-themed">
              <div className="block-content">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: 250 }}></th>
                      <th className="text-center" style={{ width: 500 }}></th>
                      <th className="text-center" style={{ width: 50 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingsCategory === 'primary' && (
                      <>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="defaultLanguage">
                              App Default Language
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <select
                                onChange={(e) => setDefaultLanguage(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="defaultLanguage"
                                name="DEFAULT_LANGUAGE"
                                value={defaultLanguage?.value}
                              >
                                <option value="" selected={true} disabled>
                                  App Default Language
                                </option>
                                <option value="PT-BR">Portuguese Brasil</option>
                                <option value="EN-US">English</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.DEFAULT_LANGUAGE}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            {selectedAppVersion ? (
                              <label className="font-w600 p-2" htmlFor="firstAct">
                                First Act?
                              </label>
                            ) : (
                              <label className="font-w600 p-2" htmlFor="isHighightFirst">
                                Is highlight First?
                              </label>
                            )}
                          </td>
                          {selectedAppVersion ? (
                            <td className="text-left">
                              <select
                                onChange={(e) => setFirstAct(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                id="firstAct"
                                name="FIRST_ACT"
                                value={firstAct?.value || 'highlights'}
                                title={descriptions.FIRST_ACT}
                              >
                                <option value="" disabled>
                                  App first act
                                </option>
                                <option value="highlights">Highlights</option>
                                <option value="offers">Offers</option>
                              </select>
                            </td>
                          ) : (
                            <td className="text-right">
                              <div className="form-check form-switch p-2 mr-6">
                                <input
                                  onChange={(e) => setIsHighlightFirst(handleChange(e))}
                                  className="form-check-input font-w500 h-300"
                                  name="HIGHLIGHTS_FIRST"
                                  type="checkbox"
                                  id="isHighightFirst"
                                  checked={isHighlightFirst?.value === 'true' ? true : false}
                                  title={descriptions.HIGHLIGHTS_FIRST}
                                />
                              </div>
                            </td>
                          )}
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                selectedAppVersion
                                  ? descriptions.FIRST_ACT
                                  : descriptions.HIGHLIGHTS_FIRST
                              }
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="soccerClubName">
                              Club Name
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setClubName(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                name="SOCCER_CLUB_NAME"
                                type="text"
                                id="soccerClubName"
                                value={clubName?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.SOCCER_CLUB_NAME}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="soccerClubFanName">
                              Club Fan Name
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setClubFanName(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                name="SOCCER_CLUB_FAN_NAME"
                                type="text"
                                id="soccerClubFanName"
                                value={clubFanName?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.SOCCER_CLUB_FAN_NAME}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                    {settingsCategory === 'notifications' && (
                      <>
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="isNotificationsEnabled">
                              Is Notifications Enabled?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setIsNotificationsEnabled(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="NOTIFICATIONS_IS_ENABLED"
                                type="checkbox"
                                id="isNotificationsEnabled"
                                checked={isNotificationsEnabled?.value === 'true' ? true : false}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_IS_ENABLED}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="font-w600 p-2" htmlFor="notificationsTime">
                              Next Notifications Date
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch ml-10 mr-6">
                              <select
                                onChange={(e) => setDay(e.target.value)}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                className="ml-16 mr-2"
                                id="notificationTimeDay"
                                value={day}
                              >
                                <option value="sunday">Sunday</option>
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                                <option value="saturday">Saturday</option>
                              </select>
                              <input
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                onChange={(e) => setHour(e.target.value)}
                                className=""
                                name="NOTIFICATIONS_TIME"
                                type="time"
                                min="08:00"
                                max="18:00"
                                id="notificationsTimeHour"
                                value={hour}
                              />
                              <small className="ml-2 mr-2">8am to 6pm</small>
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_TIME}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="hasEmailWelcomeMessage">
                              Has E-mail Welcome Message?
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasEmailWelcomeMessage(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="HAS_EMAIL_WELCOME_MESSAGE"
                                type="checkbox"
                                id="hasEmailWelcomeMessage"
                                value={hasEmailWelcomeMessage.value}
                                checked={hasEmailWelcomeMessage?.value === 'true' ? true : false}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.HAS_EMAIL_WELCOME_MESSAGE}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasEmailWelcomeMessage.value === 'true' && (
                          <>
                            <tr>
                              <td className="font-w600">
                                <label className="font-w600 p-2" htmlFor="emailWelcomeMessage">
                                  E-mail Welcome Message
                                </label>
                              </td>
                              <td className="text-left">
                                <div className="form-check form-switch p-2 mr-6 text-right">
                                  <textarea
                                    onChange={(e) => setEmailWelcomeMessage(handleChange(e))}
                                    className="form-control form-control-sm form-input text-left"
                                    name="EMAIL_WELCOME_MESSAGE"
                                    id="emailWelcomeMessage"
                                    value={emailWelcomeMessage?.value || ''}
                                  />
                                </div>
                              </td>
                              <td>
                                <i className="btn" title={descriptions.EMAIL_WELCOME_MESSAGE}>
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td className="font-w600">
                                <label className="font-w600 p-2" htmlFor="emailWelcomeSubject">
                                  E-mail Welcome Subject
                                </label>
                              </td>
                              <td className="text-left">
                                <div className="form-check form-switch p-2 mr-6 text-right">
                                  <textarea
                                    onChange={(e) => setEmailWelcomeSubject(handleChange(e))}
                                    className="form-control form-control-sm form-input text-left"
                                    name="EMAIL_WELCOME_SUBJECT"
                                    id="emailWelcomeSubject"
                                    value={emailWelcomeSubject?.value || ''}
                                  />
                                </div>
                              </td>
                              <td>
                                <i className="btn" title={descriptions.EMAIL_WELCOME_SUBJECT}>
                                  <WarningCircle height={32} color={'#0665D0'} />
                                </i>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="font-w600">
                            <label
                              className="font-w600 p-2"
                              htmlFor="hasNotificationWelcomeMessage"
                            >
                              Has Notification Welcome Message?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasNotificationWelcomeMessage(handleChange(e))}
                                className="form-check-input font-w500 h-300"
                                name="HAS_NOTIFICATION_WELCOME_MESSAGE"
                                type="checkbox"
                                id="hasNotificationWelcomeMessage"
                                value={hasNotificationWelcomeMessage.value}
                                disabled={isNotificationsEnabled?.value !== 'true'}
                                checked={
                                  hasNotificationWelcomeMessage?.value === 'true' ? true : false
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              title={descriptions.HAS_NOTIFICATION_WELCOME_MESSAGE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasNotificationWelcomeMessage.value === 'true' && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="notificationWelcomeMessage">
                                Notification Welcome Message
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <textarea
                                  onChange={(e) => setNotificationWelcomeMessage(handleChange(e))}
                                  className="form-control form-control-sm form-input"
                                  name="NOTIFICATION_WELCOME_MESSAGE"
                                  id="notificationWelcomeMessage"
                                  value={notificationWelcomeMessage?.value || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i className="btn" title={descriptions.NOTIFICATION_WELCOME_MESSAGE}>
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {settingsCategory === 'socialMedia' && (
                      <>
                        {' '}
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="facebookPageId">
                              Facebook Page Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setFacebookPageId(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Facebook page Id"
                                name="FACEBOOK_PAGE_ID"
                                type="text"
                                id="facebookPageId"
                                value={facebookPageId || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.FACEBOOK_PAGE_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="instagramPageId">
                              Instagram Page Id
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setInstagramPageId(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                placeholder="Instagram page Id"
                                name="INSTAGRAM_PAGE_ID"
                                type="text"
                                id="instagramPageId"
                                value={instagramPageId?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.INSTAGRAM_PAGE_ID}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="socialMediaType">
                              Social Media Type
                            </label>
                          </td>
                          <td className="text-left">
                            <select
                              onChange={(e) => setSocialMediaType(e.target.value)}
                              className="form-control form-control-sm form-input"
                              id="socialMediaType"
                              name="SOCIAL_MEDIA_TYPE"
                              value={socialMediaType}
                            >
                              <option value="default" selected={false} disabled>
                                Social Media Type
                              </option>
                              <option value="public">Public</option>
                            </select>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.SOCIAL_MEDIA_TYPE}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="socialNetwork">
                              Social Network
                            </label>
                          </td>
                          <td className="text-left">
                            <select
                              onChange={(e) => setSocialNetwork(e.target.value)}
                              className="form-control form-control-sm form-input"
                              id="socialNetwork"
                              name="SOCIAL_NETWORK"
                              value={socialNetwork}
                            >
                              <option value="default" selected={false} disabled>
                                Select the social network
                              </option>
                              <option value="FACEBOOK">Facebook</option>
                              <option value="INSTAGRAM">Instagram</option>
                            </select>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.SOCIAL_NETWORK}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="hasStringFilter">
                              String Filters?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasStringFilters(!hasStringFilter)}
                                className="form-check-input font-w500 h-300"
                                name="HAS_STRING_FILTERS"
                                type="checkbox"
                                id="hasStringFilter"
                                checked={hasStringFilter}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.HAS_STRING_FILTERS}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasStringFilter && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="stringFilter">
                                String Filter
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <input
                                  onChange={(e) => setStringFilters(e.target.value)}
                                  className="form-control form-control-sm form-input"
                                  placeholder="String Filter"
                                  name="STRING_FILTER"
                                  type="text"
                                  id="stringFilter"
                                  value={stringFilter || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.STRING_FILTER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="hasTextLengthFilter">
                              Text Length Filters?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setHasTextLengthFilter(e.target.value)}
                                className="form-check-input font-w500 h-300"
                                name="HAS_TEXT_LENGTH_FILTER"
                                type="checkbox"
                                id="hasTextLengthFilter"
                                checked={hasTextLengthFilter ? true : false}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.NOTIFICATIONS_IS_ENABLED}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {hasTextLengthFilter && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="textLengthFilter">
                                Text Length Filter
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <input
                                  onChange={(e) => setTextLengthFilter(e.target.value)}
                                  className="form-control form-control-sm form-input"
                                  placeholder="Minimum text size"
                                  name="TEXT_LENGTH_FILTER"
                                  type="number"
                                  id="textLengthFilter"
                                  value={textLengthFilter || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.TEXT_LENGTH_FILTER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="removeLinks">
                              Remove text links?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setRemoveLinks(!removeLinks)}
                                className="form-check-input font-w500 h-300"
                                name="REMOVE_LINKS"
                                type="checkbox"
                                id="removeLinks"
                                checked={removeLinks ? true : false}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.REMOVE_LINKS}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        {removeLinks && (
                          <tr>
                            <td className="font-w600">
                              <label className="font-w600 p-2" htmlFor="linkText">
                                Link Text
                              </label>
                            </td>
                            <td className="text-left">
                              <div className="form-check form-switch p-2">
                                <input
                                  onChange={(e) => setLinkText(e.target.value)}
                                  className="form-control form-control-sm form-input"
                                  placeholder="Text to replace links"
                                  name="LINK_TEXT"
                                  type="text"
                                  id="linkText"
                                  value={linkText || ''}
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={descriptions.STRING_FILTER}
                              >
                                <WarningCircle height={32} color={'#0665D0'} />
                              </i>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            {' '}
                            <label className="font-w600 p-2" htmlFor="removeHastag">
                              Remove hastags?
                            </label>
                          </td>
                          <td className="text-right">
                            <div className="form-check form-switch p-2 mr-6">
                              <input
                                onChange={(e) => setRemoveHastag(!removeHastag)}
                                className="form-check-input font-w500 h-300"
                                name="REMOVE_HASTAGS"
                                type="checkbox"
                                id="removeHastag"
                                checked={removeHastag}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.REMOVE_HASTAGS}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="notificationsDays">
                              Notifications Days
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setNotificationsDays(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Insert days separated by commas. Ex: 1,2,3"
                                name="NOTIFICATIONS_DAYS"
                                type="text"
                                id="notificationsDays"
                                value={notificationsDays || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.NOTIFICATIONS_DAYS}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="notificationsTimes">
                              Notifications Times
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setNotificationsTimes(e.target.value)}
                                className="form-control form-control-sm form-input"
                                placeholder="Insert times separated by commas. Ex: 10:00,11:00"
                                name="NOTIFICATIONS_TIMES"
                                type="text"
                                id="notificationsTimes"
                                value={notificationsTimes || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i
                              className="btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={descriptions.NOTIFICATIONS_TIMES}
                            >
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                    {settingsCategory === 'integrations' && (
                      <>
                        {' '}
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="soccerclubApiUrl">
                              Api URL
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setClubApiUrl(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                placeholder="Soccer Club API URL"
                                name="SOCCER_CLUB_API_URL"
                                type="text"
                                id="soccerclubApiUrl"
                                value={clubApiUrl?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.SOCCER_CLUB_API_URL}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-w600">
                            <label className="font-w600 p-2" htmlFor="soccerClubApiToken">
                              Api Token
                            </label>
                          </td>
                          <td className="text-left">
                            <div className="form-check form-switch p-2">
                              <input
                                onChange={(e) => setClubApiToken(handleChange(e))}
                                className="form-control form-control-sm form-input"
                                name="SOCCER_CLUB_APP_TOKEN"
                                type="password"
                                placeholder="Soccer club API token"
                                id="soccerClubApiToken"
                                value={clubApiToken?.value || ''}
                              />
                            </div>
                          </td>
                          <td>
                            <i className="btn" title={descriptions.SOCCER_CLUB_APP_TOKEN}>
                              <WarningCircle height={32} color={'#0665D0'} />
                            </i>
                          </td>
                        </tr>
                      </>
                    )}
                    {/*                     <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="plan">
                          Plan
                        </label>
                      </td>
                      <td className="text-right">
                        <div className="form-check form-switch p-2 mr-5">
                          <a href={`/${selectedAppId}/upgrade-plan`} title="Click to upgrade">
                            {plan?.value}
                          </a>
                        </div>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="mb-2 text-center" style={{ width: '100%' }}>
                <button
                  type="submit"
                  className="btn btn-hero-primary my-2 text-center"
                  onClick={handleSubmit}
                >
                  {isSaving || <i className="fa fa-check mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  <span>Save Configs</span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}
