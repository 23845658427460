import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { useGlobalContext } from '../../contexts/GlobalContext'
import Layout from '../../components/Layout'
import { Box, Card, CardContent, Typography, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import Loading from '../../components/Loading'
import './styles.css'
import AppCard from '../../components/AppCard'
import { KeyboardVoice } from '@mui/icons-material'
const { REACT_APP_UI_USERS_API_URL, REACT_APP_ANALYTICS_API } = process.env

interface ParamsProps {
  accountId: string
}

interface UsersDataProps {
  totalUsers: number
  usersInPeriod: number
  activeUsersInPeriod: number
  permissions: {
    'alexa::profile:name:read'?: number
    'alexa::alerts:reminders:skill:readwrite'?: number
    'alexa::profile:given_name:read'?: number
    'read::alexa:device:all:address:country_and_postal_code'?: number
    'alexa::household:lists:write'?: number
    'alexa::profile:email:read'?: number
    'alexa::persistent_unit_id::read'?: number
    'read::alexa:device:all:address'?: number
    'alexa::household:lists:read'?: number
    'alexa::devices:all:notifications:write'?: number
    'alexa::person_id'?: number
    'alexa::devices:all:geolocation:read'?: number
    'alexa::profile:mobile_number:read'?: number
  }
  userCreationDates: number[]
}

export default function Apps() {
  const history = useHistory()
  const [apps, setApps] = useState<any>([])
  const [appsAnalyticsInfos, setAppsAnalyticsInfos] = useState<any>(false)
  const { setSelectedAppId, setSelectedAppName }: any = useGlobalContext()
  const [isLoading, setIsLoading]: any = useState(true)

  const { accountId } = useParams<ParamsProps>()

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(`${REACT_APP_UI_USERS_API_URL}/accounts/${accountId}`, {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        })

        const appsData = await Promise.all(
          data.Apps.map(async (app) => {
            const appAnalyticsData = await axios.get<UsersDataProps>(
              `${REACT_APP_ANALYTICS_API}/${app.id}/users-details`,
              {
                params: {
                  period: 'monthly',
                },
                headers: { 'Access-Control-Allow-Origin': '*' },
              }
            )
            return appAnalyticsData
          })
        )

        const mergedData = data.Apps.map((app, index) => {
          console.log(app)
          console.log(appsData[index].data)
          return { name: app.name, id: app.id, ...appsData[index].data }
        })

        //setIsLoading(false)
        /*let newApps = []
        data.Apps.map(async (app) => {
          const { data } = await axios.get<UsersDataProps>(
            `${REACT_APP_ANALYTICS_API}/${app.id}/users-details`,
            {
              params: {
                period: 'monthly',
              },
              headers: { 'Access-Control-Allow-Origin': '*' },
            }
          )
          app['data'] = data
          newApps.push(app)
        })*/
        console.log('MERGED', mergedData)

        setApps(mergedData)
        setAppsAnalyticsInfos(true)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  function handleClick(appId: any, appName: any) {
    //localStorage.setItem('selectedAppId', appId)
    setSelectedAppId(appId)
    setSelectedAppName(appName)
    history.push('/dashboard')
  }

  return (
    <Layout>
      <div className="apps-icon">
        <KeyboardVoice className="mr-2" style={{ color: '#0665d0' }} /> Voice Apps
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {apps < 1 && (
            <Box display={`inline`}>
              <Card
                className="project-card"
                onClick={() => history.push('/templates')}
                style={{
                  width: '300px',
                  height: '300px',
                  marginTop: '50px',
                  marginLeft: '50px',
                  marginRight: '30px',
                  alignItems: 'center',
                  float: 'left',
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center">
                    {apps ? 'Adicionar App' : 'Você não possui nenhum App'}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconButton>
                      <Add style={{ fontSize: '70px' }} />
                    </IconButton>
                  </div>
                  <Typography color="textSecondary" align="center">
                    Clique para adicionar
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
          {apps && (
            <div className="app-cards-container">
              {apps.map((app: any) => {
                console.log('APP =>', app.data)
                return (
                  <AppCard
                    icon
                    totalUsers={app.totalUsers}
                    totalUsersInPeriod={app.usersInPeriod}
                    activeUsersInPeriod={app.activeUsersInPeriod}
                    permissions={app.permissions}
                    text={app.name}
                    key={app.id}
                    onClick={() => handleClick(app.id, app.name)}
                  />
                  // <Card
                  //   className="project-card"
                  //   key={app.id}
                  //   onClick={() => handleClick(app.id, app.name)}
                  //   style={{
                  //     width: '300px',
                  //     height: '300px',
                  //     marginTop: '50px',
                  //     marginLeft: '50px',
                  //     marginRight: '30px',
                  //     alignItems: 'center',
                  //     position: 'sticky',
                  //     float: 'left',
                  //   }}
                  // >
                  //   <CardContent>
                  //     <Typography variant="h6" align="center">
                  //       {app.name}
                  //     </Typography>
                  //     <div
                  //       style={{
                  //         display: 'flex',
                  //         alignItems: 'center',
                  //         justifyContent: 'center',
                  //       }}
                  //     >
                  //       <IconButton>
                  //         <Add style={{ fontSize: '70px' }} />
                  //       </IconButton>
                  //     </div>
                  //     <Typography color="textSecondary" align="center">
                  //       Clique para ver mais informações
                  //     </Typography>
                  //   </CardContent>
                  // </Card>
                )
              })}
            </div>
          )}
        </>
      )}
    </Layout>
  )
}
