import { FormEvent, useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-modal'

//import closeImg from './img/close.svg'
import './styles.css'
import { Container } from './styles'
import Button from '../Button'
import { XCircle } from 'phosphor-react'
import { useGlobalContext } from '../../contexts/GlobalContext'
interface PropertyModalProps {
  modalType: 'more-informations' | 'contact' | 'welcome-message'
  isOpen: boolean
  onRequestClose: () => void
}

const { REACT_APP_FEED_API } = process.env

Modal.setAppElement('#root')

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  position: 'relative',
  overlay: { zIndex: -10 },
}
interface ItemProps {
  id: string
  emailText: string
  text: string
  ts: number
  imageUrl: string
}

export function PropertyModal({ isOpen, onRequestClose, modalType }: PropertyModalProps) {
  const { selectedAppId }: any = useGlobalContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [moreInfosText, setMoreInfosText] = useState('')
  const [moreInfosEmailText, setMoreInfosEmailText] = useState('')
  const [moreInfosId, setMoreInfosId] = useState('')
  const [contactInfosText, setContactInfosText] = useState('')
  const [welcomeMessage, setWelcomeMessage] = useState('')
  const [contactInfosEmailText, setContactInfosEmailText] = useState('')
  const [contactInfosId, setContactInfosId] = useState('')

  async function handleCreateMoreInformationsInfos(event: FormEvent) {
    event.preventDefault()

    setIsSaving(true)
    let route = ''
    try {
      if (moreInfosId) {
        console.log({ moreInfosId })
        route = `${REACT_APP_FEED_API}/feedItems/${selectedAppId}/${moreInfosId}`

        const { data }: any = await axios.put(
          route,
          {
            text: moreInfosText,
            emailText: moreInfosEmailText,
            type: 'MORE_INFORMATIONS',
          },
          {
            headers: {
              authorization: localStorage.getItem('Authorization'),
            },
          }
        )
      } else {
        route = `${REACT_APP_FEED_API}/feedItems/${selectedAppId}`
        const { data }: any = await axios.post(
          route,
          {
            text: moreInfosText,
            emailText: moreInfosEmailText,
            type: 'MORE_INFORMATIONS',
          },
          {
            headers: {
              authorization: localStorage.getItem('Authorization'),
            },
          }
        )
      }
      setIsSaving(false)
      onRequestClose()
    } catch (error) {
      console.log(error)
      setIsSaving(false)
    }
  }

  async function handleCreateWelcomeMessage(event: FormEvent) {}

  async function handleCreateContactInfos(event: FormEvent) {
    event.preventDefault()
    setIsSaving(true)
    let route = ''
    try {
      if (contactInfosId) {
        console.log({ contactInfosId })
        route = `${REACT_APP_FEED_API}/feedItems/${selectedAppId}/${contactInfosId}`

        const { data }: any = await axios.put(
          route,
          {
            text: contactInfosText,
            emailText: contactInfosEmailText,
            type: 'CONTACT',
          },
          {
            headers: {
              authorization: localStorage.getItem('Authorization'),
            },
          }
        )
      } else {
        route = `${REACT_APP_FEED_API}/feedItems/${selectedAppId}`

        const { data }: any = await axios.post(
          route,
          {
            text: contactInfosText,
            emailText: contactInfosEmailText,
            type: 'CONTACT',
          },
          {
            headers: {
              authorization: localStorage.getItem('Authorization'),
            },
          }
        )
      }
      setIsSaving(false)
      onRequestClose()
    } catch (error) {
      console.log(error)
      setIsSaving(false)
    }
  }

  async function handleLoadContent() {
    try {
      if (!isLoading) setIsLoading(true)

      const moreInfos = await axios.get(`${REACT_APP_FEED_API}/feedItems/${selectedAppId}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('Authorization'),
        },
        params: {
          types: 'MORE_INFORMATIONS',
        },
      })
      setMoreInfosText(moreInfos.data[0]?.text)
      setMoreInfosEmailText(moreInfos.data[0]?.emailText)
      setMoreInfosId(moreInfos.data[0]?.id)

      let { data } = await axios.get(`${REACT_APP_FEED_API}/feedItems/${selectedAppId}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('Authorization'),
        },
        params: {
          types: 'CONTACT',
        },
      })

      setContactInfosText(data[0]?.text)
      setContactInfosEmailText(data[0]?.emailText)
      setContactInfosId(data[0]?.id)

      setIsLoading(false)
    } catch (e) {
      console.log('Erro', { e })
    }
  }

  useEffect(() => {
    handleLoadContent()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <Container
        onSubmit={
          (modalType === 'more-informations' && handleCreateMoreInformationsInfos) ||
          (modalType === 'contact' && handleCreateContactInfos) ||
          (modalType === 'welcome-message' && handleCreateWelcomeMessage)
        }
      >
        {modalType === 'more-informations' && (
          <>
            <h2>More Informations Info</h2>
            <XCircle onClick={onRequestClose} style={{ position: 'relative', float: 'right' }} />
            <textarea
              className="form-control code"
              placeholder="Type here the text that will be spoken by the assistant"
              value={moreInfosText}
              onChange={(event) => setMoreInfosText(event.target.value)}
            />
            <textarea
              className="form-control code"
              placeholder="Enter here the text that will be sent by email"
              value={moreInfosEmailText}
              onChange={(event) => setMoreInfosEmailText(event.target.value)}
            />
          </>
        )}
        {modalType === 'contact' && (
          <>
            <h2>Register Contact Infos</h2>
            <XCircle
              width={32}
              onClick={onRequestClose}
              style={{ position: 'relative', float: 'right' }}
            />

            <textarea
              className="form-control code"
              placeholder="Type here the text that will be spoken by the assistant"
              value={contactInfosText}
              onChange={(event) => setContactInfosText(event.target.value)}
            />
            <textarea
              className="form-control code"
              placeholder="Enter here the text that will be sent by email"
              value={contactInfosEmailText}
              onChange={(event) => setContactInfosEmailText(event.target.value)}
            />
          </>
        )}
        {modalType === 'welcome-message' && (
          <>
            <h2>Welcome Message</h2>
            <XCircle
              width={32}
              onClick={onRequestClose}
              style={{ position: 'relative', float: 'right' }}
            />

            <textarea
              className="form-control code mb-3"
              placeholder="Type here the text that will be spoken by the assistant"
              value={welcomeMessage || ''}
              onChange={(event) => setWelcomeMessage(event.target.value)}
            />
          </>
        )}

        <Button>
          {isSaving || <i className="fa fa-check mr-2" />}
          {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>} Save
        </Button>
      </Container>
    </Modal>
  )
}
