import { MarkerType } from 'react-flow-renderer'

const generateFlow = (
  flow: any,
  flowHandlers: any,
  selectedLanguage = 'PT_BR',
  nodes: any,
  edges: any
) => {
  try {
    selectedLanguage = selectedLanguage.replace('-', '_')
    console.log('ENTRA AQUI')

    Object.keys(flow).forEach((intentName, index = 0) => {
      if (
        flow[intentName]?.conditionals
        /* ['1'] &&
        flow[intentName]?.conditionals['1'][`${selectedLanguage}`] &&
        flow[intentName]?.conditionals['1'][`${selectedLanguage}`]?.phrase */
      ) {
        Object.keys(flow[intentName]?.conditionals).forEach((conditional, index) => {
          nodes.push({
            id: `${intentName}_${conditional}`,
            data: {
              speak:
                flow[intentName]?.conditionals[`${conditional}`][`${selectedLanguage}`]?.phrase,
              speakId: flow[intentName]?.conditionals[`${conditional}`][`${selectedLanguage}`]?.id,
              flowHandlers,
              intentName: intentName,
              selectedLanguage: selectedLanguage,
              conditionalKey: `${conditional}`,
            },
            //position: { x: 5, y: 55 },
            //parentNode: key,
            sourcePosition: 'left',
            targetPosition: 'right',
            type: 'AssistantOutputNode',
          })

          Object.keys(flow[intentName].targets).forEach((target) => {
            nodes.push({
              id: target + conditional,
              data: { speak: flow[intentName].targets[`${target}`][`${selectedLanguage}`] },
              sourcePosition: 'left',
              targetPosition: 'right',
              type: 'UserInputNode',
            })

            edges.push({
              source: `${intentName}_${conditional}`,
              target: target + conditional,
              type: 'default',
              markerEnd: { type: MarkerType.ArrowClosed },
            })
            edges.push({
              source: target + conditional,
              target: target,
              type: 'default',
              markerEnd: { type: MarkerType.ArrowClosed },
            })
          })
        })
      }

      index++
    })

    return { nodes, edges }
  } catch (error) {
    console.log({ error })
    return {}
  }
}

export default generateFlow
