import { FormEvent, useContext, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'
// import { useNavigate } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import validator from 'validator'


const { REACT_APP_UI_USERS_API_URL } = process.env

export default function CreateUser() {
  const history = useHistory()
  const { signIn } = useContext(AuthContext)

  const [first_name, setName] = useState('')
  const [last_name, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [company, setCompanyName] = useState('')
  const [website, setWebsite] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isCredentialsInvalid, setIsCredentialsInvalid] = useState('')

  const queryParams = new URLSearchParams(useLocation().search)

  let source = queryParams.get('source')

  const { REACT_APP_UI_USERS_API_URL } = process.env
  function getImageSrc(source: any) {
    switch (source) {
      case 'voicefiq':
        return './img/voicefiq.png'
      default:
        return './img/verball.png'
    }
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsLoading(true)
    setIsCredentialsInvalid('')
    const data = {
      first_name,
      last_name,
      email,
      password,
      company: company === '' ? null : company,
      website: website === '' ? null : website,
    }

    if (confirmPassword !== password) {
      setIsLoading(false)
      setIsCredentialsInvalid('Make sure the passwords are the same')
      return
    }

    if (website && !validator.isURL(website, { require_protocol: false })) {
      setIsLoading(false)
      setIsCredentialsInvalid('Invalid Website')
      return
    }
    try {
      const uiUser = await axios.post(`${REACT_APP_UI_USERS_API_URL}/auth/register`, data)
      let decode: any = jwtDecode(uiUser.data.auth)

      if (decode.status === 'pending') {
        history.push('/confirm-account')
      } else if (decode.status === 'verified') {
        history.push('/')
      }
      // console.log(result);
      localStorage.setItem('authorization', 'Bearer ' + uiUser.data.auth)
      //console.log('auth')
      //await signIn({ email, password });
      // navigate('/templates');
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setIsCredentialsInvalid('There was a problem creating your account')
    }
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div className="bg-image" style={{ backgroundImage: `url("img/bg.jpg")` }}>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-8 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a className="font-w600 display-4" href="/">
                    <img alt="" src={getImageSrc(source)} height={80} />
                  </a>
                  {source && <h6>Powered By Verball &amp; Corebiz</h6>}
                </div>
                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-10 col-xl-6">
                    {isCredentialsInvalid && (
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        {isCredentialsInvalid}
                      </div>
                    )}
                    <form
                      className="js-validation-signin"
                      onSubmit={handleSubmit}
                    >
                      <div className="py-3">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="si si-user"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              value={first_name}
                              onChange={(e) =>
                                setName(e.target.value)
                              }
                              required
                            />
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="lastname"
                              name="name"
                              placeholder="Last Name*"
                              value={last_name}
                              onChange={(e) =>
                                setLastname(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="si si-user"></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="email"
                              name="email"
                              placeholder="E-mail*"
                              value={email}
                              onChange={(e) =>
                                setEmail(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="si si-lock"></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control form-control-lg"
                              id="password"
                              name="password"
                              placeholder="Password*"
                              value={password}
                              onChange={(e) =>
                                setPassword(e.target.value)
                              }
                              required
                            />
                            <input
                              type="password"
                              className="form-control form-control-lg"
                              id="confirmPassword"
                              name="confirmPassword"
                              placeholder="Confirm Password*"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="si si-link"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="companyName"
                              name="companyName"
                              placeholder="Company Name"
                              value={company}
                              onChange={(e) =>
                                setCompanyName(e.target.value)
                              }
                            />
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="website"
                              name="website"
                              placeholder="Website"
                              value={website}
                              onChange={(e) =>
                                setWebsite(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group-prepend"></div>
                          <div>
                            <input
                              type="checkbox"
                              id="terms"
                              name="terms"
                              required
                            />
                            <label htmlFor="terms">
                              &nbsp;I agree to the{' '}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://verball.co/terms"
                              >
                                terms and conditions
                              </a>
                              *
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="privacy"
                              name="privacy"
                              required
                            />
                            <label htmlFor="privacy">
                              &nbsp;I agree with the{' '}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://verball.co/privacy"
                              >
                                privacy policy
                              </a>
                              *.
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-block btn-hero-lg btn-hero-primary"
                          >
                            Create
                            {isLoading && (
                              <div>
                                <i className="fa fa-spinner fa-spin"></i>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-static col-md-4 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-3 text-white mb-3">Think voice, not code.</p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright &copy;{' '}
                  <span className="js-year-copy">{new Date().getFullYear()}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
