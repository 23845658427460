const getMissingString = (stringArray: Array<string>) => {
  let result = ''
  // eslint-disable-next-line array-callback-return
  stringArray.map((string) => {
    result = result + `<${string}>`
  })

  return result
}

export default getMissingString
