import { Rectangle, Layer } from "recharts";



export default function SankeyNode({ x, y, width, height, index, payload, containerWidth }: any) {

  const isOut = x + width + 6 > containerWidth;

  return (
    <Layer key={index}>
      <Rectangle 
        x={x}
        y={y}
        width={width}
        height={height}
        fill={"#058DC7"}
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
        stroke="#000"
      >
        {payload.name}
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="14"
        stroke="#000000"
        strokeOpacity="0"
      >
        {payload.value}
      </text>
    </Layer>
  );
}