import { FormEvent, useState } from 'react'
import { useAPI } from '../../services/converstionalApi'
import Loading from '../Loading'

export default function NodeForm({
  flowHandlers,
  conditionalKey,
  label,
  phrase,
  phraseId,
  intentName,
  selectedLanguage,
}: any) {
  const [speak, setSpeak] = useState(phrase)
  const api = useAPI()
  const [isLoading, setIsLoading]: any = useState(false)
  const { flow, setFlow } = flowHandlers

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      const appId = localStorage.getItem('selectedAppId')
      console.log({ appId }, { phraseId })

      await api.patch(`apps/${appId}/phrase/${phraseId}`, {
        value: speak,
      })

      flow[intentName].conditionals[selectedLanguage][conditionalKey].phrase = speak
      setFlow(flow)

      console.log('OK!')
      setIsLoading(false)
    } catch (error) {
      console.log('ERROR')
      console.log(error)
    }
  }

  return (
    <div className="content">
      <div className="row push">
        <div className="block-content">
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <label htmlFor="example-text-input">{`Key: ${conditionalKey}`}</label>
              <form className="js-validation-signin" onSubmit={submit}>
                <label htmlFor="example-text-input">{label}</label>

                {/* <input onChange={(e) => setSpeak(e.target.value)} value={speak} type="text" className="form-control" id="titleLiteral" name="titleLiteral" /> */}

                <textarea
                  onChange={(e) => setSpeak(e.target.value)}
                  className="form-control code"
                  rows={3}
                  placeholder="..."
                  id="message"
                  name="message"
                  value={speak}
                />
                <div className="form-group">
                  <button type="submit" className="btn btn-hero-info mt-2 mb-2">
                    Update
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
