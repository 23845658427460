import axios from 'axios'
const { REACT_APP_UI_USERS_API_URL } = process.env

const getAccounts = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_UI_USERS_API_URL}/accounts`, {
      headers: {
        authorization: localStorage.getItem('Authorization'),
      },
    })

    return data
  } catch (error) {
    console.log(error);
    return []
  }
}

export default getAccounts