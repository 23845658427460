import { MarkerType } from 'react-flow-renderer';

const userInputs = {
  'user_input_1': {
    'PT-BR': 'Procurar Item',
    'EN-US': 'Search Item'
  },
  'user_input_4': {
    'PT-BR': 'Sim',
    'EN-US': 'Yes'
  },
  'user_input_5': {
    'PT-BR': 'Não',
    'EN-US': 'No'
  },
  'user_input_6': {
    'PT-BR': 'Meu carrinho',
    'EN-US': 'My cart'
  },
  'user_input_7': {
    'PT-BR': 'Destaques',
    'EN-US': 'Highlights'
  },
  'user_input_9': {
    'PT-BR': 'Ajuda',
    'EN-US': 'Help'
  },
  'user_input_10': {
    'PT-BR': 'Sobre a loja',
    'EN-US': 'about the store'
  },
  'user_input_11': {
    'PT-BR': 'Enviar carrinho por email',
    'EN-US': 'Send cart by email'
  },
  'user_input_12': {
    'PT-BR': 'Entrar em contato',
    'EN-US': 'Contact'
  },
}

const createVtexFlow = (flow: any, flowHandlers: any, selectedLanguage, nodes: any, edges: any) => {
  try {

    //!LaunchRequest
    nodes.push({
      id: 'LaunchRequest',
      data: {
        flowHandlers,
        intentName: 'LaunchRequest',
        intentId: flow['LaunchRequest']?.id,
        selectedLanguage,
        nodeHeight: 280
      },
      position: { x: -300, y: -100 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'LaunchRequest_1',
      data: {
        speak: flow['LaunchRequest']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['LaunchRequest']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'LaunchRequest',
        selectedLanguage,
        conditionalKey: '1',
      },
      position: { x: 5, y: 55 },
      parentNode: 'LaunchRequest',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    //! SearchItemIntent

    nodes.push({
      id: 'user_input_1',
      data: { speak: userInputs['user_input_1'][selectedLanguage] },
      position: { x: -10, y: 60 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `LaunchRequest_1`, target: 'user_input_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'assistant_output_1',
      // type: 'input',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 30
      },
      position: { x: 120, y: 35 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode',
    })

    edges.push({ source: `user_input_1`, target: 'assistant_output_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_2',
      // type: 'input',
      data: { speak: '<item>' },
      position: { x: 330, y: 60 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `assistant_output_1`, target: 'user_input_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'SearchItemIntent',
      // type: 'input',
      data: {
        flowHandlers,
        intentName: 'SearchItemIntent',
        intentId: flow['SearchItemIntent']?.id,
        selectedLanguage,
        nodeHeight: 350,
      },
      position: { x: 480, y: -80 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode'
    })

    nodes.push({
      id: 'SearchItemIntent_1',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 50
      },
      position: { x: 5, y: 55 },
      parentNode: 'SearchItemIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'SearchItemIntent_4',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['4']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['4']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '4',
        nodeHeight: 90
      },
      position: { x: 5, y: 175 },
      parentNode: 'SearchItemIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_2`, target: 'SearchItemIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_2`, target: 'SearchItemIntent_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_3',
      data: { speak: '<item>' },
      position: { x: 720, y: 100 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `SearchItemIntent_4`, target: 'user_input_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'assistant_output_8',
      // type: 'input',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 50
      },
      position: { x: 850, y: -50 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode',
    })

    edges.push({ source: `user_input_3`, target: 'assistant_output_8', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_4',
      data: { speak: userInputs['user_input_4'][selectedLanguage] },
      position: { x: 1100, y: -300 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `assistant_output_8`, target: 'user_input_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    nodes.push({
      id: 'assistant_output_9',
      // type: 'input',
      data: {
        speak: flow['ShoppingCartAddItemIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ShoppingCartAddItemIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartAddItemIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 80
      },
      position: { x: 1200, y: -400 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode',
    })

    edges.push({ source: `user_input_4`, target: 'assistant_output_9', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_5',
      data: { speak: userInputs['user_input_5'][selectedLanguage] },
      position: { x: 1100, y: -230 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `assistant_output_8`, target: 'user_input_5', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'assistant_output_10',
      // type: 'input',
      data: {
        speak: flow['NoIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['NoIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'NoIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 80
      },
      position: { x: 1200, y: -230 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode',
    })

    edges.push({ source: `user_input_5`, target: 'assistant_output_10', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! ShoppingCartIntent

    nodes.push({
      id: 'user_input_6',
      data: { speak: userInputs['user_input_6'][selectedLanguage] },
      position: { x: -10, y: 340 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_6', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'ShoppingCartIntent',
      data: {
        flowHandlers,
        intentName: 'ShoppingCartIntent',
        intentId: flow['ShoppingCartIntent']?.id,
        selectedLanguage,
        nodeHeight: 330
      },
      position: { x: 140, y: 180 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'ShoppingCartIntent_1',
      data: {
        speak: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 65
      },
      position: { x: 5, y: 55 },
      parentNode: 'ShoppingCartIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'ShoppingCartIntent_2',
      data: {
        speak: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 60
      },
      position: { x: 5, y: 190 },
      parentNode: 'ShoppingCartIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_6`, target: 'ShoppingCartIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_6`, target: 'ShoppingCartIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })



    //! HighlightsIntent

    nodes.push({
      id: 'user_input_7',
      data: { speak: userInputs['user_input_7'][selectedLanguage] },
      position: { x: -10, y: -200 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_7', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'HighlightsIntent',
      data: {
        flowHandlers,
        intentName: 'HighlightsIntent',
        intentId: flow['HighlightsIntent']?.id,
        selectedLanguage,
        nodeHeight: 420
      },
      position: { x: 140, y: -450 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'HighlightsIntent_1',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 45
      },
      position: { x: 5, y: 55 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'HighlightsIntent_2',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 45
      },
      position: { x: 5, y: 170 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'HighlightsIntent_3',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 55
      },
      position: { x: 5, y: 285 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_7`, target: 'HighlightsIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_7`, target: 'HighlightsIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_7`, target: 'HighlightsIntent_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    edges.push({ source: `HighlightsIntent_3`, target: 'user_input_5', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `HighlightsIntent_3`, target: 'user_input_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! ShoppingCartByEmailIntent

    nodes.push({
      id: 'user_input_11',
      data: { speak: userInputs['user_input_11'][selectedLanguage] },
      position: { x: 80, y: -550 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_11', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'ShoppingCartByEmailIntent',
      data: {
        flowHandlers,
        intentName: 'ShoppingCartByEmailIntent',
        intentId: flow['ShoppingCartByEmailIntent']?.id,
        selectedLanguage,
        nodeHeight: 480
      },
      position: { x: 500, y: -700 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    edges.push({ source: `user_input_11`, target: 'ShoppingCartByEmailIntent', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    nodes.push({
      id: 'ShoppingCartByEmailIntent_1',
      data: {
        speak: flow['ShoppingCartByEmailIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ShoppingCartByEmailIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartByEmailIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 100
      },
      position: { x: 5, y: 55 },
      parentNode: 'ShoppingCartByEmailIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'ShoppingCartByEmailIntent_2',
      data: {
        speak: flow['ShoppingCartByEmailIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['ShoppingCartByEmailIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartByEmailIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 45
      },
      position: { x: 5, y: 220 },
      parentNode: 'ShoppingCartByEmailIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'ShoppingCartByEmailIntent_3',
      data: {
        speak: flow['ShoppingCartByEmailIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['ShoppingCartByEmailIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartByEmailIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 70
      },
      position: { x: 5, y: 330 },
      parentNode: 'ShoppingCartByEmailIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    //! MenuIntent

    nodes.push({
      id: 'user_input_8',
      data: { speak: 'Menu' },
      position: { x: -10, y: 600 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_8', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'MenuIntent',
      data: {
        flowHandlers,
        intentName: 'MenuIntent',
        intentId: flow['MenuIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 550 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'MenuIntent_1',
      data: {
        speak: flow['MenuIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['MenuIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'MenuIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'MenuIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_8`, target: 'MenuIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! HelpIntent

    nodes.push({
      id: 'user_input_9',
      data: { speak: userInputs['user_input_9'][selectedLanguage] },
      position: { x: -10, y: 680 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_9', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_9`, target: 'MenuIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    // //! ContactIntent

    nodes.push({
      id: 'user_input_12',
      data: { speak: userInputs['user_input_12'][selectedLanguage] },
      position: { x: -60, y: 1050 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_12', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'ContactIntent',
      data: {
        flowHandlers,
        intentName: 'ContactIntent',
        intentId: flow['ContactIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 1050 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'ContactIntent_1',
      data: {
        speak: flow['ContactIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ContactIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ContactIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'ContactIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_12`, target: 'ContactIntent', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! AboutIntent

    nodes.push({
      id: 'user_input_10',
      data: { speak: userInputs['user_input_10'][selectedLanguage] },
      position: { x: -40, y: 900 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_10', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'AboutIntent',
      data: {
        flowHandlers,
        intentName: 'AboutIntent',
        intentId: flow['AboutIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 810 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'AboutIntent_1',
      data: {
        speak: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'AboutIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'AboutIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_10`, target: 'AboutIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    return { nodes, edges }
  } catch (error) {
    return {}
  }
}

export default createVtexFlow