import axios from 'axios'
import { CheckCircle } from 'phosphor-react'
import Steps, { Step } from 'rc-steps'
import 'rc-steps/assets/index.css'

//import 'rc-steps/assets/iconfont.css'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  AreaChart,
  LineChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line,
} from 'recharts'

import Layout from '../components/Layout'
import { useGlobalContext } from '../contexts/GlobalContext'
import { getAppStatus, appStatusesArray } from '../utils/strings/appStatus'
import { getPermissionDescription } from '../utils/strings/getPermissionDescription'

const { REACT_APP_APPS_API, REACT_APP_ANALYTICS_API } = process.env
interface SessionProps {
  monthly: [
    {
      Day: string
      'Current Month'?: number
      'Previous Month': number
    }
  ]

  weekly: [
    {
      Day: string
      'Current Week'?: number
      'Previous Week': number
    }
  ]
}

interface VaProps {
  va: 'AL' | 'GA'
  sessions: number
}

interface TotalAccessProps {
  totalHits: number
  totalSessions: number
  va: VaProps[]
}

/* interface LegendChartProps {
  dataKey: string
} */

interface IPropertiesTypeGet {
  name: string
  value: string
  is_visible: boolean
}

interface UsersDataProps {
  totalUsers: number
  usersInPeriod: number
  activeUsersInPeriod: number
  permissions: {
    'alexa::profile:name:read'?: number
    'alexa::alerts:reminders:skill:readwrite'?: number
    'alexa::profile:given_name:read'?: number
    'read::alexa:device:all:address:country_and_postal_code'?: number
    'alexa::household:lists:write'?: number
    'alexa::profile:email:read'?: number
    'alexa::persistent_unit_id::read'?: number
    'read::alexa:device:all:address'?: number
    'alexa::household:lists:read'?: number
    'alexa::devices:all:notifications:write'?: number
    'alexa::person_id'?: number
    'alexa::devices:all:geolocation:read'?: number
    'alexa::profile:mobile_number:read'?: number
  }
  userCreationDates: number[]
}

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true)
  const [secondIsLoading, setSecondIsLoading] = useState(true)
  const [fourthIsLoading, setFourthIsLoading] = useState(true)
  const [thirdIsLoading, setThirdIsLoading] = useState(true)
  const [chartPeriod, setChartPeriod] = useState('month')
  const [usersDataPeriod, setUsersDataPeriod] = useState('monthly')
  const [session, setSession] = useState<SessionProps>()
  const [usersData, setUsersData] = useState<UsersDataProps>()
  const [totalSessions, setTotalSessions] = useState(0)
  const [totalRequests, setTotalRequests] = useState(0)
  const [GASessions, setGASessions] = useState(0)
  const [ALSessions, setALSessions] = useState(0)
  const [isDataSetted, setIsDataSetted]: any = useState(false)
  const [appStatus, setAppStatus]: any = useState()
  const [appVersion, setAppVersion]: any = useState()
  const { selectedAppId }: any = useGlobalContext()
  const history = useHistory()

  function getNormalizedPropertie(propertyName: string, propertiesArray: IPropertiesTypeGet[]) {
    const {
      name,
      value,
      is_visible: isVisible,
    } = propertiesArray.find((property) => property.name === propertyName)

    return { name, value, isVisible }
  }

  async function handleLoadContent() {
    if (!isLoading) setIsLoading(true)

    const { data } = await axios.get<TotalAccessProps>(
      `${REACT_APP_ANALYTICS_API}/app/${selectedAppId}/query/1`
    )

    setTotalRequests(data.totalHits)
    setTotalSessions(data.totalSessions)

    setGASessions(data.va.find((obj) => obj.va === 'GA')?.sessions || 0)
    setALSessions(data.va.find((obj) => obj.va === 'AL')?.sessions || 0)

    setIsLoading(false)
  }

  async function handleLoadSecondContent() {
    if (!secondIsLoading) setSecondIsLoading(true)

    const { data } = await axios.get<SessionProps>(
      `${REACT_APP_ANALYTICS_API}/app/${selectedAppId}/query/2`
    )

    setSession(data)
    setSecondIsLoading(false)
  }

  async function handlethirdContent() {
    if (!thirdIsLoading) setThirdIsLoading(true)
    const { data } = await axios.get<IPropertiesTypeGet[]>(
      `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
      {
        headers: {
          authorization: localStorage.getItem('Authorization'),
        },
      }
    )
    console.log(data)
    const status = getNormalizedPropertie('STATUS', data)

    await localStorage.removeItem('selectedAppVersion')
    getNormalizedPropertie('TEMPLATE_VERSION', data).value &&
      localStorage.setItem(
        'selectedAppVersion',
        getNormalizedPropertie('TEMPLATE_VERSION', data).value
      )

    setAppVersion(localStorage.getItem('selectedAppVersion'))

    console.log({ appVersion })
    setAppStatus(getAppStatus(status.value))
    setThirdIsLoading(false)
    setIsDataSetted(true)
  }

  async function handleFourthContent() {
    if (!fourthIsLoading) setFourthIsLoading(true)

    console.log({ selectedAppId, usersDataPeriod })

    const { data } = await axios.get<UsersDataProps>(
      `${REACT_APP_ANALYTICS_API}/${selectedAppId}/users-details`,
      {
        params: {
          period: usersDataPeriod,
        },
        headers: { 'Access-Control-Allow-Origin': '*' },
      }
    )

    setUsersData(data)

    setSecondIsLoading(false)

    setFourthIsLoading(false)
  }

  function redirect(statusValue) {
    console.log({ statusValue })
    switch (statusValue) {
      case 'test':
        if (appStatus.value === 'test') return `${selectedAppId}/distribute`
        break
      case 'certification':
        if (appStatus.value === 'certification') return `${selectedAppId}/certificate`
        break
      default:
        break
    }
  }

  useEffect(() => {
    handleFourthContent()
    handleLoadSecondContent()
    handleLoadContent()
  }, [])

  useEffect(() => {
    handlethirdContent()
  }, [isDataSetted])

  useEffect(() => {
    console.log(chartPeriod)
  }, [chartPeriod])

  return (
    <Layout>
      <div>
        <div className="bg-body-light">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-6 mb-0 mb-sm-2">
                <i className="si si-cursor mr-2" style={{ color: '#0665d0' }} /> Dashboard
              </h1>
              {/*
              {appStatus && (
                <p className="font-w400 mt-6 mb-0 mr-5 float-left">
                  <i className="fa fa-check-circle mr-1" style={{ color: appStatus.iconColor }} />{' '}
                  <strong>App Status: </strong>
                  {appStatus?.description}
                </p>
              )} */}

              {appStatus && (
                <Steps
                  style={{
                    width: 650,
                  }}
                  className="mt-6 mb-0 mr-5 w-600"
                  current={appStatus.index}
                  direction="horizontal"
                  size="default"
                  type="navigation"
                >
                  {appStatusesArray.map((status) => {
                    return (
                      <Step
                        title={status.description}
                        key={status.value}
                        status={status.value === appStatus.value ? 'process' : 'wait'}
                        onClick={() => history.push(redirect(status.value))}
                        icon={
                          (appStatus.value !== 'distributed' &&
                            status.index === appStatus.index && (
                              <CheckCircle
                                size={32}
                                weight="duotone"
                                color="#0665D0"
                                style={{ margin: '0' }}
                              />
                            )) ||
                          (status.index < appStatus.index && (
                            <CheckCircle
                              size={32}
                              weight="duotone"
                              color="grey"
                              style={{ margin: '0' }}
                            />
                          )) ||
                          (appStatus.value === 'distributed' &&
                            status.index === appStatus.index && (
                              <CheckCircle
                                size={32}
                                weight="duotone"
                                color="green"
                                style={{ margin: '0' }}
                              />
                            ))
                        }
                      ></Step>
                    )
                  })}
                </Steps>
              )}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-xl-12" style={{ justifyContent: 'center', justifyItems: 'center' }}>
              {/* BEGIN USER INFORMATIONS*/}
              <div className={`block block-themed ${fourthIsLoading && 'block-mode-loading'}`}>
                <div className="block-header">
                  <h3 className="block-title">Users Informations</h3>
                  <div className="block-options">
                    <div className="btn-group btn-group-sm btn-group-toggle mr-2">
                      <label
                        className={`btn btn-light ${usersDataPeriod === 'daily' ? 'active' : ''}`}
                      >
                        <input
                          type="radio"
                          value="daily"
                          name="period"
                          onChange={(e) => {
                            setUsersDataPeriod(e.target.value)
                          }}
                        />{' '}
                        Last Day
                      </label>
                      <label
                        className={`btn btn-light ${usersDataPeriod === 'weekly' ? 'active' : ''}`}
                      >
                        <input
                          type="radio"
                          value="weekly"
                          name="period"
                          onChange={(e) => {
                            setUsersDataPeriod(e.target.value)
                          }}
                        />{' '}
                        Last Week
                      </label>
                      <label
                        className={`btn btn-light ${usersDataPeriod === 'monthly' ? 'active' : ''}`}
                      >
                        <input
                          type="radio"
                          value="monthly"
                          name="period"
                          onChange={(e) => {
                            setUsersDataPeriod(e.target.value)
                          }}
                        />{' '}
                        Last Month
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn-block-option align-middle"
                      onClick={() => handleFourthContent()}
                    >
                      <i className="si si-refresh" />
                    </button>
                  </div>
                </div>
                <div
                  className={`block-content block-content-full overflow-hidden ${
                    fourthIsLoading && 'block-mode-loading'
                  } `}
                >
                  <span className="mr-2">
                    <strong>Total Users In Period: </strong>
                  </span>
                  <span>{usersData?.usersInPeriod}</span>
                  <span className="mr-2 ml-6">
                    <strong>Active Users In Period: </strong>
                  </span>
                  <span>{usersData?.activeUsersInPeriod}</span>
                  <div style={{ float: 'right' }}>
                    <span className="mr-2 ml-6">
                      <strong>Total Users:</strong>{' '}
                    </span>
                    <span>{usersData?.totalUsers}</span>
                  </div>
                  <div>
                    <div>
                      <strong>Permissions: </strong>
                    </div>
                    <div>
                      {!usersData?.permissions && 'No permissions granted'}
                      {!fourthIsLoading &&
                        Object.keys(usersData?.permissions).map((permission) => (
                          <div>
                            {getPermissionDescription(permission) +
                              ': ' +
                              usersData?.permissions[permission]}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* FINISH USER INFORMATIONS*/}
            <div className="col-xl-6">
              <div
                className={`block block-themed ${
                  isLoading && 'block-mode-loading'
                } block-rounded block-bordered`}
              >
                <div className="block-header" style={{ color: '#0665d0' }}>
                  <h3 className="block-title">Total of sessions this month</h3>
                  <div className="block-options">
                    <button type="button" className="btn-block-option" onClick={handleLoadContent}>
                      <i className="si si-refresh" />
                    </button>
                  </div>
                </div>
                <div
                  className="block-content block-content-full text-center"
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    height: '22.5rem',
                  }}
                >
                  <div className="row mt-2">
                    <div className="col-md-6 col-xl-6">
                      <a className="block block-rounded block-link-pop">
                        <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                          <div>
                            <LineChart height={100} width={150} data={session?.monthly}>
                              <Tooltip
                                formatter={(value: any) => {
                                  return [value]
                                }}
                                offset={30}
                              />

                              <Line
                                type="monotone"
                                dot={false}
                                dataKey="Current Month"
                                stroke="#8884d8"
                              />
                            </LineChart>
                            {/* <i className="fa fa-2x fa-users" /> */}
                          </div>
                          <div className="ml-3 text-right">
                            <p className="text-muted mb-0">Sessions</p>
                            <p className="font-size-h3 font-w300 mb-0">{totalSessions}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <a className="block block-rounded block-link-pop">
                        <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                          <div>
                            {/* <i className="fa fa-2x fa-chart-bar" /> */}
                            GRÁFICO
                          </div>
                          <div className="ml-3 text-right">
                            <p className="text-muted mb-0">Requests</p>
                            <p className="font-size-h3 font-w300 mb-0">{totalRequests}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-xl-6">
                      <a className="block block-rounded block-link-pop">
                        <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                          <div>
                            {/* <i className="fab fa-2x fa-google" /> */}
                            GRÁFICO
                          </div>
                          <div className="ml-3 text-right">
                            <p className="text-muted mb-0">Google Assistant</p>
                            <p className="font-size-h3 font-w300 mb-0">{GASessions}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <a className="block block-rounded block-link-pop">
                        <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                          <div>
                            {/* <i className="fab fa-2x fa-amazon" /> */}
                            GRÁFICO
                          </div>
                          <div className="ml-3 text-right">
                            <p className="text-muted mb-0">Amazon Alexa</p>
                            <p className="font-size-h3 font-w300 mb-0">{ALSessions}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div className="col-xl-6">
              <div className={`block block-themed ${secondIsLoading && 'block-mode-loading'}`}>
                <div className="block-header">
                  <h3 className="block-title">Sessions per day</h3>
                  <div className="block-options">
                    <div className="btn-group btn-group-sm btn-group-toggle mr-2">
                      <label className={`btn btn-light ${chartPeriod === 'month' ? 'active' : ''}`}>
                        <input
                          type="radio"
                          value="month"
                          name="period"
                          onChange={(e) => setChartPeriod(e.target.value)}
                        />{' '}
                        Month
                      </label>
                      <label className={`btn btn-light ${chartPeriod === 'week' ? 'active' : ''}`}>
                        <input
                          type="radio"
                          value="week"
                          name="period"
                          onChange={(e) => setChartPeriod(e.target.value)}
                        />{' '}
                        Week
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn-block-option align-middle"
                      onClick={handleLoadSecondContent}
                    >
                      <i className="si si-refresh" />
                    </button>
                  </div>
                </div>
                <div className="block-content block-content-full overflow-hidden">
                  <div className="pull-x pull-b" style={{ height: '21.3rem' }}>
                    {/* <Chart options={options} series={series} type="area" height={'100%'} /> */}
                    <ResponsiveContainer height="100%">
                      <AreaChart data={chartPeriod === 'week' ? session?.weekly : session?.monthly}>
                        <defs>
                          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#6f6bb9" stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="Day" />
                        <YAxis />
                        <Tooltip
                          formatter={(value: any) => {
                            return [value]
                          }}
                        />
                        <Legend verticalAlign="top" iconType="line" align="right" />
                        ;
                        <Area
                          type="monotone"
                          dataKey={chartPeriod === 'week' ? 'Current Week' : 'Current Month'}
                          stroke="#8884d8"
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />
                        <Area
                          type="monotone"
                          dataKey={chartPeriod === 'week' ? 'Previous Week' : 'Previous Month'}
                          stroke="#82ca9d"
                          fillOpacity={1}
                          fill="url(#colorPv)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
