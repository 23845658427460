import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import { useHistory } from 'react-router-dom'

export default function Templates() {
  const history = useHistory()

  async function handleChoosePlan(plan: string) {
    let route: string
    switch (plan) {
      case 'free':
        route = 'vtex-free'
        break
      case 'basic':
        route = 'vtex-basic'
        break
      case 'default':
        route = 'vtex'
        break
      default:
        break
    }
    localStorage.setItem('planRoute', route)
    history.push('/create-ecommerce-template')
  }

  return (
    <Layout>
      <div className="p-3 w-100">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-free-template'}>
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Free Template
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 w-100">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-free-template-with-faq'}>
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Free Template With FAQ
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 w-90">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-soccer-club-template'}>
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Soccer Club Template
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 w-90">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-ecommerce-template'} onClick={() => handleChoosePlan('basic')}>
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Basic E-commerce Template (VTEX)
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 w-90">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-ecommerce-template'} onClick={() => handleChoosePlan('default')}>
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Default E-commerce Template (VTEX)
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 w-90">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-podcast-template'}>
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Podcast Voice App
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
