import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useAPI } from "../../services/converstionalApi";
import Layout from "../../components/Layout";

interface Phrase {
  id: string;
  value: string;
}


export default function Phrase() {
  const [phrases, setPhrases] = useState<Phrase[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const api = useAPI();

  const { user } = useContext(AuthContext);

  function handleLoadContent() {
    try {
      if (!isLoading) setIsLoading(true);

      api.get<Phrase[]>(`apps/${user.currentAppId}/phrase`)
        .then((response) => {
          setPhrases(response.data);
          setIsLoading(false);
        });

    } catch (e) {
      console.log(e);
    }
  }

  function handleDelete(id: string) {
    try {
      api.delete(`apps/${user.currentAppId}/phrase/${id}`)
        .then(() => {
          handleLoadContent();
        });

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    handleLoadContent();
  }, []);

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2"><i className="si si-bubbles" style={{ color: "#0665d0" }}></i> Phrases</h1>
            <Link to={{ pathname: "/speech" }} className="btn btn-light">
              <i className="si si-action-undo"></i> Back
            </Link>
          </div>
        </div>
      </div>

      <div className="content">
        <h2 className="content-heading pt-0">App: <strong>Teste</strong></h2>
        <div className={`block block-themed table-responsive ${isLoading && 'block-mode-loading'}`}>
          <div className="block-header bg-primary-dark">
            <h3 className="block-title">Phrases</h3>
            <div className="block-options">
              <button type="button" className="btn-block-option" data-toggle="block-option" onClick={handleLoadContent} data-action-mode="demo">
                <i className="si si-refresh"></i>
              </button>
            </div>
          </div>
          <div className="block-content">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Value</th>
                  <th className="text-center" style={{ width: 70 }}></th>
                  <th className="text-center" style={{ width: 70 }}>Add Variation</th>
                  <th className="text-center" style={{ width: 70 }}>Edit</th>
                  <th className="text-center" style={{ width: 70 }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {phrases.map(phrase => (
                  <tr key={phrase.id}>
                    <td className="font-w600 breaktd">
                      <Link to={{ pathname: `/phrase/edit/${phrase.id}` }} className="btn link-fx" data-toggle="tooltip" title="Click to edit">
                        {phrase.value}
                      </Link>
                    </td>
                    <td className="text-center">
                    </td>
                    <td className="text-center">
                      <Link to={{ pathname: `/phrase/variation/${phrase.id}` }} className="btn text-primary" data-toggle="tooltip" title="Add variation">
                        <i className="fa fa-plus"></i>
                      </Link>
                    </td>
                    <td className="text-center">
                      <Link to={{ pathname: `/phrase/edit/${phrase.id}` }} className="btn text-primary" data-toggle="tooltip" title="Manage Phrases">
                        <i className="fa fa-pencil-alt"></i>
                      </Link>
                    </td>
                    <td className="text-center">
                      <a onClick={() => handleDelete(phrase.id)} className="btn text-primary" data-toggle="tooltip" title="Delete Phrase">
                        <i className="fa fa-trash-alt"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="mb-2 text-center" style={{ width: "100%" }}>
            <Link className="btn btn-hero-primary my-2 text-center" to={{ pathname: "/phrase/add" }}>
              <i className="si si-plus mr-1"></i>
              <span>Add Phrase</span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}