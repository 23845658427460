import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Button from '../../components/Button'
import Layout from '../../components/Layout'

import { getAppStatus } from '../../utils/strings/appStatus'
import { sendEmail } from '../../utils/sendEmail'
import './styles.css'

import { toast, ToastContainer } from 'react-toastify'
import { useGlobalContext } from '../../contexts/GlobalContext'
import Loading from '../../components/Loading'
import { AuthContext } from '../../contexts/AuthContext'
const { REACT_APP_APPS_API } = process.env

interface IPropertiesTypeGet {
  name: string
  value: string
  is_visible: boolean
}

export default function AppCertificate() {
  const [isLoading, setIsLoading] = useState(true)
  const [appStatus, setAppStatus]: any = useState()
  const [isSaving, setIsSaving]: any = useState(false)
  const [isDataSetted, setIsDataSetted]: any = useState(false)
  const [appStatusUpdated, setAppStatusUpdated]: any = useState(false)
  const { selectedAppId, selectedAppName, selectedProjectId }: any = useGlobalContext()
  const { getUserEmail } = useContext(AuthContext)

  function getNormalizedPropertie(propertyName: string, propertiesArray: IPropertiesTypeGet[]) {
    const {
      name,
      value,
      is_visible: isVisible,
    } = propertiesArray.find((property) => property.name === propertyName)

    return { name, value, isVisible }
  }

  async function handleLoadContent() {
    if (!isLoading) setIsLoading(true)
    const { data } = await axios.get<IPropertiesTypeGet[]>(
      `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
      {
        headers: {
          authorization: localStorage.getItem('Authorization'),
        },
      }
    )

    setAppStatus(getNormalizedPropertie('STATUS', data))

    console.log('App Status', appStatus)
    setIsLoading(false)
    setIsDataSetted(true)
  }

  async function handleCertificate() {
    const newAppStatus = appStatus
    newAppStatus.value = 'certification'

    setAppStatus(newAppStatus)

    try {
      const { data } = await axios.post(
        `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
        newAppStatus,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      setIsSaving(false)

      const email = await sendEmail({
        emailTo: getUserEmail(),
        text: `Hello, and thanks for submitting your application to certification.

        We are starting the certification process for your voice app, Name: ${selectedAppName} & ID: ${selectedAppId}.

        This process can take between 1 to 2 work days, depending on submission demand. We will let you know by email as soon as it's done, and will send instructions on how to test your voice app using Amazon Alexa and Google Assistant.

        We will be back in touch soon.

        Thank you!

        best regards from the Verball Team.
        `,
        subject: `App Sent to Certification`,
      })
      setAppStatusUpdated(true)
      toast.success(`App Sent to Certification`)
    } catch (error: any) {
      console.log('ERRO', error.message)
      toast.error(`An error ocurred: ${error.response.data.message}`)
    }
  }

  useEffect(() => {
    handleLoadContent()
  }, [isDataSetted, appStatusUpdated])

  return (
    <>
      <Layout>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="title-container">
              <h1>Certification</h1>

              <button
                type="submit"
                className="btn btn-hero-primary ml-2 my-2 text-center"
                onClick={handleCertificate}
              >
                {isSaving || <i className="fa fa-check mr-2" />}
                {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                <span>Sent to certification</span>
              </button>
            </div>
            <div className="status-container">
              <h3 className="status-title">App Status</h3>
              <span>{getAppStatus(appStatus.value).description}</span>
            </div>
          </>
        )}
      </Layout>
    </>
  )
}
