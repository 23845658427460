import { FormEvent, useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom';


export default function Login(){
  const [verificationCode, setVerificationCode] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);
  const [isCredentialsInvalid, setIsCredentialsInvalid] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const { confirmAccount, resendConfirmationEmail } = useContext(AuthContext);

  async function handleSubmit(event: FormEvent){
    event.preventDefault();

    setIsLoading(true);
    
    try{
      
      const data  = await confirmAccount({verificationCode});
      console.log('Data', data)

    } catch(error){

      console.log(error);

      setIsLoading(false);
      setIsCredentialsInvalid(true);
    
    }
  }

  async function handleResendConfirmationCode() {
    setButtonState(true);
    await resendConfirmationEmail();
    try {
      
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div className="bg-image" style={{ backgroundImage: `url("img/bg.jpg")` }}>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a className="link-fx font-w600 display-4" href="#">
                    <img alt= '' src="https://www.verball.co/wp-content/uploads/2019/02/cropped-verball-beta-4-4.png" height={90}/>
                    {/* <span className="primary-color">verb</span><span className="text-primary">all</span> */}
                  </a>
                </div>
                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                  { 
                    isCredentialsInvalid && (
                      <div className="alert alert-danger text-center" role="alert">
                        There was a problem with your login.
                      </div>
                    )
                  }
                    <form className="js-validation-signin" onSubmit={handleSubmit}>
                      <div>Please, verify your account</div>
                      <div className="py-3">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                              <i className="si si-lock"></i>
                              </span>
                            </div>
                              <input 
                                type="text" 
                                className="form-control form-control-lg" 
                                id="verificationCode" 
                                name="verificationCode" 
                                placeholder="Verification Code"
                                value={verificationCode}
                                onChange={e => setVerificationCode(e.target.value)}
                              />
                          </div>
                          <div> Place your confirmation code here, or click on the link that was sent to your email</div>
                          <div> If you did not receive the confirmation code, resend the email by <button disabled={buttonState} onClick={handleResendConfirmationCode}>clicking here</button></div>
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary">
                            <i className="fa fa-fw fa-sign-in-alt mr-1" />Confirm Account
                            { 
                              isLoading && (
                                <div>
                                  <i className="fa fa-spinner fa-spin"></i>
                                </div>
                              )
                            } 
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-3 text-white mb-3">
                  Think voice, not code.
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright &copy; <span className="js-year-copy">{new Date().getFullYear()}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}