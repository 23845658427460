const getFlow = (intents: any) => {
  try {
    const flow: any = {}

    intents.forEach((intent: any) => {
      flow[intent.name] = {
        id: intent.id,
        name: intent.name,
      }
      const conditionals: any = {}
      intent.conditionals.forEach((conditional: any) => {
        if (!conditionals[conditional.language]) {
          conditionals[conditional.language] = {}
        }
        conditionals[conditional.language][conditional.key] = {
          phraseId: conditional?.speak?.phrases_speeches[0]?.phrase?.id,
          phrase: conditional?.speak?.phrases_speeches[0]?.phrase?.value,
        }
      })
      flow[intent.name].conditionals = conditionals
      // console.log(flow);

    })

    return flow
  } catch (error) {
    console.log(error);
    return {}
  }
}

export default getFlow