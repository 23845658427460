export const propertiesVisibility: any = {
  HIGHLIGHTS_FIRST: true,
  PLAN: true,
  TYPE: true,
  YOUTUBE_PLAYLIST_ID: false,
  SHORT_DESCRIPTION: true,
  FULL_DESCRIPTION: true,
  PRIVACY_POLICY_URL: true,
  THERMS_OF_SERVICE_URL: true,
  SMALL_LOGO_IMAGE: true,
  LARGE_LOGO_IMAGE: true,
  VTEX_ACCOUNT_NAME: true,
  VTEX_API_URL: true,
  VTEX_SITE_URL: true,
  VTEX_API_APP_KEY: false,
  VTEX_API_APP_TOKEN: false,
  STORE_NAME: true,
  DEFAULT_LANGUAGE: true,
  BRAND_NAME: true,
}

export const isPropertyVisible = (propertyName: any) => {
  return propertiesVisibility[propertyName]
}
