import { useCallback, useState } from 'react'
import ReactFlow, {
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  ConnectionLineType,
} from 'react-flow-renderer'
import createPodcastFlow from '../../utils/createPodcastFlow'
import createVtexFlow from '../../utils/createVtexFlow'
import createFreeFlow from '../../utils/createFreeFlow'
import createVtexBasicFlow from '../../utils/createVtexBasicFlow'
import AssistantOutputNode from '../AssistantOutputNode'
import IntentNode from '../IntentNode'
import MusicPlayNode from '../MusicPlayNode'
import UserInputNode from '../UserInputNode'
import generateFlow from '../../utils/generateFlow'
import dagre from 'dagre'
// import { useEffect } from 'react'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 400
const nodeHeight = 172

const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = isHorizontal ? 'right' : 'top'
    node.sourcePosition = isHorizontal ? 'left' : 'bottom'

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    return node
  })

  return { nodes, edges }
}

function Flow({ intents, setIntents, flowHandlers, appType, selectedLanguage }: any) {
  let nodes: any = []
  let edges: any = []

  const nodeTypes = { IntentNode, UserInputNode, AssistantOutputNode, MusicPlayNode }
  const { flow } = flowHandlers

  if (flow) {
    try {
      switch (appType) {
        case '-FREE':
          createFreeFlow(flow, flowHandlers, selectedLanguage, nodes, edges)
          break
        case '-VTEX-BASIC':
          createVtexBasicFlow(flow, flowHandlers, selectedLanguage, nodes, edges)
          break
        case '-VTEX':
          createVtexFlow(flow, flowHandlers, selectedLanguage, nodes, edges)
          break
        case '-PODCAST':
          createPodcastFlow(flow, flowHandlers, selectedLanguage, nodes, edges)
          break

        default:
          generateFlow(flow, flowHandlers, selectedLanguage, nodes, edges)
          break
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges)

  const [finalNodes, setNodes, onNodesChange] = useNodesState(layoutedNodes)
  const [finalEdges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges)

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      fitView
      nodeTypes={nodeTypes}
      //onNodesChange={onNodesChange}
      //onEdgesChange={onEdgesChange}
      //onConnect={onConnect}
      connectionLineType={ConnectionLineType.SmoothStep}
      attributionPosition="top-right"
    >
      <Controls />
      {/* <MiniMap /> */}
    </ReactFlow>
  )
}

export default Flow
