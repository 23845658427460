import { Typography } from '@material-ui/core'
import { isMobile } from 'react-device-detect';
import './styles.css';

function AccountCard({ text, onClick, icon }: any) {
  return (
    <>
      <div className="account-card" onClick={() => onClick()} style={{ width: isMobile ? '100%' : '' }}>
        <Typography variant="h6">
          {text}
        </Typography>
      </div>
    </>

  );
}

export default AccountCard