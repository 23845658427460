import { Card, CardContent } from '@material-ui/core'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import { AuthContext } from '../../contexts/AuthContext'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { sendEmail } from '../../utils/sendEmail'

export default function Upgrade() {
  const [isLoading, setIsLoading]: any = useState(false)
  const { selectedAppId, selectedAppName, selectedProjectId }: any = useGlobalContext()
  const { getUserEmail } = useContext(AuthContext)

  async function handleChangePlan(plan: string) {
    try {
      const email = await sendEmail({
        emailTo: getUserEmail(),
        text: `Hello, and thanks for upgrade your plan.

  We are starting the upgrade process for your voice app, Account: ${selectedProjectId} Name: ${selectedAppName} & ID: ${selectedAppId}.

  Plan choosed: ${plan}

  This process can take between 1 to 2 work days, depending on upgrade submission demand.

  Thank you!

  best regards from the Verball Team.
  `,
        subject: `Upgrade Plan Required`,
      })

      toast.success(`Upgrade Plan Required`)
    } catch (error: any) {
      console.log('ERRO', error.message)
      toast.error(`An error ocurred: ${error.response.data.message}`)
    }
  }
  return (
    <Layout>
      {isLoading && <Loading />}
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            className="plan"
            onClick={() => console.log('click')}
            style={{
              display: 'flex',
              width: '18.75rem',
              height: '25rem',
              marginTop: '50px',
              marginLeft: '50px',
              marginRight: '30px',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <h5 style={{ alignItems: 'center', display: 'block' }}>Plan Free</h5>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div>
                {' '}
                <button
                  type="submit"
                  className="btn btn-hero-primary mt-12"
                  onClick={() => handleChangePlan('free')}
                >
                  <i className="fa fa-fw fa-check" />
                  Choose
                </button>
              </div>
            </CardContent>
          </Card>
          <Card
            className="plan"
            onClick={() => console.log('click')}
            style={{
              display: 'flex',
              width: '18.75rem',
              height: '25rem',
              marginTop: '50px',
              marginLeft: '50px',
              marginRight: '30px',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <h5 style={{ alignItems: 'center', display: 'block' }}>Plan Basic</h5>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div>
                {' '}
                <button
                  type="submit"
                  className="btn btn-hero-primary mt-12"
                  onClick={() => handleChangePlan('basic')}
                >
                  <i className="fa fa-fw fa-check" />
                  Choose
                </button>
              </div>
            </CardContent>
          </Card>
          <Card
            className="plan"
            onClick={() => console.log('click')}
            style={{
              display: 'flex',
              width: '18.75rem',
              height: '25rem',
              marginTop: '50px',
              marginLeft: '50px',
              marginRight: '30px',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <h5 style={{ alignItems: 'center', display: 'block' }}>Plan Advanced</h5>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div>
                {' '}
                <button
                  type="submit"
                  className="btn btn-hero-primary mt-12"
                  onClick={() => handleChangePlan('advanced')}
                >
                  <i className="fa fa-fw fa-check" />
                  Choose
                </button>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </Layout>
  )
}
