import { FormEvent, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useGlobalContext } from '../../contexts/GlobalContext'
import Layout from '../../components/Layout'

const { REACT_APP_FEED_API } = process.env

export default function NewFaq() {
  const history = useHistory()
  const [faqTitle, setFaqTitle] = useState('')
  const [faqText, setFaqText] = useState('')
  const [type, setType] = useState('FAQ')
  const [isLoading, setIsLoading] = useState(false)
  const { selectedAppId }: any = useGlobalContext()
  console.log(REACT_APP_FEED_API)
  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsLoading(true)

    try {
      const { data }: any = await axios.post(
        `${REACT_APP_FEED_API}/feedItems/${selectedAppId}`,
        {
          text: faqText,
          title: faqTitle,
          type: type,
        },
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      history.push(`/${selectedAppId}/faqs`)
      // navigate('/templates');
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <Layout>
      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h3 className="block-title">
              <i className="fa fa-info-circle text-primary mr-2"></i>
              Fill in the fields below to create your FAQ.
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  {/*                 <div className="justify-content-end">
                  <label htmlFor="example-text-input">Highlight Type</label>
                  <select
                    onChange={(e) => setType(e.target.value)}
                    className="form-control"
                    id="type"
                    name="type"
                    value={type}
                  >
                    <option value="FAQ">FAQ</option>
                  </select>
                </div> */}
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">FAQ Title</label>

                    <textarea
                      className="form-control code"
                      required
                      value={faqTitle}
                      onChange={(e) => setFaqTitle(e.target.value)}
                      rows={3}
                      placeholder="Your FAQ Title"
                      id="faqTitle"
                      name="faqTitle"
                    ></textarea>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">FAQ Text</label>

                    <textarea
                      className="form-control code"
                      required
                      value={faqText}
                      onChange={(e) => setFaqText(e.target.value)}
                      rows={3}
                      placeholder="Your FAQ text"
                      id="text"
                      name="text"
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <button
                      onSubmit={handleSubmit}
                      type="submit"
                      className="btn btn-hero-info mt-2 mb-2"
                    >
                      Create FAQ
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
