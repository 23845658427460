import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
// import { AuthContext } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom'

import Layout from '../../components/Layout'
import { useGlobalContext } from '../../contexts/GlobalContext'
import axios from 'axios'
import Loading from '../../components/Loading'
import { NewNotificationModal } from '../../components/NewNotificationModal'
const { REACT_APP_NOTIFICATIONS_API_URL } = process.env
const PERPAGE = 3

interface NotificationsLimitProps {
  limit: number
  interval: string
  sent: number
}

interface NotificationsProps {
  id: string
  text: string
  ts: number
  number_of_users: number
  number_of_notified_users: number
  type: string
}

export default function Notifications() {
  const history = useHistory()
  const [notifications, setNotifications] = useState<NotificationsProps[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [active, setButtonActive] = useState(true)
  const [isNewNotificationModalOpen, setIsNewNotificationModalOpen] = useState(false)
  const { selectedAppId, selectedAppName }: any = useGlobalContext()
  const [notificationsLimit, setNotificationsLimit] = useState<NotificationsLimitProps>({
    sent: 0,
    limit: 0,
    interval: 'loading',
  })

  function openNewNotificationModal() {
    setIsNewNotificationModalOpen(true)
  }

  function closeNewNotificationModal() {
    setIsNewNotificationModalOpen(false)
  }

  async function handleLoadContent(page) {
    try {
      const { data } = await axios.get(`${REACT_APP_NOTIFICATIONS_API_URL}/${selectedAppId}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('Authorization'),
        },
        params: {
          page: page,
          perPage: PERPAGE,
        },
      })
      console.info(data)
      setNotifications(data)
    } catch (e) {
      console.log('Erro', { e })
    }
  }

  async function handleLoadSecondContent() {
    try {
      const { data } = await axios.get(`${REACT_APP_NOTIFICATIONS_API_URL}/${selectedAppId}/info`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('Authorization'),
        },
      })
      //let data = { sent: 2, limit: 3, interval: 'day' }
      setNotificationsLimit(data)
    } catch (error) {
      console.error('Error limit', error)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    handleLoadContent(page)
    handleLoadSecondContent()
    setIsLoading(false)
  }, [selectedAppId, page])

  return (
    <Layout>
      <NewNotificationModal
        isOpen={isNewNotificationModalOpen}
        onRequestClose={closeNewNotificationModal}
      />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className="bg-body-light">
            <div className="content content-full">
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                  <i className="si si-bell" style={{ color: '#0665d0' }}></i> Your Notifications
                </h1>
                <div className="flex-sm-fill">
                  <span className="mr-5" style={{ fontSize: 18 }}>
                    <strong>
                      {notificationsLimit.interval[0].toLocaleUpperCase() +
                        notificationsLimit.interval.substring(1)}{' '}
                      Notifications Total Sent:
                    </strong>{' '}
                    {notificationsLimit.sent}
                  </span>
                  <span style={{ fontSize: 18 }}>
                    <strong>
                      {notificationsLimit.interval[0].toLocaleUpperCase() +
                        notificationsLimit.interval.substring(1)}{' '}
                      Notifications Limit:
                    </strong>{' '}
                    {notificationsLimit.limit}
                  </span>
                </div>
                <button
                  className="btn btn-hero-primary my-2"
                  onClick={() => openNewNotificationModal()}
                  disabled={notificationsLimit.sent >= notificationsLimit.limit}
                >
                  <i className="fa fa-bell"></i> New Notification
                </button>
              </div>
            </div>
          </div>

          <div className="content">
            <h2 className="content-heading pt-0">
              App: <strong>{selectedAppName}</strong>
            </h2>

            <div className={`block block-themed ${isLoading && 'block-mode-loading'}`}>
              <div className="block-header bg-primary-dark">
                <h3 className="block-title">Registered Notification</h3>
                <div className="block-options">
                  <button
                    type="button"
                    className="btn-block-option"
                    data-toggle="block-option"
                    onClick={handleLoadContent}
                    data-action-mode="demo"
                  >
                    <i className="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div className="block-content">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: '18%' }}>
                        <h5 className="mb-0">Date</h5>
                      </th>
                      <th style={{ width: '60%', wordWrap: 'break-word' }}>
                        <h5 className="mb-0">Notification</h5>
                      </th>

                      <th style={{ width: '11%', wordWrap: 'break-word' }}>Total Users</th>
                      <th style={{ width: '11%', wordWrap: 'break-word' }}>Notified Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification) => (
                      <tr key={notification.id}>
                        <td className="font-w600">
                          <p>{`${new Date(
                            Number(notification.ts)
                          ).toLocaleDateString()}  ${new Date(
                            Number(notification.ts)
                          ).toLocaleTimeString()}`}</p>
                        </td>
                        <td className="font-w600">{notification.text}</td>
                        <td>{notification.number_of_users}</td>
                        <td>{notification.number_of_notified_users}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <button
                          onClick={() => setPage(page - 1)}
                          className="btn text-primary float-right"
                          data-toggle="tooltip"
                          title="Previous Page"
                          disabled={page === 1}
                        >
                          <i className="fa fa-arrow-left"></i>
                        </button>
                      </td>
                      <td>
                        <p title={`Page ${page}`} className="mt-2 ml-4 mr-4 float-right">
                          {' '}
                          Page {page}
                        </p>
                      </td>
                      <td>
                        <button
                          onClick={() => setPage(page + 1)}
                          className="btn text-primary float-right"
                          data-toggle="tooltip"
                          title="Next Page"
                          disabled={notifications.length < PERPAGE}
                        >
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
      {isLoading ||
        (!notifications.length && page === 1 && (
          <div className="row">
            <div className="mb-2 text-center lg" style={{ width: '100%' }}>
              <i className="si si-plus mr-1"></i>
              <span>You dont have Notifications</span>
            </div>
          </div>
        ))}
    </Layout>
  )
}
