// import React from 'react'
import ReactLoading from 'react-loading';
import { LoadingContainer } from './styles'

const Loading = () => (
  <LoadingContainer>
    <ReactLoading type={'bars'} color={'#337ab7'} height={'100%'} width={100} />
  </LoadingContainer>
)

export default Loading