import { useState } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import Modal from 'react-modal'
import './styles.css'
import NodeForm from '../NodeForm'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 1000 },
}

function IntentNode({ data }: any) {
  const { flowHandlers, intentName, selectedLanguage = 'PT-BR', nodeHeight } = data
  const { flow } = flowHandlers

  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className="intent-node" style={{ height: nodeHeight || 300 }}>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles}>
        <div style={{ width: '800px', height: '500px' }}>
          <h2>{data.intentName}</h2>
          <div>{data.intentId}</div>

          {Object.keys(flow[intentName]?.conditionals[selectedLanguage])
            .sort((a: any, b: any) => {
              return Number(a) - Number(b)
            })
            .map((key) => {
              return (
                <>
                  <NodeForm
                    flowHandlers={flowHandlers}
                    conditionalKey={key}
                    label={'Speak'}
                    phrase={flow[intentName]?.conditionals[selectedLanguage][key]?.phrase}
                    phraseId={flow[intentName]?.conditionals[selectedLanguage][key]?.phraseId}
                    intentName={intentName}
                    selectedLanguage={selectedLanguage}
                  />
                </>
              )
            })}
        </div>
      </Modal>
      <div>
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
        <div>
          {/* <label htmlFor="text">{data.intentName}</label> */}
          <button
            onClick={() => {
              openModal()
            }}
          >
            {flow[intentName].name}
          </button>
        </div>
      </div>
    </div>
  )
}

export default IntentNode
