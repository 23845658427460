import  styled  from 'styled-components';

export const Container = styled.button`
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    font-weight: 700;
    padding: 0.625rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    background-color: #0665d0;
    border: none;
    box-shadow: 0 0.125rem 0.75rem rgba(4, 65, 134, 0.25);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.12s ease-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out, -webkit-transform 0.12s ease-out;

    &:hover {
        color: #fff;
        background-color: #117ef8;
        box-shadow: 0 0.375rem 0.75rem rgba(4, 65, 134, 0.4);
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }

    &:focus, .btn-hero-primary.focus {
        color: #fff;
        background-color: #117ef8;
        box-shadow: 0 0.125rem 0.75rem rgba(4, 65, 134, 0.25);
    }
`; 
