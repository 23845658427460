import styled from 'styled-components'
import { darken, transparentize } from 'polished'

export const Container = styled.form`
  text-align: center;
  padding: 1rem;

  textarea {
    margin-top: 1rem;

    & + textarea {
      margin-bottom: 1rem;
    }
  }

  h2 {
    display: initial;
    margin-bottom: 2rem;
  }
`
