import axios from 'axios'
import { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../components/Layout'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { isPropertyVisible } from '../../utils/appProperties'
const { REACT_APP_APPS_API } = process.env

interface IPropertiesType {
  name: string
  value: string
  isVisible: boolean
}

export default function CreateEcommerceVtexTemplate() {
  const history = useHistory()
  const [appName, setAppName] = useState('')
  const [vtexAccountName, setVtexAccountName] = useState<IPropertiesType>()
  const [storeName, setStoreName] = useState<IPropertiesType>()
  const [apiUrl, setApiUrl] = useState<IPropertiesType>()
  const [siteUrl, setSiteUrl] = useState<IPropertiesType>()
  const [apiAppKey, setApiAppKey] = useState<IPropertiesType>()
  const [apiAppToken, setApiAppToken] = useState<IPropertiesType>()
  const [defaultLanguage, setDefaultLanguage] = useState<IPropertiesType>()
  const [properties, setProperties] = useState<IPropertiesType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { selectedProjectId }: any = useGlobalContext()
  const plan = localStorage.getItem('planRoute')
  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsLoading(true)

    let newArr = properties

    apiUrl
      ? newArr.push(apiUrl as IPropertiesType)
      : newArr.push({ name: 'VTEX_API_URL', value: '', isVisible: true })

    siteUrl
      ? newArr.push(siteUrl as IPropertiesType)
      : newArr.push({ name: 'VTEX_SITE_URL', value: '', isVisible: true })

    apiAppKey
      ? newArr.push(apiAppKey as IPropertiesType)
      : newArr.push({ name: 'VTEX_API_APP_KEY', value: '', isVisible: false })

    apiAppToken
      ? newArr.push(apiAppToken as IPropertiesType)
      : newArr.push({ name: 'VTEX_API_APP_TOKEN', value: '', isVisible: false })

    defaultLanguage
      ? newArr.push(defaultLanguage as IPropertiesType)
      : newArr.push({ name: 'DEFAULT_LANGUAGE', value: 'PT-BR', isVisible: true })

    vtexAccountName
      ? newArr.push(vtexAccountName as IPropertiesType)
      : newArr.push({ name: 'VTEX_ACCOUNT_NAME', value: '', isVisible: true })

    storeName
      ? newArr.push(storeName as IPropertiesType)
      : newArr.push({ name: 'STORE_NAME', value: '', isVisible: true })

    newArr.push({ name: 'HIGHLIGHTS_FIRST', value: 'true', isVisible: true })
    newArr.push({ name: 'PLAN', value: 'basic', isVisible: true })

    setProperties(newArr)

    console.log({ properties })
    console.log({ newArr })

    const data = {
      appName,
      template: 'VTEX',
      accountId: localStorage.getItem('selectedProjectId'),
      properties,
    }

    let route = localStorage.getItem('planRoute')
    try {
      axios.post(`${REACT_APP_APPS_API}/${route}`, data, {
        headers: {
          authorization: localStorage.getItem('Authorization'),
        },
      })
      setTimeout(() => {
        setIsLoading(false)
        history.push(`/accounts/${selectedProjectId}`)
      }, 5000)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  function handleChange(e: FormEvent) {
    return {
      name: (e.target as HTMLInputElement).name,
      value: (e.target as HTMLInputElement).value,
      isVisible: isPropertyVisible((e.target as HTMLInputElement).name),
    }
  }

  return (
    <Layout>
      <div className="p-3 w-100">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <form className="js-validation-signin" onSubmit={handleSubmit}>
              <div className="py-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg mt-2"
                    id="appName"
                    name="appName"
                    placeholder="App Name"
                    value={appName}
                    onChange={(e) => setAppName(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control form-control-lg mt-2"
                    id="storeName"
                    name="STORE_NAME"
                    placeholder="Store Name"
                    onChange={(e) => setStoreName(handleChange(e))}
                    value={storeName?.value}
                  />
                  {plan !== 'vtex-free' && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-lg mt-2"
                        id="vtexSiteUrl"
                        name="VTEX_SITE_URL"
                        placeholder="Vtex Site Url"
                        onChange={(e) => setSiteUrl(handleChange(e))}
                        value={siteUrl?.value}
                      />
                      <input
                        type="text"
                        className="form-control form-control-lg mt-2"
                        id="accountName"
                        name="VTEX_ACCOUNT_NAME"
                        placeholder="Vtex Account Name"
                        onChange={(e) => setVtexAccountName(handleChange(e))}
                        value={vtexAccountName?.value}
                      />
                      <input
                        type="url"
                        className="form-control form-control-lg mt-2"
                        id="vtexApiUrl"
                        pattern="https?://.+"
                        name="VTEX_API_URL"
                        placeholder="Vtex Api Url"
                        onChange={(e) => setApiUrl(handleChange(e))}
                        value={apiUrl?.value}
                      />
                    </div>
                  )}
                  {plan !== 'vtex-free' && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-lg mt-2"
                        id="vtexApiAppKey"
                        name="VTEX_API_APP_KEY"
                        placeholder="Vtex Api App Key"
                        onChange={(e) => setApiAppKey(handleChange(e))}
                        value={apiAppKey?.value}
                      />
                      <input
                        type="password"
                        className="form-control form-control-lg mt-2"
                        id="vtexApiAppToken"
                        name="VTEX_API_APP_TOKEN"
                        placeholder="Vtex Api App Token"
                        onChange={(e) => setApiAppToken(handleChange(e))}
                        value={apiAppToken?.value}
                      />
                    </div>
                  )}
                  <div className="justify-content-end mt-2">
                    <label htmlFor="example-text-input">Default Language</label>
                    <select
                      onChange={(e) => setDefaultLanguage(handleChange(e))}
                      defaultValue="PT-BR"
                      className="form-control"
                      id="defaultLanguage"
                      name="DEFAULT_LANGUAGE"
                      value={defaultLanguage?.value}
                    >
                      <option value="" selected={true} disabled>
                        App Default Language
                      </option>
                      <option value="PT-BR">Portuguese Brasil</option>
                      <option value="EN-US">English</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary">
                    Create Template
                    {isLoading && (
                      <div>
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
