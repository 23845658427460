import { Handle, Position } from 'react-flow-renderer'
import './styles.css'

function IntentNode({ data, sourcePosition, targetPosition }: any) {
  return (
    <>
      <div className="user-input-node">
        {sourcePosition === 'left' && <Handle type="target" position={Position.Left} />}
        {targetPosition === 'left' && <Handle type="source" position={Position.Left} />}
        {sourcePosition === 'right' && <Handle type="target" position={Position.Right} />}
        {targetPosition === 'right' && <Handle type="source" position={Position.Right} />}
        {sourcePosition === 'bottom' && <Handle type="target" position={Position.Bottom} />}
        {targetPosition === 'bottom' && <Handle type="source" position={Position.Bottom} />}
        {sourcePosition === 'top' && <Handle type="target" position={Position.Top} />}
        {targetPosition === 'top' && <Handle type="source" position={Position.Top} />}

        <div>
          {/* <label htmlFor="text">{data.intentName}</label> */}

          <small>▶️ {data.speak}</small>
        </div>
      </div>
    </>
  )
}

export default IntentNode
