import jwtDecode from 'jwt-decode'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useAPI } from '../services/api'

interface App {
  id: string
  name: string
}

interface Account {
  id: string
  name: string
  apps: App[]
}

interface User {
  email?: string
  accounts?: Account[]
  currentAppId: string
}

interface SignInCredentials {
  email: string
  password: string
}

interface AccountVerificationBody {
  verificationCode: string
}
interface AuthContextData {
  signIn: (credetials: SignInCredentials) => Promise<void>
  signOut: () => void
  confirmAccount: (verificationBody: AccountVerificationBody) => Promise<void>
  resendConfirmationEmail: () => void
  sendResetPasswordCode: (email: any) => void
  resetPassword: (token: any, password: any, email: any) => void
  user: User
  isAuthenticated: boolean
  getUserEmail: () => string
}

interface AuthProviderProps {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>({
    currentAppId: localStorage.getItem('selectedAppId') || '',
  })
  const history = useHistory()
  const location = useLocation()
  const isAuthenticated = !!user

  const api = useAPI()

  function getUserEmail() {
    const t = localStorage.getItem('Authorization')
    const decoded: any = jwtDecode(t)

    return decoded.email
  }
  async function getUserAccount() {
    try {
      const { data: avaiableAccounts } = await api.get('accounts')

      const { data: accounts } = await api.get(`accounts/${avaiableAccounts[0].id}`)
      const { apps } = accounts

      const currentAppId = apps[0].id
      setUser({ currentAppId })

      localStorage.setItem('currentAppId', currentAppId)
    } catch (e) {
      console.log(e)
    }
  }

  function signOut() {
    localStorage.clear()
    if (location.pathname !== '/') {
      history.push('/')
    }
  }

  async function sendResetPasswordCode(email: string) {
    const data: any = await api.post('/auth/forgot-password', { email: email })
  }

  async function resetPassword(token: any, password: any, email: any) {
    console.log(email, token, password)
    const data: any = await api.post('/auth/reset-password', {
      email: email,
      token: token,
      password: password,
    })
  }

  async function confirmAccount({ verificationCode }: AccountVerificationBody) {
    const userCreatedToken: any = localStorage.getItem('authorization')
    const decoded: any = jwtDecode(userCreatedToken)

    const response = await api.post('/auth/confirm-account', {
      token: verificationCode,
      uiUserId: decoded.id,
    })

    const { auth } = response.data
    localStorage.removeItem('Authorization')
    localStorage.setItem('Authorization', `Bearer ${auth}`)
    //localStorage.removeItem('authorization');
    api.defaults.headers['Authorization'] = `Bearer ${auth}`
    //todo remover comentário abaixo após implementar accounts
    //await getUserAccount();
    history.push('/homepage')
  }

  async function signIn({ email, password }: SignInCredentials) {
    const response = await api.post('/auth', {
      email,
      password,
    })

    const { auth } = response.data

    const decoded: any = jwtDecode(auth)

    if (decoded.status === 'verified') {
      localStorage.setItem('Authorization', `Bearer ${auth}`)

      api.defaults.headers['Authorization'] = `Bearer ${auth}`

      //await getUserAccount();

      history.push('/homepage')
    } else if (decoded.status === 'pending') {
      localStorage.setItem('Authorization', `Bearer ${auth}`)
      history.push('/confirm-account')
    }

    // cookie.set('auth.token', auth, {
    //   expires: 30, //days
    //   path: '/'
    // });
  }

  async function resendConfirmationEmail() {
    const auth: any = localStorage.getItem('Authorization')

    const decode: any = jwtDecode(auth)

    const { data } = await api.post('/auth/resend-confirmation-email', { email: decode.email })

    console.log(data)
  }

  return (
    <AuthContext.Provider
      value={{
        resetPassword,
        sendResetPasswordCode,
        resendConfirmationEmail,
        confirmAccount,
        signIn,
        signOut,
        isAuthenticated,
        user,
        getUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
