import Layout from '../components/Layout'
import axios from 'axios'

import { Sankey, Tooltip } from 'recharts'

import SankeyNode from '../components/Sankey/SankeyNode'
import SankeyLink from '../components/Sankey/SankeyLink'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../contexts/GlobalContext'

interface SankeyData {
  nodes: {
    name: string
  }[]
  links: {
    source: number
    target: number
    value: number
  }[]
}

const { REACT_APP_ANALYTICS_API } = process.env

export default function Analytics() {
  const [sankeyData, setSankeyData] = useState<SankeyData>({ nodes: [], links: [] })
  const [isLoading, setIsLoading] = useState(true)
  const { selectedAppId }: any = useGlobalContext()

  async function handleLoadContent() {
    if (!isLoading) setIsLoading(true)

    const { data } = await axios.get<SankeyData>(
      `${REACT_APP_ANALYTICS_API}/${selectedAppId}/sankey`
    )

    setSankeyData(data)

    setIsLoading(false)
  }

  useEffect(() => {
    handleLoadContent()
  }, [])

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">Analytics</h1>
            {/*             <Link className="btn btn-hero-primary my-2" to={{ pathname: '/analytics/path' }}>
              Per session
            </Link> */}
          </div>
        </div>
      </div>

      <div className="content">
        <div className={`block block-themed ${isLoading && 'block-mode-loading'}`}>
          <div className="block-header bg-primary-dark">
            <h3 className="block-title">Interaction Path</h3>
          </div>
          {(sankeyData.links.length > 0 && (
            <div className="block-content">
              {!isLoading && (
                <Sankey
                  data={sankeyData}
                  width={1660}
                  height={760}
                  margin={{ right: 135 }}
                  nodePadding={30}
                  linkCurvature={0.75}
                  node={<SankeyNode />}
                  link={<SankeyLink />}
                >
                  <Tooltip />
                </Sankey>
              )}
            </div>
          )) || <div className="block-content font-size-h5 text-center"> No data yet</div>}
          {/* {sankeyData.nodes.length > 0 || } */}
        </div>
      </div>
    </Layout>
  )
}
