export const permissions: any = {
  'alexa::profile:name:read': 'Customer Full Name',
  'alexa::alerts:reminders:skill:readwrite': 'Reminders Read and Write',
  'alexa::profile:given_name:read': 'Customer Given Name',
  'read::alexa:device:all:address:country_and_postal_code': 'Customer Country and Postal Code',
  'alexa::household:lists:write': 'Customer Lists Write',
  'alexa::profile:email:read': 'Customer Email Address',
  'alexa::persistent_unit_id::read': 'Customer Persistent Id',
  'read::alexa:device:all:address': 'Customer Addres Read',
  'alexa::household:lists:read': 'Customer List Read',
  'alexa::devices:all:notifications:write': 'Notifications Write',
  'alexa::person_id': 'Customer Id',
  'alexa::devices:all:geolocation:read': 'Customer Localization',
  'alexa::profile:mobile_number:read': 'Customer Phone Number',
}

export const getPermissionDescription = (name: any) => {
  return permissions[name]
}
