import { useState } from "react";
import { Layer } from "recharts";


export default function SankeyLink({
  sourceX,
  targetX,
  sourceY,
  targetY,
  sourceControlX,
  targetControlX,
  sourceRelativeY,
  targetRelativeY,
  linkWidth,
  index,
}: any) {
  const [color, setColor] = useState('#9cd5e9');

  return (
    <Layer key={index}>
      <path 
        d={`
          M${sourceX},${sourceY + linkWidth / 2}
          C${sourceControlX},${sourceY + linkWidth / 2}
            ${targetControlX},${targetY + linkWidth / 2}
            ${targetX},${targetY + linkWidth / 2}
          L${targetX},${targetY - linkWidth / 2}
          C${targetControlX},${targetY - linkWidth / 2}
            ${sourceControlX},${sourceY - linkWidth / 2}
            ${sourceX},${sourceY - linkWidth / 2}
          Z
        `}
        fill={color}
        strokeWidth="0"
        onMouseEnter={() => setColor('#058DC7')}
        onMouseLeave={() => setColor('#9cd5e9')}
      />
    </Layer>
  );
}