import { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { AuthContext } from '../../contexts/AuthContext'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { getAppStatus } from '../../utils/strings/appStatus'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import './styles.css'
import { Question } from 'phosphor-react'

interface IPropertiesTypeGet {
  name: string
  value: string
  is_visible: boolean
}

const { REACT_APP_APPS_API } = process.env

export default function Layout({ children }: any) {
  const history = useHistory()
  const [sidebarClass, setSidebarClass] = useState(isMobile ? '' : 'sidebar-mini')

  const [appStatus, setAppStatus]: any = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isDataSetted, setIsDataSetted]: any = useState(false)

  const { signOut } = useContext(AuthContext)
  let { selectedAppId, selectedAppName, selectedProjectId }: any = useGlobalContext()
  const selectedAppVersion = localStorage.getItem('selectedAppVersion')
  function sidebarToggle() {
    if (isMobile) {
      setSidebarClass('')
      return
    }
    sidebarClass === 'sidebar' ? setSidebarClass('sidebar-mini') : setSidebarClass('sidebar')
  }

  function getNormalizedPropertie(propertyName: string, propertiesArray: IPropertiesTypeGet[]) {
    const {
      name,
      value,
      is_visible: isVisible,
    } = propertiesArray.find((property) => property.name === propertyName)

    return { name, value, isVisible }
  }

  async function handleLoadContent() {
    if (!isLoading) setIsLoading(true)
    const { data } = await axios.get<IPropertiesTypeGet[]>(
      `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
      {
        headers: {
          authorization: localStorage.getItem('Authorization'),
        },
      }
    )

    const status = getNormalizedPropertie('STATUS', data)
    setAppStatus(getAppStatus(status.value))

    setIsLoading(false)
    setIsDataSetted(true)
  }

  useEffect(() => {
    handleLoadContent()
  }, [isDataSetted])

  return (
    <div
      id="page-container"
      className={`sidebar-o ${sidebarClass} enable-page-overlay side-scroll page-header-fixed page-header-dark main-content-narrow`}
    >
      <nav id="sidebar" aria-label="Main Navigation">
        <div className="bg-header-dark">
          <div className="content-header bg-white-10">
            <div>
              {/* <Link
                className="d-lg-none text-white ml-2"
                data-toggle="layout"
                data-action="sidebar_close"
                to={{ pathname: '/dashboard' }}
              > */}
              <i
                className="si si-close d-lg-none text-white ml-2"
                onClick={() => sidebarToggle()}
                data-toggle="layout"
                data-action="sidebar_close"
              />
              {/* </Link> */}
            </div>
          </div>
        </div>
        <div className="content-side content-side-full">
          <ul className="nav-main">
            {selectedAppId && (
              <li className="nav-main-item">
                <Link className="nav-main-link" to={{ pathname: '/dashboard' }}>
                  <i className="nav-main-link-icon si si-cursor"></i>
                  <span className="nav-main-link-name">Dashboard</span>
                </Link>
              </li>
            )}

            <li className="nav-main-heading" style={{ paddingTop: '.4rem' }}>
              VOICE DESIGN
            </li>

            <li className="nav-main-item">
              <Link className="nav-main-link" to={{ pathname: `/accounts/${selectedProjectId}` }}>
                <i className="nav-main-link-icon si si-microphone fa-2x"></i>
                <span className="nav-main-link-name">Apps</span>
              </Link>
            </li>

            {selectedAppId && (
              <li className="nav-main-item">
                <Link className="nav-main-link" to={{ pathname: `/${selectedAppId}/highlights` }}>
                  <i className="nav-main-link-icon si si-star fa-2x"></i>
                  <span className="nav-main-link-name">App Highlights</span>
                </Link>
              </li>
            )}

            {selectedAppId && (
              <li className="nav-main-item">
                <Link className="nav-main-link" to={{ pathname: `/${selectedAppId}/faqs` }}>
                  <Question className="nav-main-link-icon fa-2x"></Question>
                  <span className="nav-main-link-name">App FAQs</span>
                </Link>
              </li>
            )}

            {selectedAppId && (
              <li className="nav-main-item">
                <Link
                  className="nav-main-link"
                  to={{ pathname: `/${selectedAppId}/conversational` }}
                >
                  <i className="nav-main-link-icon si si-bubbles fa-2x"></i>
                  <span className="nav-main-link-name">Dialogs</span>
                </Link>
              </li>
            )}

            {selectedAppId && (
              <li className="nav-main-item">
                <Link
                  className="nav-main-link"
                  to={{ pathname: `/${selectedAppId}/notifications` }}
                >
                  <i className="nav-main-link-icon si si-bell fa-2x"></i>
                  <span className="nav-main-link-name">Notifications</span>
                </Link>
              </li>
            )}

            <li className="nav-main-item">
              <a
                className="nav-main-link"
                href="/simulator"
                data-toggle="layout"
                data-action="side_overlay_toggle"
                style={{ pointerEvents: 'none' }}
              >
                <i className="nav-main-link-icon si si-control-play" style={{ color: 'grey' }}></i>
                <span
                  className="nav-main-link-name disable-item"
                  onClick={() => console.log('simulator')}
                >
                  Simulator
                </span>
                <span className="nav-main-link-badge badge badge-pill badge-success">Soon</span>
              </a>
            </li>

            <li className="nav-main-heading" style={{ paddingTop: '.4rem' }}>
              STRATEGY
            </li>

            <li className="nav-main-item">
              <a className="nav-main-link" href="/analytics" style={{ pointerEvents: 'none' }}>
                <i className="nav-main-link-icon si si-graph" style={{ color: 'grey' }}></i>
                <span className="nav-main-link-name disable-item">Analytics</span>
                <span className="nav-main-link-badge badge badge-pill badge-success">Soon</span>
              </a>
            </li>
            <li className="nav-main-item">
              <a className="nav-main-link" href="/voice-seo" style={{ pointerEvents: 'none' }}>
                <i
                  className="nav-main-link-icon si si-magnifier mr-2"
                  style={{ color: 'grey' }}
                ></i>
                <span className="nav-main-link-name disable-item">Voice SEO</span>{' '}
                <span className="nav-main-link-badge badge badge-pill badge-success">Soon</span>{' '}
              </a>
            </li>
            <li className="nav-main-item">
              <a className="nav-main-link" href="/homepage" style={{ pointerEvents: 'none' }}>
                <i className="nav-main-link-icon si si-like" style={{ color: 'grey' }}></i>
                <span className="nav-main-link-name disable-item">Insights</span>
                <span className="nav-main-link-badge badge badge-pill badge-success">Soon</span>
              </a>
            </li>

            <li className="nav-main-heading" style={{ paddingTop: '.4rem' }}>
              INTEGRATION
            </li>

            <li className="nav-main-item">
              <a className="nav-main-link" href="/data-sources" style={{ pointerEvents: 'none' }}>
                <i className="nav-main-link-icon si si-size-actual" style={{ color: 'grey' }}></i>
                <span className="nav-main-link-name disable-item">Data Sources</span>
                <span className="nav-main-link-badge badge badge-pill badge-success">Soon</span>
              </a>
            </li>

            <li className="nav-main-item">
              <a className="nav-main-link" href="/connectors" style={{ pointerEvents: 'none' }}>
                <i className="nav-main-link-icon si si-grid" style={{ color: 'grey' }}></i>
                <span className="nav-main-link-name disable-item">Connectors</span>
                <span className="nav-main-link-badge badge badge-pill badge-success">Soon</span>
              </a>
            </li>

            {/* <li className="nav-main-item">
              <a
                className="nav-main-link nav-main-link-submenu"
                data-toggle="submenu"
                aria-haspopup="true"
                aria-expanded="false"
                href="#"
              >
                <i className="nav-main-link-icon si si-equalizer"></i>
                <span className="nav-main-link-name">More Integrations</span>
              </a>

              <ul className="nav-main-submenu">
                <li className="nav-main-item">
                  <a className="nav-main-link" href="/transactionals">
                    <i className="nav-main-link-icon si si-shuffle"></i>
                    <span className="nav-main-link-name">Transactionals</span>
                  </a>
                </li>

                <li className="nav-main-item">
                  <a className="nav-main-link" href="/authentications">
                    <i className="nav-main-link-icon si si-key"></i>
                    <span className="nav-main-link-name">Authentications</span>
                  </a>
                </li>

                <li className="nav-main-item">
                  <a className="nav-main-link" href="/widgets">
                    <i className="nav-main-link-icon si si-puzzle"></i>
                    <span className="nav-main-link-name">Widgets</span>
                  </a>
                </li>

                <li className="nav-main-item">
                  <a className="nav-main-link" href="/sdks">
                    <i className="nav-main-link-icon si si-layers"></i>
                    <span className="nav-main-link-name">SDKs</span>
                  </a>
                </li>

                <li className="nav-main-item">
                  <a className="nav-main-link" href="/apis">
                    <i className="nav-main-link-icon si si-share"></i>
                    <span className="nav-main-link-name">APIs</span>
                    <span className="nav-main-link-badge badge badge-pill badge-success">Pro</span>
                  </a>
                </li>

                <li className="nav-main-item">
                  <a className="nav-main-link" href="/plug-ins">
                    <i className="nav-main-link-icon si si-link"></i>
                    <span className="nav-main-link-name">Plug-ins</span>
                    <span className="nav-main-link-badge badge badge-pill badge-success">Pro</span>
                  </a>
                </li>
              </ul>
            </li> */}
            {selectedAppName && (
              <>
                <li className="nav-main-heading" style={{ paddingTop: '.4rem' }}>
                  APP: {selectedAppName}
                </li>
                <li className="nav-main-item">
                  <div
                    className="nav-main-link nav-main-link-submenu"
                    data-toggle="submenu"
                    aria-haspopup="true"
                    aria-expanded="false"
                    //href="#"
                  >
                    <i className="nav-main-link-icon si si-settings"></i>
                    <span className="nav-main-link-name">{selectedAppName}</span>
                  </div>
                  <ul className="nav-main-submenu">
                    <li className="nav-main-item">
                      <span
                        className="nav-main-link"
                        onClick={() => {
                          console.log('Version', selectedAppVersion)
                          selectedAppVersion
                            ? history.push(`/${selectedAppId}/${selectedAppVersion}/settings`)
                            : history.push(`/${selectedAppId}/settings`)
                        }}
                      >
                        <i className="nav-main-link-icon si si-wrench"></i>
                        <span className="nav-main-link-name">App Settings</span>
                      </span>
                    </li>

                    <li className="nav-main-item">
                      <a
                        className="nav-main-link"
                        href={
                          selectedAppVersion
                            ? `/${selectedAppId}/${selectedAppVersion}/distribute`
                            : `/${selectedAppId}/distribute`
                        }
                      >
                        <i className="nav-main-link-icon fa fa-network-wired"></i>
                        <span className="nav-main-link-name">Distribute</span>
                      </a>
                    </li>

                    <li className="nav-main-item">
                      <a className="nav-main-link" href={`/templates`}>
                        <i className="nav-main-link-icon si si-plus"></i>
                        <span className="nav-main-link-name">New App</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>

      <header id="page-header">
        <div className="content-header">
          <div>
            <button
              type="button"
              className="btn btn-dual mr-1"
              data-toggle="layout"
              data-action="sidebar_toggle"
              onClick={sidebarToggle}
              style={{ verticalAlign: 'middle' }}
            >
              <i className="si si-list fa-2x"></i>
            </button>

            <Link
              className="font-w600 font-size-h1 mr-1"
              to={{ pathname: '/homepage' }}
              style={{ verticalAlign: 'middle' }}
            >
              <span className="primary-color">verb</span>
              <span className="text-white">all</span>
            </Link>
          </div>
          <div>
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn btn-dual"
                id="page-header-app-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="si si-microphone font-size-base mr-1"></i>
                <span className="d-none d-sm-inline-block">
                  App: <strong>{selectedAppName || 'No app Selected'}</strong>
                </span>
              </button>
              {selectedAppId && (
                <div
                  className="dropdown-menu dropdown-menu-right p-0"
                  aria-labelledby="page-header-app-dropdown"
                >
                  <div className="p-2">
                    <Link
                      className="dropdown-item"
                      to={{
                        pathname: selectedAppVersion
                          ? `/${selectedAppId}/${selectedAppVersion}/settings`
                          : `/${selectedAppId}/settings`,
                      }}
                    >
                      <i className="si si-wrench font-size-base mr-1"></i> App Settings
                    </Link>

                    <div role="separator" className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      to={{ pathname: `/${selectedAppId}/distribute` }}
                    >
                      <i className="fa fa-network-wired font-size-base mr-1"></i> Distribute
                    </Link>
                    {appStatus?.value === 'test' && (
                      <>
                        <div role="separator" className="dropdown-divider"></div>
                        <Link
                          className="dropdown-item"
                          to={{ pathname: `/${selectedAppId}/certificate` }}
                        >
                          <i className="si si-check mr-1"></i> Certificate
                        </Link>
                      </>
                    )}
                    <div role="separator" className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/templates">
                      <i className="si si-plus mr-1"></i> New App
                    </a>
                  </div>
                </div>
              )}
            </div>
            {/*             <button
              type="button"
              className="btn btn-primary"
              onClick={() => console.log('simulator')}
              data-toggle="layout"
              data-action="side_overlay_toggle"
            >
              <i className="si si-control-play mr-1"></i>
              <span className="d-none d-sm-inline-block">Simulator</span>
            </button> */}
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn btn-dual"
                id="page-header-user-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="si si-user mr-1"></i>
                <span className="d-none d-sm-inline-block">Account</span>
              </button>

              <div
                className="dropdown-menu dropdown-menu-right p-0"
                // aria-labelledby="page-header-user-dropdown"
              >
                <div className="bg-primary-dark rounded-top font-w600 text-white text-center p-3">
                  Hi, verballer
                </div>
                <div className="p-2">
                  {/*                   <a className="dropdown-item" href={`/account/${selectedProjectId}/settings`}>
                    <i className="far fa-fw fa-building mr-1"></i> Settings
                  </a>
                  <div role="separator" className="dropdown-divider"></div>*/}
                  <a className="dropdown-item" onClick={signOut}>
                    <i className="si si-logout mr-1" onClick={signOut}></i> Sign Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="page-header-loader" className="overlay-header bg-primary-darker">
          <div className="content-header">
            <div className="w-100 text-center">
              <i className="fa fa-fw fa-2x fa-sun fa-spin text-white"></i>
            </div>
          </div>
        </div>
      </header>

      <main id="main-container">{children}</main>
    </div>
  )
}
