import { FormEvent, useContext, useState } from "react";

import { useAPI } from "../../services/converstionalApi";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

import Layout from "../../components/Layout";
import { toast } from "react-toastify";

export default function AddPhrase() {
  const [type, setType] = useState('text');
  const [language, setLanguage] = useState('PT-BR');
  const [value, setValue] = useState('');

  const api = useAPI();
  const { user } = useContext(AuthContext);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      await api.post(`apps/${user.currentAppId}/phrase`, {
        value,
        type,
        language
      });

      setValue('');
      toast.success('Phrase saved successfully');
    } catch (err) {
      toast.error(`An error ocurred: ${err.response.data.message}`);
    }

  }

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">Add New Phrase</h1>
            <Link to={{ pathname: "/phrase" }} className="btn btn-light">
              <i className="si si-action-undo"></i> <span className="d-none d-sm-inline-block">Back</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h3 className="block-title">
              Fill in the fields below to create a new dialog.
              <i className="fa fa-info-circle text-primary"></i>
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="example-text-input">Title</label>
                    <input type="text" className="form-control" id="name" name="name" />
                  </div>

                  <div className="form-group form-row">
                    <div className="col-4">
                      <label htmlFor="example-text-input">Application</label>
                      <select className="form-control" id="idApp" name="idApp">
                        <option>--</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="example-text-input">Language</label>
                      <select className="form-control" onChange={(e) => setLanguage(e.target.value)} id="idLanguage" name="idLanguage">
                        <option value="PT-BR">Portuguese BR</option>
                        <option value="EN-US">English US</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="example-text-input">Dialog Type</label>
                      <select onChange={(e) => setType(e.target.value)} className="form-control" id="type" name="type">
                        <option value="text">Text</option>
                        <option value="image_url">Image URL</option>
                        <option value="audio_url">Audio URL</option>
                      </select>
                    </div>
                  </div>

                  <div id="normal-message">
                    <div className="form-group">
                      <label htmlFor="example-text-input">Invotation Terms</label>
                      <input type="text" className="form-control" id="invocationTerms" name="invocationTerms" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="example-text-input">Message</label>
                      <textarea onChange={(e) => setValue(e.target.value)} className="form-control code" value={value} rows={10} placeholder="..." id="message" name="message"></textarea>
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-hero-danger mt-2 mb-2">
                      Add Phrase
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}