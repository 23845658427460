import axios from 'axios'

import { Link } from 'react-router-dom'
import { FormEvent, useContext, useEffect, useState } from 'react'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { toast, ToastContainer } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import { isPropertyVisible } from '../../utils/appProperties'
import { getAppStatus } from '../../utils/strings/appStatus'
import { sendEmail } from '../../utils/sendEmail'
import './styles.css'
const { REACT_APP_APPS_API } = process.env

interface IPropertiesTypeGet {
  name: string
  value: string
  is_visible: boolean
}

interface IPropertiesTypePost {
  name: string
  value: string
  isVisible: boolean
}

export default function AppDistribute() {
  const { selectedAppId, selectedAppName }: any = useGlobalContext()
  const { getUserEmail } = useContext(AuthContext)
  const [isLoading, setIsLoading]: any = useState(false)
  const [isSaving, setIsSaving]: any = useState(false)
  const [isDataSetted, setIsDataSetted]: any = useState(false)
  const [isReadyToDistribute, setIsReadyToDistribute]: any = useState(false)
  const [appStatus, setAppStatus]: any = useState<IPropertiesTypePost | IPropertiesTypeGet>()

  const [smallLogoImage, setSmallLogoImage] = useState<IPropertiesTypePost>()
  const [largeLogoImage, setLargeLogoImage] = useState<IPropertiesTypePost>()
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState<IPropertiesTypePost>()
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState<IPropertiesTypePost>()
  const [fullDescription, setFullDescription] = useState<IPropertiesTypePost>()
  const [shortDescription, setShortDescription] = useState<IPropertiesTypePost>()

  async function handleSubmit(e: FormEvent) {
    setIsSaving(true)
    e.preventDefault()

    let properties = [
      smallLogoImage,
      largeLogoImage,
      termsOfServiceUrl,
      privacyPolicyUrl,
      fullDescription,
      shortDescription,
      appStatus,
    ]

    try {
      properties.map(async (property) => {
        await axios.post(`${REACT_APP_APPS_API}/${selectedAppId}/properties`, property, {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        })
        setIsSaving(false)
      })

      setIsReadyToDistribute(true)
      toast.success('Configs saved successfully')
    } catch (error: any) {
      console.log('ERRO', error.response)
      toast.error(`An error ocurred: ${error.response.data.message}`)
    }
  }

  async function handleSubmitDistribute() {
    const newAppStatus = appStatus
    newAppStatus.value = 'test'

    await setAppStatus(newAppStatus)

    try {
      const { data } = await axios.post(
        `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
        newAppStatus,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )
      console.log(data)
      setIsSaving(false)

      const email = await sendEmail({
        emailTo: getUserEmail(),
        text: `Hello, and thanks for submitting your application to distribution.

        We are starting the distribution process for your voice app, Name: ${selectedAppName} & ID: ${selectedAppId}.

        This process can take between 1 to 2 work days, depending on submission demand. We will let you know by email as soon as it's done, and will send instructions on how to test your voice app using Amazon Alexa and Google Assistant.

        We will be back in touch soon.

        Thank you!

        best regards from the Verball Team.
        `,
        subject: `App Sent to distribution`,
      })
      toast.success(`App Sent to Distribution ${email}`)
    } catch (error: any) {
      console.log('ERRO', error.response)
      toast.error(`An error ocurred: ${error.response.data.message}`)
    }
  }

  function getNormalizedPropertie(propertyName: string, propertiesArray: IPropertiesTypeGet[]) {
    const property = propertiesArray.find((property) => property.name === propertyName)

    if (property) {
      const { name, value, is_visible: isVisible } = property
      return { name, value, isVisible }
    }

    return { name: propertyName, value: '', isVisible: isPropertyVisible(propertyName) }
  }
  async function handleLoadContent() {
    try {
      setIsLoading(true)

      const { data } = await axios.get<IPropertiesTypeGet[]>(
        `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      console.log(data)

      setSmallLogoImage(getNormalizedPropertie('SMALL_LOGO_IMAGE', data))
      setLargeLogoImage(getNormalizedPropertie('LARGE_LOGO_IMAGE', data))

      setTermsOfServiceUrl(getNormalizedPropertie('THERMS_OF_SERVICE_URL', data))
      setPrivacyPolicyUrl(getNormalizedPropertie('PRIVACY_POLICY_URL', data))

      setShortDescription(getNormalizedPropertie('SHORT_DESCRIPTION', data))
      setFullDescription(getNormalizedPropertie('FULL_DESCRIPTION', data))

      setAppStatus(getNormalizedPropertie('STATUS', data))
      setIsDataSetted(true)
      setIsLoading(false)
    } catch (e) {
      console.log(e?.message)
    }
  }

  function handleChange(e: FormEvent) {
    if ((e.target as HTMLInputElement).type === 'checkbox') {
      const newProperty = {
        name: (e.target as HTMLInputElement).name,
        value: String((e.target as HTMLInputElement).checked),
        isVisible: isPropertyVisible((e.target as HTMLInputElement).name) === 'true' ? true : false,
      } as IPropertiesTypePost

      return newProperty
    }
    return {
      name: (e.target as HTMLInputElement).name,
      value: (e.target as HTMLInputElement).value,
      isVisible: isPropertyVisible((e.target as HTMLInputElement).name) === 'true' ? true : false,
    }
  }

  useEffect(() => {
    handleLoadContent()
  }, [isDataSetted])

  return (
    <Layout>
      {console.log('Properties', {
        smallLogoImage,
        largeLogoImage,
        termsOfServiceUrl,
        privacyPolicyUrl,
        shortDescription,
        fullDescription,
      })}
      <div className="content">
        <ToastContainer />
        <h2 className="content-heading pt-0 mt-0">
          <i className="fa fa-network-wired font-size-26 mr-2"></i>
          <strong>Distribute</strong>
        </h2>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div className="block block-themed">
              <div className="block-content">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: 250 }}></th>
                      <th className="text-center" style={{ width: 500 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="shortDescription">
                          Short Description
                        </label>
                      </td>
                      <td className="text-left">
                        <input
                          onChange={(e) => setShortDescription(handleChange(e))}
                          className="form-control form-control-sm form-input"
                          name="SHORT_DESCRIPTION"
                          type="text"
                          id="shortDescription"
                          value={shortDescription?.value}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="fullDescription">
                          Full Description
                        </label>
                      </td>
                      <td className="text-left">
                        <textarea
                          onChange={(e) => setFullDescription(handleChange(e))}
                          className="form-control form-control-sm form-input"
                          name="FULL_DESCRIPTION"
                          id="fullDescription"
                          value={fullDescription?.value}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="privacyPolicyUrl">
                          Privacy Policy
                        </label>
                      </td>
                      <td className="text-left">
                        <input
                          onChange={(e) => setPrivacyPolicyUrl(handleChange(e))}
                          className="form-control form-control-sm form-input"
                          name="PRIVACY_POLICY_URL"
                          type="url"
                          id="privacyPolicyUrl"
                          value={privacyPolicyUrl?.value}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="termsOfServiceUrl">
                          Terms of Service
                        </label>
                      </td>
                      <td className="text-left">
                        <input
                          onChange={(e) => setTermsOfServiceUrl(handleChange(e))}
                          className="form-control form-control-sm form-input"
                          name="THERMS_OF_SERVICE_URL"
                          type="url"
                          id="termsOfServiceUrl"
                          value={termsOfServiceUrl?.value}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="smallLogoImage">
                          Small Logo
                        </label>
                      </td>
                      <td className="text-left">
                        <input
                          onChange={(e) => setSmallLogoImage(handleChange(e))}
                          className="form-control form-control-sm form-input"
                          name="SMALL_LOGO_IMAGE"
                          type="url"
                          id="smallLogoImage"
                          value={smallLogoImage?.value}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="font-w600">
                        <label className="font-w600 p-2" htmlFor="largeLogoImage">
                          Large Logo
                        </label>
                      </td>
                      <td className="text-left">
                        <input
                          onChange={(e) => setLargeLogoImage(handleChange(e))}
                          className="form-control form-control-sm form-input"
                          name="LARGE_LOGO_IMAGE"
                          type="url"
                          id="largeLogoImage"
                          value={largeLogoImage?.value}
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="mb-2 text-center" style={{ width: '100%' }}>
                <button
                  type="submit"
                  className="btn btn-hero-primary my-2 text-center"
                  onClick={handleSubmit}
                >
                  {isSaving || <i className="fa fa-save mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  <span>Save</span>
                </button>

                <button
                  type="submit"
                  className="btn btn-hero-primary ml-2 my-2 text-center"
                  onClick={handleSubmitDistribute}
                  disabled={!isReadyToDistribute}
                >
                  {isSaving || <i className="fa fa-check mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>}
                  <span>Distribute</span>
                </button>
                {/*             <button className="btn btn-hero-primary my-2 text-center" onClick={handleSubmit}>
              <i className="si si-check mr-1"></i>

            </button> */}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}
