import { FormEvent, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { useAPI } from '../../services/api'
import Layout from '../../components/Layout'

export default function AddIntent() {
  const [name, setName] = useState('')
  const { user } = useContext(AuthContext)
  const api = useAPI()

  async function submit(e: FormEvent) {
    e.preventDefault()

    try {
      await api.post(`apps/${user.currentAppId}/intent`, {
        name,
      })

      toast.success('Intent saved successfully')
    } catch (err) {
      toast.error(`An error ocurred: ${err.response.data.message}`)
    }
  }

  return (
    <Layout>
      <div>
        <div className="bg-body-light">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                Add New Phrase
              </h1>
              <Link to={{ pathname: '/intent' }} className="btn btn-light">
                <i className="si si-action-undo" />{' '}
                <span className="d-none d-sm-inline-block">Back</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="block block-rounded">
            <div className="block-header block-header-default">
              <h3 className="block-title">Select a name to create an intent.</h3>
            </div>
            <div className="row push">
              <div className="col-lg-8 col-xl-8">
                <div className="block-content">
                  <form className="js-validation-signin" onSubmit={submit}>
                    <div className="form-group">
                      <label htmlFor="example-text-input">Name</label>
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        id="name"
                        name="name"
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-hero-info mt-2 mb-2">
                        Add Intent
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
