import { FormEvent, useState } from 'react'

import Modal from 'react-modal'

//import closeImg from './img/close.svg'

import { Container, RadioButton, TransactionTypeContainer } from './styles'

interface NewHighlightModalProps {
    isOpen: boolean
    onRequestClose: () => void
}
export function NewHighlightModal({ isOpen, onRequestClose }: NewHighlightModalProps) {
    const [type, setType] = useState('deposit')
    const [title, setTitle] = useState('')
    const [amount, setAmount] = useState(0)
    const [category, setCategory] = useState('')

    async function handleCreateNewHighlight(event: FormEvent) {
        event.preventDefault()
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            {/*             <button type="button" onClick={onRequestClose} className="react-modal-close">
                <img src={closeImg} alt="Close" />
            </button> */}

            <Container onSubmit={handleCreateNewHighlight}>
                <h2>Register New Highlight</h2>
                <input
                    placeholder="Title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
                <input
                    type="number"
                    placeholder="Value"
                    value={amount}
                    onChange={(event) => setAmount(Number(event.target.value))}
                />

                <input
                    placeholder="Category"
                    value={category}
                    onChange={(event) => setCategory(event.target.value)}
                />
                <button type="submit">Register</button>
            </Container>
        </Modal>
    )
}
