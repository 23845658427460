import { createContext, useState, useContext, useEffect } from 'react'
import getAccounts from '../utils/getAccounts'

const GlobalContext = createContext({})

export default function GlobalProvider({ children }: any) {
  const [selectedProjectId, setSelectedProjectId]: any = useState(null)
  const [selectedAppId, setSelectedAppId] = useState(localStorage.getItem('selectedAppId'))
  const [selectedAppName, setSelectedAppName] = useState(localStorage.getItem('selectedAppName'))
  const [selectedAppVersion, setSelectedAppVersion] = useState(localStorage.getItem('version'))
  const [accounts, setAccounts] = useState([])
  const [userToken, setUserToken] = useState(localStorage.getItem('Authorization'))
  const [selectedLanguage, setSelectedLanguage] = useState('PT-BR')

  useEffect(() => {
    async function fetchData() {
      try {
        const accountsData = await getAccounts()
        setAccounts(accountsData)

        if (localStorage.getItem('selectedProjectId')) {
          setSelectedProjectId(localStorage.getItem('selectedProjectId'))
        } else if (accountsData?.length) {
          setSelectedProjectId(accountsData[0].id)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [userToken])

  useEffect(() => {
    if (selectedProjectId) {
      localStorage.setItem('selectedProjectId', `${selectedProjectId}`)
    }
    if (selectedAppId) {
      localStorage.setItem('selectedAppId', `${selectedAppId}`)
    }
    if (selectedAppId) {
      localStorage.setItem('selectedAppName', `${selectedAppName}`)
    }
    if (selectedAppVersion) {
      localStorage.setItem('selectedAppVersion', `${selectedAppVersion}`)
    }
  }, [selectedProjectId, selectedAppId, selectedAppName, selectedAppVersion])

  return (
    <GlobalContext.Provider
      value={{
        selectedProjectId,
        setSelectedProjectId,
        selectedAppName,
        selectedAppId,
        setSelectedAppId,
        setSelectedAppName,
        accounts,
        setAccounts,
        userToken,
        setUserToken,
        selectedLanguage,
        setSelectedLanguage,
        selectedAppVersion,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

const useGlobalContext = () => useContext(GlobalContext)

export { useGlobalContext }
