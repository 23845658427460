function extract([beg, end]) {
  const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm')
  const normalise = (str) => str.slice(beg.length, end.length * -1)

  return function (str) {
    let matcherResult = str.match(matcher)
    if (matcherResult) return matcherResult.map(normalise)
  }
}

export const getStringTag = (string: string) => {
  const stringExtractor = extract(['<', '>'])
  let tagsArray = []

  if (string) {
    tagsArray = stringExtractor(string)
  }
  if (tagsArray) {
    return tagsArray
  }

  return []
}

export const getLoopTag = (string: string) => {
  const stringExtractor = extract(['<loop>', '</loop>'])

  let loopString

  if (string) {
    loopString = stringExtractor(string)
  }
  let result = ''
  if (loopString) {
    // eslint-disable-next-line array-callback-return
    loopString.map((string) => {
      console.log(string)
      result = result + string
    })
  }

  const loopTags = getStringTag(result)
  if (loopTags) {
    return loopTags
  }

  return []
}
export default getStringTag
