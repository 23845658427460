import { FormEvent, useContext, useState, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useGlobalContext } from '../contexts/GlobalContext'
import { useHistory } from 'react-router-dom'

import { Link, useLocation } from 'react-router-dom'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isCredentialsInvalid, setIsCredentialsInvalid] = useState(false)

  const { signIn } = useContext(AuthContext)
  const { setUserToken }: any = useGlobalContext()
  const queryParams = new URLSearchParams(useLocation().search)
  const history = useHistory()
  const userToken = localStorage.getItem('Authorization')

  let source = queryParams.get('source')

  useEffect(() => {

    if (userToken) {
      // console.log({ userToken });
      history.push('/dashboard')
    }
  }, [])

  function getImageSrc(source: any) {
    switch (source) {
      case 'voicefiq':
        return './img/voicefiq.png'
      default:
        return './img/verball.png'
    }
  }
  async function handleSubmit(event: FormEvent) {
    event.preventDefault()

    setIsLoading(true)

    const credentials = {
      email,
      password,
    }

    try {
      await signIn(credentials)
      setUserToken(localStorage.getItem('Authorization'))
    } catch (e) {
      console.log(e)

      setIsLoading(false)
      setIsCredentialsInvalid(true)
    }
  }

  return (
    <div id="page-container">
      {!userToken && <main id="main-container">
        <div className="bg-image" style={{ backgroundImage: `url("img/bg.jpg")` }}>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-7 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a className="font-w600 display-4" href="/">
                    <img alt="" src={getImageSrc(source)} height={90} />
                  </a>
                  {source && <h6>Powered By Verball &amp; Corebiz</h6>}
                </div>
                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                    {isCredentialsInvalid && (
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        There was a problem with your login.
                      </div>
                    )}
                    <form
                      className="js-validation-signin"
                      onSubmit={handleSubmit}
                    >
                      <div className="py-3">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="si si-user"></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="email"
                              name="email"
                              placeholder="E-mail"
                              value={email}
                              onChange={(e) =>
                                setEmail(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="si si-lock"></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control form-control-lg"
                              id="password"
                              name="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) =>
                                setPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <Link to={'/forgot-password'}>
                            Forgot password?
                          </Link>
                        </div>
                        <br />
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-block btn-hero-lg btn-hero-primary"
                          >
                            <i className="fa fa-fw fa-sign-in-alt mr-1" />
                            Login
                            {isLoading && (
                              <div>
                                <i className="fa fa-spinner fa-spin"></i>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="form-group">
                      <Link
                        to={
                          source ? '/sign-up?source=voicefiq' : '/sign-up'
                        }
                      >
                        <button className="btn btn-block btn-hero-lg btn-hero-primary">
                          Sign-up
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-static col-md-5 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-3 text-white mb-3">Think voice, not code.</p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright &copy;{' '}
                  <span className="js-year-copy">{new Date().getFullYear()}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>}
    </div>
  )
}
