import { Link } from 'react-router-dom'
import Layout from '../components/Layout'

export default function Templates() {

  return (
    <Layout>
      <div className="p-3 w-100">
        <div className="row no-gutters justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <div className="form-group">
              <Link to={'/create-free-template'} >
                <button className="btn btn-block btn-hero-lg btn-hero-primary">
                  Create New Free Template
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
