import { FormEvent, useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom';
import { useHistory, useLocation} from 'react-router-dom'
import jwtDecode from "jwt-decode";

export default function Login(){
  const history = useHistory();
  const params = useLocation().search;
  const t = new URLSearchParams(params).get('t');
  
  let [email, setEmail] = useState('');
  let [token, setToken] = useState('');
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  let [step, setStep] = useState(1);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isCredentialsInvalid, setIsCredentialsInvalid] = useState(false);
  
  const { sendResetPasswordCode, resetPassword } = useContext(AuthContext);
  
/*   if(params != null){
    console.log('Params', t);
    //todo decode token
    setStep(3);
    setToken(String(t));

  } */

  if(t != null){
    let decodedToken : any = jwtDecode(t);
    token = decodedToken.t.token;
    email = decodedToken.email;
    console.log('Aqui o token', token, email);
    step = 3;
  }

  async function handleSubmitOne(event: FormEvent){
    event.preventDefault();
    
    try{
      
      setIsLoading(true);
      const data  = await sendResetPasswordCode(email);
      console.log('Data', data)
      setStep(2);
      setIsLoading(false);

    } catch(error){

      console.log(error);

      setIsLoading(false);
    
    }
  }

  async function handleSubmitTwo(event: FormEvent){
    event.preventDefault();
    setIsLoading(true);
    
    try{
      
      const data  = await resetPassword(token, password, email);
      console.log(data);
      history.push('/');
    } catch(error){

      console.log(error);

      setIsLoading(false);
      history.push('/error');
    
    }
  }

  return (
        <div id="page-container">
          <main id="main-container">
            <div className="bg-image" style={{ backgroundImage: `url("img/bg.jpg")` }}>
              <div className="row no-gutters bg-primary-op">
                <div className="hero-static col-md-6 d-flex align-items-center bg-white">
                  <div className="p-3 w-100">
                    <div className="mb-3 text-center">
                      <a className="link-fx font-w600 display-4" href="#">
                        <img alt= '' src="https://www.verball.co/wp-content/uploads/2019/02/cropped-verball-beta-4-4.png" height={90}/>
                        {/* <span className="primary-color">verb</span><span className="text-primary">all</span> */}
                      </a>
                    </div>
                    <div className="row no-gutters justify-content-center">
                      <div className="col-sm-8 col-xl-6">
                      { 
                        isCredentialsInvalid && (
                          <div className="alert alert-danger text-center" role="alert">
                            There was a problem with your login.
                          </div>
                        )
                      }
                        {step === 1 && (
                            <form className="js-validation-signin" onSubmit={handleSubmitOne}>
                            <div>Insert your E-mail</div>
                            <div className="py-3">
                            <div className="form-group">
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                    <i className="si si-lock"></i>
                                    </span>
                                </div>
                                    <input 
                                    type="email" 
                                    className="form-control form-control-lg" 
                                    id="email" 
                                    name="email" 
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary">
                                <i className="fa fa-fw fa-sign-in-alt mr-1" />Sent Reset Code
                                { 
                                    isLoading && (
                                    <div>
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                    )
                                } 
                                </button>
                            </div>
                            </div>
                            </form>
                        )}
                        {step === 2 && (
                            <form className="js-validation-signin" onSubmit={handleSubmitTwo}>
                            <div className="py-3">
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="si si-user"></i>
                                            </span>
                                        </div>
                                        <input 
                                        type="text"
                                        className="form-control form-control-lg" 
                                        id="token" 
                                        name="token"
                                        placeholder="Your token here"
                                        value={token}
                                        onChange={e => setToken(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="si si-lock"></i>
                                            </span>
                                        </div>
                                        <input 
                                        type="password"
                                        className="form-control form-control-lg" 
                                        id="password" 
                                        name="password"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="si si-lock"></i>
                                            </span>
                                        </div>
                                        <input 
                                        type="password"
                                        className="form-control form-control-lg" 
                                        id="password" 
                                        name="password"
                                        placeholder="Confirm password"
                                        value={passwordConfirmation}
                                        onChange={e => setPasswordConfirmation(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary">
                                        <i className="fa fa-fw fa-sign-in-alt mr-1" />Reset Password
                                        { 
                                        isLoading && (
                                            <div>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        )
                                        } 
                                    </button>
                                </div>
                            </div>
                            </form>
                        )}
                          {step === 3 && (
                            <form className="js-validation-signin" onSubmit={handleSubmitTwo}>
                            <div className="py-3">
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="si si-lock"></i>
                                            </span>
                                        </div>
                                        <input 
                                        type="password"
                                        className="form-control form-control-lg" 
                                        id="password" 
                                        name="password"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="si si-lock"></i>
                                            </span>
                                        </div>
                                        <input 
                                        type="password"
                                        className="form-control form-control-lg" 
                                        id="password" 
                                        name="password"
                                        placeholder="Confirm password"
                                        value={passwordConfirmation}
                                        onChange={e => setPasswordConfirmation(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary">
                                        <i className="fa fa-fw fa-sign-in-alt mr-1" />Reset Password
                                        { 
                                        isLoading && (
                                            <div>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        )
                                        } 
                                    </button>
                                </div>
                            </div>
                            </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
                  <div className="p-3">
                    <p className="display-3 text-white mb-3">
                      Think voice, not code.
                    </p>
                    <p className="font-size-lg font-w600 text-white-75 mb-0">
                      Copyright &copy; <span className="js-year-copy">{new Date().getFullYear()}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      );
}