import { Typography } from '@material-ui/core'
import { GraphicEq } from '@mui/icons-material'
import { isMobile } from 'react-device-detect'
import './styles.css'
import { getPermissionDescription } from '../../utils/strings/getPermissionDescription'
interface UsersDataProps {
  totalUsers: number
  usersInPeriod: number
  activeUsersInPeriod: number
  permissions: {
    'alexa::profile:name:read'?: number
    'alexa::alerts:reminders:skill:readwrite'?: number
    'alexa::profile:given_name:read'?: number
    'read::alexa:device:all:address:country_and_postal_code'?: number
    'alexa::household:lists:write'?: number
    'alexa::profile:email:read'?: number
    'alexa::persistent_unit_id::read'?: number
    'read::alexa:device:all:address'?: number
    'alexa::household:lists:read'?: number
    'alexa::devices:all:notifications:write'?: number
    'alexa::person_id'?: number
    'alexa::devices:all:geolocation:read'?: number
    'alexa::profile:mobile_number:read'?: number
  }
  userCreationDates: number[]
}

function AppCard({
  text,
  totalUsers,
  totalUsersInPeriod,
  activeUsersInPeriod,
  onClick,
  permissions,
}: any) {
  console.log({ permissions })

  return (
    <div className="app-card" onClick={() => onClick()} style={{ width: isMobile ? '100%' : '' }}>
      <GraphicEq className="app-card-icon" fontSize="large" />
      <div className="app-card-container">
        <Typography variant="h6">{text}</Typography>
      </div>
      <hr />
      <div style={{ alignContent: 'center', justifyContent: 'center', marginLeft: '8px' }}>
        <div style={{ width: '100%' }}>Total Users: {totalUsers || 'loading...'}</div>
        <div style={{ width: '100%' }}>
          Total Users In The Month: {totalUsersInPeriod || 'loading...'}
        </div>
        <div style={{ width: '100%' }}>
          Active Users In The Month: {activeUsersInPeriod || 'loading...'}
        </div>
        <div>
          <strong>Permissions (month):</strong>{' '}
        </div>
      </div>
      <div style={{ justifyContent: 'center', fontSize: '90%', marginBottom: '1rem' }}>
        <div style={{ marginLeft: '1rem' }}>
          {' '}
          {Object.keys(permissions).length === 0 && 'No permissions granted'}
        </div>
        {Object.keys(permissions).map((permission) => (
          <div style={{ marginLeft: '1rem' }}>
            {getPermissionDescription(permission) + ': ' + permissions[permission]}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AppCard
