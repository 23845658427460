import { FormEvent, useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-modal'

//import closeImg from './img/close.svg'
import './styles.css'
import { Container } from './styles'
import Button from '../Button'
import { X, XCircle } from 'phosphor-react'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { CloseButton } from 'react-toastify/dist/components'
interface NewNotificationModalProps {
  isOpen: boolean
  onRequestClose: () => void
}
const { REACT_APP_NOTIFICATIONS_API_URL } = process.env

export function NewNotificationModal({ isOpen, onRequestClose }: NewNotificationModalProps) {
  const { selectedAppId }: any = useGlobalContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [notificationText, setNotificationText] = useState('')
  const [notificationStage, setNotificationStage] = useState('prod')

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsSaving(true)
    setIsLoading(true)

    try {
      const { data }: any = await axios.post(
        `${REACT_APP_NOTIFICATIONS_API_URL}/${selectedAppId}/custom`,
        {
          text: notificationText,
        },
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
          params: {
            stage: notificationStage,
          },
        }
      )

      setIsLoading(false)
      setIsSaving(false)
      onRequestClose()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="modal-overlay"
      className="modal-content"
    >
      <Container>
        <>
          <XCircle
            width={48}
            onClick={onRequestClose}
            style={{ position: 'relative', float: 'right' }}
          />
          <div className="content">
            <div className="block block-rounded">
              <div className="block-header block-header-default">
                <h3 className="block-title">
                  <i className="fa fa-bell text-primary mr-2"></i>
                  Fill in the fields below to create your Notification.
                </h3>
              </div>

              <div>
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">Notification Stage</label>
                    <select
                      onChange={(e) => setNotificationStage(e.target.value)}
                      defaultValue="prod"
                      className="form-control mb-4"
                      id="stage"
                      name="STAGE"
                      value={notificationStage}
                    >
                      <option value="prod">Production</option>
                      <option value="dev">Development</option>
                    </select>
                    <label htmlFor="example-text-input">Notification Text</label>
                    <textarea
                      className="form-control code"
                      required
                      value={notificationText}
                      onChange={(e) => setNotificationText(e.target.value)}
                      rows={3}
                      placeholder="Your notification text"
                      id="text"
                      name="text"
                    ></textarea>
                  </div>
                </form>
                <Button onClick={handleSubmit}>
                  {isSaving || <i className="fa fa-check mr-2" />}
                  {isSaving && <i className="fa fa-spinner fa-spin mr-2"></i>} Save
                </Button>
              </div>
            </div>
          </div>
        </>
      </Container>
    </Modal>
  )
}
