import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout'

//import { useAPI } from '../../services/api'
import { AuthContext } from '../../contexts/AuthContext'
import { Link, useParams } from 'react-router-dom'
//import { useGlobalContext } from '../../contexts/GlobalContext'
import Accordion from '../../components/Accordion'
const { REACT_APP_API_URL } = process.env
interface ParamsProps {
    id: string
}

interface Speak {
    id: string
    language: string
    description: string
    speech: string
}

interface Conditional {
    key: string
    intentId: string
    speak: Speak
    speakId: string
    hasReprompt: boolean
    repromptId: string
    cardId: string
    cardTitle: string
    cardText: string
    vaType: 'AL' | 'GA'
    language: string
}
/*
interface IntentProps {
    id: string
    name: string
    names: ['']
    conditionals: Conditional[]
} */

export default function EditIntent() {
    const [isLoading, setIsLoading] = useState(true)
    const [intentName, setIntentName] = useState('')
    const [conditionals, setConditionals] = useState<Conditional[]>([])

    //const { selectedAppId }: any = useGlobalContext();

    const { user } = useContext(AuthContext)
    const { id } = useParams<ParamsProps>()

    useEffect(() => {
        handleLoadContent()
    }, [])

    async function handleLoadContent() {
        const response = await axios.get(
            `${REACT_APP_API_URL}/apps/${user.currentAppId}/intent/${id}`,
            {
                headers: {
                    authorization: localStorage.getItem('Authorization'),
                },
            }
        )
        console.log('data>', response.data)
        setIntentName(response.data.name)
        setConditionals(response.data.conditionals)
        setIsLoading(false)
        await localStorage.setItem('intentId', id)
        /* api
      .get<IntentProps>(`apps/${user.currentAppId}/intent/${id}`)
      .then((response) => {
        console.log('Response >', response.data);
        setIntentName(response.data.name);
        setConditionals(response.data.conditionals);
        setIsLoading(false);
      }); */
    }

    return (
        <Layout>
            <div className="bg-body-light">
                <div className="content content-full">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                        <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                            Intent: {intentName}
                        </h1>
                        <Link to={{ pathname: '/intent' }} className="btn btn-light">
                            <i className="si si-action-undo"></i>{' '}
                            <span className="d-none d-sm-inline-block">Back</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="content">
                <h2 className="content-heading pt-0">
                    App: <strong>Teste</strong>
                </h2>
                <div className={`block block-themed ${isLoading && 'block-mode-loading'}`}>
                    <div className="block-header bg-primary-dark">
                        <h3 className="block-title">Conditionals</h3>
                        <div className="block-options">
                            <button
                                type="button"
                                className="btn-block-option"
                                data-toggle="block-option"
                                onClick={handleLoadContent}
                                data-action-mode="demo"
                            >
                                <i className="si si-refresh"></i>
                            </button>
                        </div>
                    </div>
                    <div className="block-content">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th className="text-center" style={{ width: 70 }}></th>
                                    <th className="text-center" style={{ width: 70 }}>
                                        Edit
                                    </th>
                                    <th className="text-center" style={{ width: 70 }}>
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {conditionals.map((conditional) => (
                                    <tr key={conditional.key}>
                                        <td className="font-w600">
                                            <div>
                                                <Accordion
                                                    title={conditional.key}
                                                    content={conditional.speak}
                                                ></Accordion>
                                            </div>
                                        </td>
                                        <td className="text-center"></td>
                                        <td className="text-center">
                                            <Link
                                                to={{
                                                    pathname: `/conditional/edit/${conditional.key}`,
                                                }}
                                                className="btn text-primary"
                                                data-toggle="tooltip"
                                                title="Manage Speeches"
                                            >
                                                <i className="fa fa-pencil-alt"></i>
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <a
                                                onClick={() => console.log('')}
                                                className="btn text-primary"
                                                data-toggle="tooltip"
                                                title="Delete Intent"
                                            >
                                                <i className="fa fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-2 text-center" style={{ width: '100%' }}>
                        <Link
                            className="btn btn-hero-primary my-2 text-center"
                            to={{ pathname: `/conditional/add/${id}` }}
                        >
                            <i className="si si-plus mr-1"></i>
                            <span>Add Conditional</span>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
