import { MarkerType } from 'react-flow-renderer';

const userInputs = {
  'user_input_1': {
    'PT-BR': 'Minha última compra',
    'EN-US': 'My last order'
  },
  'user_input_2': {
    'PT-BR': 'Procurar Item',
    'EN-US': 'Search Item'
  },
  'user_input_4': {
    'PT-BR': 'Listar Compras',
    'EN-US': 'List Orders'
  },
  'user_input_5': {
    'PT-BR': 'Sim',
    'EN-US': 'Yes'
  },
  'user_input_6': {
    'PT-BR': 'Não',
    'EN-US': 'No'
  },
  'user_input_7': {
    'PT-BR': 'Meu carrinho',
    'EN-US': 'My cart'
  },
  'user_input_8': {
    'PT-BR': 'Concluir compra',
    'EN-US': 'Checkout'
  },
  'user_input_9': {
    'PT-BR': 'Sim',
    'EN-US': 'Yes'
  },
  'user_input_11': {
    'PT-BR': 'Destaques',
    'EN-US': 'Highlights'
  },
  'user_input_12': {
    'PT-BR': 'Não',
    'EN-US': 'No'
  },
  'user_input_15': {
    'PT-BR': 'Ajuda',
    'EN-US': 'Help'
  },
  'user_input_16': {
    'PT-BR': 'Entrar em contato',
    'EN-US': 'Contact'
  },
  'user_input_17': {
    'PT-BR': 'Sobre a loja',
    'EN-US': 'about the store'
  }
}

const createVtexFlow = (flow: any, flowHandlers: any, selectedLanguage, nodes: any, edges: any) => {
  try {

    //!LaunchRequest
    nodes.push({
      id: 'LaunchRequest',
      data: {
        flowHandlers,
        intentName: 'LaunchRequest',
        intentId: flow['LaunchRequest']?.id,
        selectedLanguage,
        nodeHeight: 380
      },
      position: { x: -300, y: -100 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'LaunchRequest_1',
      data: {
        speak: flow['LaunchRequest']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['LaunchRequest']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'LaunchRequest',
        selectedLanguage,
        conditionalKey: '1',
      },
      position: { x: 5, y: 55 },
      parentNode: 'LaunchRequest',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'LaunchRequest_2',
      data: {
        speak: flow['LaunchRequest']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['LaunchRequest']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'LaunchRequest',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 70
      },
      position: { x: 5, y: 225 },
      parentNode: 'LaunchRequest',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    //! LastOrderIntent

    nodes.push({
      id: 'user_input_1',
      data: { speak: userInputs['user_input_1'][selectedLanguage] },
      position: { x: -35, y: -30 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })


    nodes.push({
      id: 'LastOrderIntent',
      data: {
        flowHandlers,
        intentName: 'LastOrderIntent',
        intentId: flow['LastOrderIntent']?.id,
        selectedLanguage,
        nodeHeight: 500
      },
      position: { x: 250, y: -500 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'LastOrderIntent_1',
      data: {
        speak: flow['LastOrderIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['LastOrderIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'LastOrderIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 65
      },
      position: { x: 5, y: 55 },
      parentNode: 'LastOrderIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'LastOrderIntent_2',
      data: {
        speak: flow['LastOrderIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['LastOrderIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'LastOrderIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 65
      },
      position: { x: 5, y: 190 },
      parentNode: 'LastOrderIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'LastOrderIntent_3',
      data: {
        speak: flow['LastOrderIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['LastOrderIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'LastOrderIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 65
      },
      position: { x: 5, y: 325 },
      parentNode: 'LastOrderIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_1`, target: 'LastOrderIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_1`, target: 'LastOrderIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_1`, target: 'LastOrderIntent_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! ListOrdersIntent

    nodes.push({
      id: 'user_input_4',
      data: { speak: userInputs['user_input_4'][selectedLanguage] },
      position: { x: -35, y: -100 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    nodes.push({
      id: 'ListOrdersIntent',
      data: {
        flowHandlers,
        intentName: 'ListOrdersIntent',
        intentId: flow['ListOrdersIntent']?.id,
        selectedLanguage,
        nodeHeight: 500
      },
      position: { x: 250, y: -1050 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'ListOrdersIntent_1',
      data: {
        speak: flow['ListOrdersIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ListOrdersIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ListOrdersIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 65
      },
      position: { x: 5, y: 55 },
      parentNode: 'ListOrdersIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'ListOrdersIntent_2',
      data: {
        speak: flow['ListOrdersIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['ListOrdersIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'ListOrdersIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 65
      },
      position: { x: 5, y: 190 },
      parentNode: 'ListOrdersIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'ListOrdersIntent_3',
      data: {
        speak: flow['ListOrdersIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['ListOrdersIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'ListOrdersIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 65
      },
      position: { x: 5, y: 325 },
      parentNode: 'ListOrdersIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_4`, target: 'ListOrdersIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_4`, target: 'ListOrdersIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_4`, target: 'ListOrdersIntent_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! SearchItemIntent

    nodes.push({
      id: 'user_input_2',
      data: { speak: userInputs['user_input_2'][selectedLanguage] },
      position: { x: -10, y: 60 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `LaunchRequest_1`, target: 'user_input_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'assistant_output_1',
      // type: 'input',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 30
      },
      position: { x: 120, y: 35 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode',
    })

    edges.push({ source: `user_input_2`, target: 'assistant_output_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `assistant_output_1`, target: 'user_input_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_3',
      // type: 'input',
      data: { speak: '<item>' },
      position: { x: 330, y: 60 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    nodes.push({
      id: 'SearchItemIntent',
      // type: 'input',
      data: { flowHandlers, intentName: 'SearchItemIntent', intentId: flow['SearchItemIntent']?.id, selectedLanguage },
      position: { x: 480, y: -80 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode'
    })

    nodes.push({
      id: 'SearchItemIntent_1',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 50
      },
      position: { x: 5, y: 55 },
      parentNode: 'SearchItemIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'SearchItemIntent_3',
      data: {
        speak: flow['SearchItemIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['SearchItemIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'SearchItemIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 50
      },
      position: { x: 5, y: 175 },
      parentNode: 'SearchItemIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_3`, target: 'SearchItemIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_3`, target: 'SearchItemIntent_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_5',
      data: { speak: userInputs['user_input_5'][selectedLanguage] },
      position: { x: 720, y: 100 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    nodes.push({
      id: 'user_input_6',
      data: { speak: userInputs['user_input_6'][selectedLanguage] },
      position: { x: 720, y: 160 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `SearchItemIntent_3`, target: 'user_input_5', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `SearchItemIntent_3`, target: 'user_input_6', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_6`, target: 'NoIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    // nodes.push({
    //   id: 'YesIntent',
    //   // type: 'input',
    //   data: { flowHandlers, intentName: 'YesIntent', intentId: flow['YesIntent']?.id, selectedLanguage },
    //   position: { x: 880, y: -80 },
    //   sourcePosition: 'right',
    //   targetPosition: 'left',
    //   type: 'IntentNode'
    // })

    nodes.push({
      id: 'assistant_output_6',
      data: {
        speak: flow['YesIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['YesIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'YesIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 50
      },
      position: { x: 880, y: 50 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_5`, target: 'assistant_output_6', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_13',
      data: { speak: '<number>' },
      position: { x: 1115, y: 80 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `assistant_output_6`, target: 'user_input_13', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'assistant_output_7',
      data: {
        speak: flow['ShoppingCartAddItemIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ShoppingCartAddItemIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartAddItemIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 50
      },
      position: { x: 1250, y: 50 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_13`, target: 'assistant_output_7', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! ShoppingCartIntent

    nodes.push({
      id: 'user_input_7',
      data: { speak: userInputs['user_input_7'][selectedLanguage] },
      position: { x: -10, y: 340 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_7', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'ShoppingCartIntent',
      data: {
        flowHandlers,
        intentName: 'ShoppingCartIntent',
        intentId: flow['ShoppingCartIntent']?.id,
        selectedLanguage,
        nodeHeight: 300
      },
      position: { x: 140, y: 180 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'ShoppingCartIntent_1',
      data: {
        speak: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 65
      },
      position: { x: 5, y: 55 },
      parentNode: 'ShoppingCartIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'ShoppingCartIntent_2',
      data: {
        speak: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['ShoppingCartIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'ShoppingCartIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 25
      },
      position: { x: 5, y: 190 },
      parentNode: 'ShoppingCartIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_7`, target: 'ShoppingCartIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_7`, target: 'ShoppingCartIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! CompleteOrderIntent

    nodes.push({
      id: 'user_input_8',
      data: { speak: userInputs['user_input_8'][selectedLanguage] },
      position: { x: -10, y: 600 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_8', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_8`, target: 'CompleteOrderIntent_6', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_8`, target: 'CompleteOrderIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'CompleteOrderIntent',
      data: {
        flowHandlers,
        intentName: 'CompleteOrderIntent',
        intentId: flow['CompleteOrderIntent']?.id,
        selectedLanguage,
        nodeHeight: 330
      },
      position: { x: 140, y: 530 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'CompleteOrderIntent_6',
      data: {
        speak: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['6']?.phrase,
        speakId: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['6']?.phraseId,
        flowHandlers,
        intentName: 'CompleteOrderIntent',
        selectedLanguage,
        conditionalKey: '6',
        nodeHeight: 45
      },
      position: { x: 5, y: 55 },
      parentNode: 'CompleteOrderIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'user_input_9',
      data: { speak: userInputs['user_input_9'][selectedLanguage] },
      position: { x: 420, y: 610 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    nodes.push({
      id: 'user_input_12',
      data: { speak: userInputs['user_input_12'][selectedLanguage] },
      position: { x: 420, y: 550 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `CompleteOrderIntent_6`, target: 'user_input_9', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_12`, target: 'NoIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `CompleteOrderIntent_6`, target: 'user_input_12', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'CompleteOrderIntent_7',
      data: {
        speak: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'CompleteOrderIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 45
      },
      position: { x: 550, y: 580 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_9`, target: 'CompleteOrderIntent_7', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'user_input_10',
      data: { speak: '<PaymentMethod>' },
      position: { x: 780, y: 610 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `CompleteOrderIntent_7`, target: 'user_input_10', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'CompleteOrderIntent_4',
      data: {
        speak: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['4']?.phrase,
        speakId: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['4']?.phraseId,
        flowHandlers,
        intentName: 'CompleteOrderIntent',
        selectedLanguage,
        conditionalKey: '4',
        nodeHeight: 45
      },
      position: { x: 960, y: 640 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_10`, target: 'CompleteOrderIntent_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'CompleteOrderIntent_1',
      data: {
        speak: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'CompleteOrderIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 45
      },
      position: { x: 960, y: 500 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_10`, target: 'CompleteOrderIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'CompleteOrderIntent_2',
      data: {
        speak: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['CompleteOrderIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'CompleteOrderIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 65
      },
      position: { x: 5, y: 170 },
      parentNode: 'CompleteOrderIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    //! HighlightsIntent

    nodes.push({
      id: 'user_input_11',
      data: { speak: userInputs['user_input_11'][selectedLanguage] },
      position: { x: -10, y: 1000 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_11', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'HighlightsIntent',
      data: {
        flowHandlers,
        intentName: 'HighlightsIntent',
        intentId: flow['HighlightsIntent']?.id,
        selectedLanguage,
        nodeHeight: 420
      },
      position: { x: 140, y: 900 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'HighlightsIntent_1',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 45
      },
      position: { x: 5, y: 55 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'HighlightsIntent_2',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 45
      },
      position: { x: 5, y: 170 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'HighlightsIntent_3',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['3']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['3']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '3',
        nodeHeight: 55
      },
      position: { x: 5, y: 285 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_11`, target: 'HighlightsIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_11`, target: 'HighlightsIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_11`, target: 'HighlightsIntent_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! MenuIntent

    nodes.push({
      id: 'user_input_14',
      data: { speak: 'Menu' },
      position: { x: -10, y: 1400 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_14', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'MenuIntent',
      data: {
        flowHandlers,
        intentName: 'MenuIntent',
        intentId: flow['MenuIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 1350 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'MenuIntent_1',
      data: {
        speak: flow['MenuIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['MenuIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'MenuIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'MenuIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_14`, target: 'MenuIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! HelpIntent

    nodes.push({
      id: 'user_input_15',
      data: { speak: userInputs['user_input_15'][selectedLanguage] },
      position: { x: -10, y: 1480 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_15', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_15`, target: 'MenuIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! ContactIntent

    nodes.push({
      id: 'user_input_16',
      data: { speak: userInputs['user_input_16'][selectedLanguage] },
      position: { x: -60, y: 1650 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_16', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'ContactIntent',
      data: {
        flowHandlers,
        intentName: 'ContactIntent',
        intentId: flow['ContactIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 1600 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'ContactIntent_1',
      data: {
        speak: flow['ContactIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ContactIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ContactIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'ContactIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_16`, target: 'ContactIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! AboutIntent

    nodes.push({
      id: 'user_input_17',
      data: { speak: userInputs['user_input_17'][selectedLanguage] },
      position: { x: -60, y: 1900 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_17', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'AboutIntent',
      data: {
        flowHandlers,
        intentName: 'AboutIntent',
        intentId: flow['AboutIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 1850 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'AboutIntent_1',
      data: {
        speak: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'AboutIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'AboutIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_17`, target: 'AboutIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! NoIntent

    nodes.push({
      id: 'NoIntent',
      data: {
        flowHandlers,
        intentName: 'NoIntent',
        intentId: flow['NoIntent']?.id,
        selectedLanguage,
        nodeHeight: 180
      },
      position: { x: 900, y: 250 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'NoIntent_1',
      data: {
        speak: flow['NoIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['NoIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'NoIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 45
      },
      position: { x: 5, y: 55 },
      parentNode: 'NoIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })


    return { nodes, edges }
  } catch (error) {
    return {}
  }
}

export default createVtexFlow