import { FormEvent, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const { REACT_APP_TEMPLATES_API } = process.env

export default function CreateAccount() {
    const history = useHistory()
    const [skillId, setSkillId] = useState('')
    const [actionId, setActionId] = useState('')
    const [magentoKey, setMagentoKey] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        setIsLoading(true)
        const data = {
            skillId,
            actionId,
            magentoKey,
            appId: localStorage.getItem('selectedAppId'),
        }
        try {
            await axios.post(`${REACT_APP_TEMPLATES_API}/magento/info`, data, {
                headers: {
                    authorization: localStorage.getItem('Authorization'),
                },
            })
            history.push('/apps')
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    return (
        <>
            <div className="p-3 w-100">
                <div className="row no-gutters justify-content-center">
                    <div className="col-sm-8 col-xl-6">
                        <form className="js-validation-signin" onSubmit={handleSubmit}>
                            <div className="py-3">
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-align-justify"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="skillId"
                                            name="skillId"
                                            placeholder="Skill Id"
                                            value={skillId}
                                            onChange={(e) => setSkillId(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-align-justify"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="actionId"
                                            name="actionId"
                                            placeholder="Action Id"
                                            value={actionId}
                                            onChange={(e) => setActionId(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-align-justify"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="magentoKey"
                                            name="magentoKey"
                                            placeholder="Magento Key"
                                            value={magentoKey}
                                            onChange={(e) => setMagentoKey(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-hero-lg btn-hero-primary"
                                    >
                                        Send info
                                        {isLoading && (
                                            <div>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
