import React, { useRef } from 'react';

import { Container } from './styles'

interface ButtonProps {
  children?: React.ReactNode;
  color?: string;
  size?: string,
  disabled?: boolean;
  onClick?: (e: any) => void;
}


const Button: React.FC<ButtonProps> = ({ children, color, size, disabled, ...rest }) => {

  const ref = useRef(null);

  const onClick = (e: any) => {
    if (disabled) return;
    rest.onClick && rest.onClick(e);
  };

  return (
    <Container
      {...rest}
      ref={ref}
      disabled={disabled}
      onClick={onClick}>
      {
        <> {children} </>
      }
    </Container>
  );
}

export default Button;