import axios from 'axios'
import { FormEvent, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAPI } from '../../services/api'
import { AuthContext } from '../../contexts/AuthContext'
import { Link, useParams } from 'react-router-dom'

import Layout from '../../components/Layout'
import { toast } from 'react-toastify'
const { REACT_APP_API_URL, REACT_APP_APPS_API } = process.env

interface ParamsProps {
  id: string
}

interface CardProps {
  id: string
  text: string
}

interface SpeechProps {
  id: string
  speech: string
}

export default function ConditionalAdd() {
  const history = useHistory()
  const { id } = useParams<ParamsProps>()
  const [isLiteral, setIsLiteral] = useState(true)

  const [key, setKey] = useState(id)
  const [language, setLanguage] = useState('PT-BR')
  const [vaType, setVaType] = useState('AL')
  const [hasReprompt, setHasReprompt] = useState(false)
  const [speak, setSpeak] = useState('')
  const [cardTitle, setCardTitle] = useState<string | null>(null)
  const [cardText, setCardText] = useState<string | null>(null)

  const [speeches, setSpeeches] = useState<SpeechProps[]>([])
  const [cards, setCards] = useState<CardProps[]>([])

  const [speakId, setSpeakId] = useState('')
  const [cardId, setCardId] = useState<string | null>(null)
  const [repromptId, setRepromptId] = useState<string | null>(null)
  let intentId: any = localStorage.getItem('intentId')
  const { user } = useContext(AuthContext)

  const api = useAPI('http://localhost:3334')

  async function handleLoadContent() {
    const response = await axios.get(
      `${REACT_APP_API_URL}/apps/${user.currentAppId}/intent/${id}`,
      {
        headers: {
          authorization: localStorage.getItem('Authorization'),
        },
      }
    )
    console.log('data>', response.data)
    /*         setIntentName(response.data.name)
        setConditionals(response.data.conditionals)
        setIsLoading(false) */
  }

  useEffect(() => {
    handleLoadContent()
  }, [])
  async function handleSubmit(e: FormEvent) {
    console.log('intentId', intentId)

    e.preventDefault()
    console.log('Requisition')

    try {
      await axios.put(`${REACT_APP_APPS_API}/${intentId}/conditional/edit/${id}`, {
        newCardTitle: cardTitle,
        newCardText: cardText,
        newPhrase: speak,
      })

      //resetConditional()
      toast.success('Conditional saved successfully!')
      history.push(`/intent/edit/${intentId}`)
    } catch (err: any) {
      console.log(err.message.toString())
      toast.error(`An error ocurred: ${err.response}`)
    }
  }

  useEffect(() => {
    if (!isLiteral) {
      try {
        api.get<SpeechProps[]>(`apps/${user.currentAppId}/speech`).then((response) => {
          setSpeeches(response.data)
          setSpeakId(response.data[0].id)
        })

        api.get<CardProps[]>(`apps/${user.currentAppId}/card`).then((response) => {
          setCards(response.data)
        })
      } catch (err: any) {
        toast.error(`An error ocurred: ${err.response.data.message}`)
      }
    }
  }, [isLiteral])

  function resetConditional() {
    setKey('')
    setSpeak('')
    setCardTitle(null)
    setCardText(null)
  }

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Add New Conditional
            </h1>
            <Link to={{ pathname: `/intent/edit/${id}` }} className="btn btn-light">
              <i className="si si-action-undo"></i>{' '}
              <span className="d-none d-sm-inline-block">Back</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h3 className="block-title">
              Fill in the fields below to create a new conditional.
              <i className="fa fa-info-circle text-primary"></i>
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <div className="custom-control custom-switch mb-3 mt-1">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="example-sw-custom1"
                    name="example-sw-custom1"
                    onChange={(e) => setIsLiteral(e.target.checked)}
                    checked={isLiteral}
                  />
                  <label
                    className="custom-control-label"
                    style={{ float: 'right' }}
                    htmlFor="example-sw-custom1"
                  >
                    New
                  </label>
                </div>

                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="form-group form-row">
                    <div className="col-4">
                      <label htmlFor="example-text-input">Key</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        id="key"
                        name="key"
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="example-text-input">Language</label>
                      <select
                        className="form-control"
                        onChange={(e) => setLanguage(e.target.value)}
                        id="idLanguage"
                        name="idLanguage"
                      >
                        <option value="PT-BR">Portuguese BR</option>
                        <option value="EN-US">English US</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="example-text-input">Voice Assistant</label>
                      <select
                        onChange={(e) => setVaType(e.target.value)}
                        className="form-control"
                        id="idLanguage"
                        name="idLanguage"
                      >
                        <option value="AL">Amazon Alexa</option>
                        <option value="GA">Google Assitant</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    {isLiteral && (
                      <div className="custom-control custom-checkbox" style={{ float: 'right' }}>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="example-cb-custom1"
                          name="example-cb-custom1"
                          onChange={(e) => setHasReprompt(e.target.checked)}
                          checked={hasReprompt}
                        />
                        <label className="custom-control-label" htmlFor="example-cb-custom1">
                          Reprompt
                        </label>
                      </div>
                    )}

                    <label htmlFor="example-text-input">Speak</label>
                    {isLiteral ? (
                      <textarea
                        className="form-control code"
                        required
                        value={speak}
                        onChange={(e) => setSpeak(e.target.value)}
                        rows={5}
                        placeholder="..."
                        id="speak"
                        name="speak"
                      ></textarea>
                    ) : (
                      <select
                        onChange={(e) => setSpeakId(e.target.value)}
                        className="form-control"
                        id="speakId"
                        name="speakId"
                      >
                        {speeches.map((speech) => (
                          <option title={speech.speech} value={speech.id}>
                            {speech.speech}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  {isLiteral ? (
                    <>
                      <div className="form-group">
                        <label htmlFor="example-text-input">Card Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={cardTitle || 'card'}
                          onChange={(e) => setCardTitle(e.target.value)}
                          id="name"
                          name="name"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="example-text-input">Card Text</label>
                        <textarea
                          className="form-control code"
                          value={cardText || ''}
                          onChange={(e) => setCardText(e.target.value)}
                          rows={5}
                          placeholder="..."
                          id="speak"
                          name="speak"
                        ></textarea>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <label htmlFor="example-text-input">Reprompt</label>
                        <select
                          onChange={(e) => setRepromptId(e.target.value || null)}
                          className="form-control"
                          id="repromptId"
                          name="repromptId"
                        >
                          <option value={''}>--</option>
                          {speeches.map((speech) => (
                            <option title={speech.speech} value={speech.id}>
                              {speech.speech}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="example-text-input">Card</label>
                        <select
                          onChange={(e) => setCardId(e.target.value || null)}
                          className="form-control"
                          id="cardId"
                          name="cardId"
                        >
                          <option value={''}>--</option>
                          {cards.map((card) => (
                            <option title={card.text} value={card.id}>
                              {card.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <button
                      onSubmit={handleSubmit}
                      type="submit"
                      className="btn btn-hero-info mt-2 mb-2"
                    >
                      Add Conditional
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
