import Layout from '../../components/Layout'
import { useEffect, useState } from 'react'

import axios from 'axios'
import Flow from '../../components/Flow'

import Loading from '../../components/Loading'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { useAPI } from '../../services/api'

import { At, Chat, FlowArrow, Notification } from 'phosphor-react'
import { PropertyModal } from '../../components/PropertyModal'

const { REACT_APP_FLOW_API, REACT_APP_APPS_API } = process.env

interface Intent {
  id: string
  name: string
  conditionals: Conditional[]
}

interface Conditional {
  key: string
  va_type: string
  language: string
  redirect_to: string
  end_session: boolean
  speak?: Speak
  reprompt?: Reprompt
  card?: Card
}

interface Speak {
  id: string
  description: string
  language: string
  phrases_speeches: PhrasesSpeeches[]
}

interface PhrasesSpeeches {
  index: number
  phrase: Phrase
}

interface Phrase {
  value: string
  id: string
  variations: string[]
}
interface Reprompt {
  id: string
  description: string
  language: string
  phrases_speeches: PhrasesSpeeches
}

interface Card {
  speechIdTitle: string
  speechIdText: string
  language: string
  imageUrl: string
  text: string
}

export default function Conversational() {
  const [intents, setIntents] = useState<Intent[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isDataSetted, setIsDataSetted] = useState(false)
  const [flow, setFlow] = useState(null)
  const [appType, setAppType] = useState(null)
  const [defaultLanguage, setDefaultLanguage] = useState(null)
  const [isMoreInformationsModalOpen, setIsMoreInformationsModalOpen] = useState(false)
  const [isWelcomeMessageModalOpen, setIsWelcomeMessageModalOpen] = useState(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const api = useAPI()

  function openWelcomeMessageModal() {
    setIsWelcomeMessageModalOpen(true)
  }

  function closeWelcomeMessageModal() {
    setIsWelcomeMessageModalOpen(false)
  }

  function openMoreInformationsModal() {
    setIsMoreInformationsModalOpen(true)
  }

  function closeMoreInformationModal() {
    setIsMoreInformationsModalOpen(false)
  }

  function openContactModal() {
    setIsContactModalOpen(true)
  }

  function closeContactModal() {
    setIsContactModalOpen(false)
  }

  const { selectedAppId }: any = useGlobalContext()

  async function handleSumbitFlow() {
    try {
      if (!isLoading) setIsLoading(true)

      console.log(
        "🚀 ~ file: Conversational.tsx ~ line 109 ~ handleSumbitFlow ~ localStorage.getItem('Authorization')",
        localStorage.getItem('Authorization')
      )
      const { data } = await axios.post(
        `${REACT_APP_FLOW_API}/${selectedAppId}`,
        {},
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )
      setIsLoading(false)
    } catch (error) {
      console.log({ error })
    }
  }

  async function handleLoadContent() {
    try {
      if (!isLoading) setIsLoading(true)

      const { data } = await axios.get(
        `${REACT_APP_APPS_API}/apps/${selectedAppId}/all-attributes`,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      //const genericFlow = await createGenericFlow(data)

      const { data: properties } = await axios.get(
        `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      const appType = properties.filter((property) => {
        return property.name === 'TYPE'
      })[0]?.value

      const defaultLanguage = properties.filter((property) => {
        return property.name === 'DEFAULT_LANGUAGE'
      })[0]?.value

      setDefaultLanguage(defaultLanguage)
      setAppType(appType)
      setFlow(data)

      setIntents(data)
      setIsDataSetted(true)
      setIsLoading(false)
      /*         api.get<any[]>(`apps/${selectedAppId}/intent/all-attributes`)
    .then((response) => {
    }); */
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleLoadContent()
  }, [isDataSetted])

  return (
    <Layout>
      <PropertyModal
        modalType="more-informations"
        isOpen={isMoreInformationsModalOpen}
        onRequestClose={closeMoreInformationModal}
      />
      <PropertyModal
        modalType="contact"
        isOpen={isContactModalOpen}
        onRequestClose={closeContactModal}
      />
      <PropertyModal
        modalType="welcome-message"
        isOpen={isWelcomeMessageModalOpen}
        onRequestClose={closeWelcomeMessageModal}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="bg-body-light">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="si si-bubbles" style={{ color: '#0665d0' }}></i> Intents & Speeches
              </h1>

              <select
                name="select"
                className="btn btn-hero-primary my-2 mx-2"
                defaultValue={defaultLanguage}
                onChange={(e) => setDefaultLanguage(e.target.value)}
              >
                <option value="PT-BR">PT-BR</option>
                <option value="EN-US">EN-US</option>
              </select>

              {/* <Link className="btn btn-hero-primary my-2" to={{ pathname: '/speech' }} style={{ margin: '10px' }}>
              <i className="fa fa-microphone-alt"></i> SPEECH
            </Link> */}
              <button onClick={() => handleSumbitFlow()} className="btn btn-hero-primary my-2 mx-2">
                <FlowArrow height={16} /> Submit FLow
              </button>
              <button
                onClick={() => openMoreInformationsModal()}
                className="btn btn-hero-primary my-2 mx-2"
              >
                <At /> App more informations
              </button>
              <button onClick={() => openContactModal()} className="btn btn-hero-primary my-2 mx-2">
                <Chat height={16} /> App Contact
              </button>
              <button
                onClick={() => openWelcomeMessageModal()}
                className="btn btn-hero-primary my-2 mx-2"
              >
                <Notification height={16} /> Welcome Message
              </button>
            </div>
          </div>
        </div>
      )}
      <Flow
        flowHandlers={{ flow, setFlow }}
        intents={intents}
        setIntents={setIntents}
        appType={appType}
        selectedLanguage={defaultLanguage}
      />
    </Layout>
  )
}
