export const appStatuses: any = {
  preDistributed: {
    value: 'preDistributed',
    description: 'Pre Distribution',
    index: 0,
  },
  test: { value: 'test', description: 'Tests', index: 1 },
  certification: { value: 'certification', description: 'Certification', index: 2 },
  distributed: { value: 'distributed', description: 'Live', index: 3 },
}

export const appStatusesArray: any = [
  { value: 'preDistributed', description: 'Pre Distribution', index: 0 },
  { value: 'test', description: 'Tests', index: 1 },
  { value: 'certification', description: 'Certification', index: 2 },
  { value: 'distributed', description: 'Live', index: 3 },
]

export const getAppStatus = (status: any) => {
  return appStatuses[status]
}
