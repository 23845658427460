import { FormEvent, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useGlobalContext } from '../../contexts/GlobalContext'
import Layout from '../../components/Layout'

const { REACT_APP_FEED_API } = process.env

export default function NewHighlights() {
  const history = useHistory()
  const [highlightText, setHighlightText] = useState('')
  const [highlightEmailText, setHighlightEmailText] = useState('')
  const [highlightImageUrl, setHighlightImageUrl] = useState('')
  const [highlightedtemId, setHighlightedItemId] = useState('')
  const [highlightType, setHighlightType] = useState('DEFAULT')
  const [isLoading, setIsLoading] = useState(false)
  const { selectedAppId }: any = useGlobalContext()
  console.log(REACT_APP_FEED_API)

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsLoading(true)

    try {
      const { data }: any = await axios.post(
        `${REACT_APP_FEED_API}/feedItems/${selectedAppId}`,
        {
          text: highlightText,
          emailText: highlightEmailText,
          imageUrl: highlightImageUrl,
          itemId: highlightedtemId,
          type: highlightType,
        },
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      history.push(`/${selectedAppId}/highlights`)
      // navigate('/templates');
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <Layout>
      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h3 className="block-title">
              <i className="fa fa-star text-primary"></i>
              Fill in the fields below to create your highlight.
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="justify-content-end">
                    <label htmlFor="example-text-input">Highlight Type</label>
                    <select
                      onChange={(e) => setHighlightType(e.target.value)}
                      className="form-control"
                      id="highlightType"
                      name="highlightType"
                      value={highlightType}
                    >
                      <option value="DEFAULT">Simple Highlight</option>
                      <option value="OFFER">Product Highlight</option>
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">Highlight Text</label>

                    <textarea
                      className="form-control code"
                      required
                      value={highlightText}
                      onChange={(e) => setHighlightText(e.target.value)}
                      rows={3}
                      placeholder="Your highlight text"
                      id="text"
                      name="text"
                    ></textarea>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">Highlight E-mail Text</label>

                    <textarea
                      className="form-control code"
                      required
                      value={highlightEmailText}
                      onChange={(e) => setHighlightEmailText(e.target.value)}
                      rows={3}
                      placeholder="Your highlight E-mail text"
                      id="emailText"
                      name="emailText"
                    ></textarea>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">Highlight image Url</label>

                    <textarea
                      className="form-control code"
                      value={highlightImageUrl}
                      onChange={(e) => setHighlightImageUrl(e.target.value)}
                      rows={3}
                      placeholder="Your highlight Image Url"
                      id="emailText"
                      name="emailText"
                    ></textarea>
                  </div>

                  {highlightType === 'OFFER' && (
                    <div className="form-group mt-3">
                      <label htmlFor="example-text-input">Highlighted Item ID</label>

                      <textarea
                        className="form-control code"
                        required
                        value={highlightedtemId}
                        onChange={(e) => setHighlightedItemId(e.target.value)}
                        rows={1}
                        placeholder="Please, place your product ID here"
                        id="productId"
                        name="productId"
                      ></textarea>
                    </div>
                  )}

                  <div className="form-group">
                    <button
                      onSubmit={handleSubmit}
                      type="submit"
                      className="btn btn-hero-info mt-2 mb-2"
                    >
                      Create Highlight
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
