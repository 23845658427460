import axios from 'axios'
import { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import Layout from '../../components/Layout'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../../contexts/GlobalContext'
const { REACT_APP_FEED_API } = process.env

interface ParamsProps {
  faqId: string
}

interface FaqEditProps {
  id: string
  text: string
  title?: string
}

export default function EditFAQ() {
  const history = useHistory()
  const { selectedAppId, selectedAppName }: any = useGlobalContext()
  const [faqText, setFaqText] = useState('')
  const [faqTitle, setFaqTitle] = useState('')
  const [faqType, setFaqType] = useState('')

  const [isLoading, setIsLoading] = useState(true)

  const { faqId } = useParams<ParamsProps>()

  async function handleLoadContent() {
    setIsLoading(true)
    const response = await axios.get(`${REACT_APP_FEED_API}/feedItems/${selectedAppId}/${faqId}`, {
      headers: {
        authorization: localStorage.getItem('Authorization'),
      },
    })
    setFaqTitle(response.data.title)
    setFaqText(response.data.text)
    setFaqType(response.data.type)

    setIsLoading(false)
  }

  useEffect(() => {
    handleLoadContent()
  }, [])

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    try {
      await axios.put(
        `${REACT_APP_FEED_API}/feedItems/${selectedAppId}/${faqId}`,
        {
          text: faqText,
          title: faqTitle,
          type: faqType,
        },
        { headers: { authorization: localStorage.getItem('Authorization') } }
      )

      toast.success('FAQ saved successfully!')
      history.push(`/${selectedAppId}/faqs`)
    } catch (err: any) {
      console.log(err.message.toString())
      toast.error(`An error ocurred: ${err.response}`)
    }
  }

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">Update FAQ</h1>
            {/*                         <Link to={{ pathname: `/intent/edit/${id}` }} className="btn btn-light">
                            <i className="si si-action-undo"></i>{' '}
                            <span className="d-none d-sm-inline-block">Back</span>
                        </Link> */}
          </div>
        </div>
      </div>

      <div className="content">
        <div className={`block block-rounded ${isLoading && 'block-mode-loading'}`}>
          <div className="block-header block-header-default">
            <h3 className="block-title">
              <i className="fa fa-info-circle text-primary mr-2"></i>
              Fill in the fields below to update your FAQ.
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">FAQ Text</label>

                    <textarea
                      className="form-control code"
                      required
                      value={faqTitle}
                      onChange={(e) => setFaqTitle(e.target.value)}
                      rows={3}
                      placeholder="Your FAQ Title"
                      id="text"
                      name="text"
                    ></textarea>
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="example-text-input">FAQ Text</label>

                    <textarea
                      className="form-control code"
                      required
                      value={faqText}
                      onChange={(e) => setFaqText(e.target.value)}
                      rows={3}
                      placeholder="Your FAQ text"
                      id="text"
                      name="text"
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <button
                      onSubmit={handleSubmit}
                      type="submit"
                      className="btn btn-hero-info mt-2 mb-2"
                    >
                      Update FAQ
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
