import Layout from '../../components/Layout'
import axios from 'axios'

import { Sankey, Tooltip } from 'recharts'

import { useEffect, useState } from 'react'
import { useGlobalContext } from '../../contexts/GlobalContext'
import * as moment from 'moment'
import { ArrowArcRight, ArrowCircleDownRight, ArrowRight } from 'phosphor-react'
import { TreeItem, TreeView } from '@material-ui/lab'
import { ExpandMore } from '@material-ui/icons'

interface LogData {
  app?: string
  deviceId?: string
  did?: string
  dlid?: string
  sid?: string
  ts?: string
  uid?: string
  userId?: string
  va?: string
  vars?: string
}

const { REACT_APP_ANALYTICS_API } = process.env

export default function AppLog() {
  const [logData, setLogData] = useState<any>()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const { selectedAppId }: any = useGlobalContext()

  async function handleLoadContent() {
    if (!isLoading) setIsLoading(true)

    const { data } = await axios.get<any>(`${REACT_APP_ANALYTICS_API}/${selectedAppId}/log`)

    console.log('Log Data', data)

    setLogData(data)

    setIsLoading(false)
  }

  useEffect(() => {
    handleLoadContent()
  }, [])

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              Activities Log
            </h1>
            {/*             <Link className="btn btn-hero-primary my-2" to={{ pathname: '/analytics/path' }}>
              Per session
            </Link> */}
          </div>
        </div>
      </div>

      <div className="content">
        <div className={`block block-themed ${isLoading && 'block-mode-loading'}`}>
          <div className="block-header bg-primary-dark">
            <h3 className="block-title">Logs</h3>
          </div>
          {!isLoading && (
            <div>
              {logData.map((data) => {
                return (
                  <TreeView
                    className="m-4"
                    defaultExpanded={['1']}
                    style={{ flexGrow: 1 }}
                    defaultExpandIcon={<ArrowRight />}
                    defaultCollapseIcon={<ExpandMore />}
                  >
                    <TreeItem nodeId={data.ts} label={`${new Date(Number(data.ts))}`}>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`Timestamp: ${data.ts}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`App: ${data.app}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`Session Id: ${data.sid}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2" style={{ wordWrap: 'break-word' }}>
                        {`Did: ${data.did}`}
                      </div>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`Device Id: ${data.deviceId}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`DlId: ${data.dlid}`}</div>
                      <div
                        className="mr-4 ml-4 mt-2 mb-2"
                        style={{ wordWrap: 'break-word' }}
                      >{`uId: ${data.uid}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`User Id: ${data.userId}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2"> {`va: ${data.va}`}</div>
                      <div className="mr-4 ml-4 mt-2 mb-2">{`Data: ${data.vars}`}</div>
                    </TreeItem>
                  </TreeView>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
