import { SayButton } from 'react-say';
import './styles.css';

function Button({ speak }: any) {
  return <div className='say-button'>
    <SayButton
      // onClick={ event => console.log(event) }
      speak={speak}
    >
      🔊
    </SayButton>
  </div>
}

export default Button
