import { FormEvent, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useGlobalContext } from '../../contexts/GlobalContext'
import Layout from '../../components/Layout'

const { REACT_APP_NOTIFICATIONS_API_URL } = process.env

export default function NewNotification() {
  const history = useHistory()
  const [notificationText, setNotificationText] = useState('')
  const [notificationStage, setNotificationStage] = useState('prod')
  const [isLoading, setIsLoading] = useState(false)
  const { selectedAppId }: any = useGlobalContext()
  console.log(REACT_APP_NOTIFICATIONS_API_URL)

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsLoading(true)

    try {
      const { data }: any = await axios.post(
        `${REACT_APP_NOTIFICATIONS_API_URL}/${selectedAppId}/custom`,
        {
          text: notificationText,
        },
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
          params: {
            stage: notificationStage,
          },
        }
      )

      history.push(`/${selectedAppId}/notifications`)
      // navigate('/templates');
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <Layout>
      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h3 className="block-title">
              <i className="fa fa-bell text-primary mr-2"></i>
              Fill in the fields below to create your Notification.
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="form-group mt-3">
                    <label htmlFor="stage">Notification Stage</label>
                    <select
                      onChange={(e) => setNotificationStage(e.target.value)}
                      defaultValue="prod"
                      className="form-control mb-4"
                      id="stage"
                      name="STAGE"
                      value={notificationStage}
                    >
                      <option value="prod">Production</option>
                      <option value="dev">Development</option>
                    </select>
                    <label htmlFor="example-text-input">Notification Text</label>
                    <textarea
                      className="form-control code"
                      required
                      value={notificationText}
                      onChange={(e) => setNotificationText(e.target.value)}
                      rows={3}
                      placeholder="Your notification text"
                      id="text"
                      name="text"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <button
                      onSubmit={handleSubmit}
                      type="submit"
                      className="btn btn-hero-info mt-2 mb-2"
                    >
                      Create Notifications
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
