import { useEffect, useRef, useState } from 'react'

import { RefObject } from 'react'

import { AccordionContainer, AccordionItemContainer } from './styles'

interface Speak {
  id: string
  language: string
  description: string
  speech: string
}

function getRefValue<C>(ref: RefObject<C>) {
  return ref.current as C
}

const AccordionItem = ({ title, content }: { title: string; content?: Speak }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const contentEl = getRefValue(contentRef)

      setHeight(contentEl.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [isOpen])

  return (
    <AccordionItemContainer>
      <h2 className="accordion-item-title">
        <button className="accordion-item-btn" onClick={() => setIsOpen(!isOpen)}>
          {title}
        </button>
      </h2>
      <div className="accordion-item-container" style={{ height }}>
        <div ref={contentRef} className="accordion-item-content">
          <ul>
            <li>Descrição: {content?.description || ''}</li>
            <li>Speech: {content?.speech || ''}</li>
          </ul>
        </div>
      </div>
    </AccordionItemContainer>
  )
}

const Accordion = ({ title, content }: { title: string; content: Speak }) => {
  /*     const [currentIndex, setCurrentIndex] = useState(-1)
    // const [accordionData, setAccordionData] = useState<Array<AccordionData>>([]);

    const onClick = (index: number) => {
        setCurrentIndex((currentValue) => (currentValue !== index ? index : -1))
    } */

  return (
    <ul>
      <AccordionContainer>
        <AccordionItem
          // key={idx}
          title={title}
          content={content}
          // isOpen={idx === currentIndex}
          // onClick={() => onClick(idx)}
        />
        {/* {accordionData.map((item, idx) => (
            ))} */}
      </AccordionContainer>
    </ul>
  )
}

export default Accordion
