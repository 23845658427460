import { MarkerType } from 'react-flow-renderer';

const userInputs = {
  'user_input_2': {
    'PT-BR': 'Resumo semanal',
    'EN-US': 'Weekly summary'
  },
  'user_input_3': {
    'PT-BR': 'Ajuda',
    'EN-US': 'Help'
  },
  'user_input_4': {
    'PT-BR': 'Quero conhecer',
    'EN-US': 'I want to meet'
  },
  'user_input_5': {
    'PT-BR': 'Destaques',
    'EN-US': 'highlights'
  }
}

const musicPlays = {
  'music_play_1': {
    'PT-BR': 'Tocando Juriscast',
    'EN-US': 'Playing Juriscast'
  },
  'music_play_2': {
    'PT-BR': 'Tocando Resumo semanal',
    'EN-US': 'Playing weekly summary'
  }
}

const createPodcastFlow = (flow: any, flowHandlers: any, selectedLanguage, nodes: any, edges: any) => {

  try {
    //!LaunchRequest
    nodes.push({
      id: 'LaunchRequest',
      data: {
        flowHandlers,
        intentName: 'LaunchRequest',
        intentId: flow['LaunchRequest']?.id,
        selectedLanguage,
        nodeHeight: 260
      },
      position: { x: -300, y: -100 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'LaunchRequest_2',
      data: {
        speak: flow['LaunchRequest']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['LaunchRequest']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'LaunchRequest',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 120
      },
      position: { x: 5, y: 55 },
      parentNode: 'LaunchRequest',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    //! HighlightsIntent

    nodes.push({
      id: 'user_input_5',
      data: { speak: userInputs['user_input_5'][selectedLanguage] },
      position: { x: -35, y: -250 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_5', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'HighlightsIntent',
      data: {
        flowHandlers,
        intentName: 'HighlightsIntent',
        intentId: flow['HighlightsIntent']?.id,
        selectedLanguage,
        nodeHeight: 360
      },
      position: { x: 150, y: -420 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'HighlightsIntent_2',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 80
      },
      position: { x: 5, y: 55 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'HighlightsIntent_4',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['4']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['4']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '4',
        nodeHeight: 80
      },
      position: { x: 5, y: 200 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_5`, target: 'HighlightsIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_5`, target: 'HighlightsIntent_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! JuriscastIntent

    nodes.push({
      id: 'user_input_1',
      data: { speak: 'juriscast' },
      position: { x: -35, y: -30 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'music_play_1',
      data: { speak: musicPlays['music_play_1'][selectedLanguage] },
      position: { x: 110, y: -30 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'MusicPlayNode',
    })

    edges.push({ source: `user_input_1`, target: 'music_play_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! WeeklySummaryIntent

    nodes.push({
      id: 'user_input_2',
      data: { speak: userInputs['user_input_2'][selectedLanguage] },
      position: { x: -35, y: 70 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'music_play_2',
      data: { speak: musicPlays['music_play_2'][selectedLanguage] },
      position: { x: 130, y: 70 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'MusicPlayNode',
    })

    edges.push({ source: `user_input_2`, target: 'music_play_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! HelpIntent

    nodes.push({
      id: 'user_input_3',
      data: { speak: userInputs['user_input_3'][selectedLanguage] },
      position: { x: -35, y: 200 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'HelpIntent',
      data: {
        flowHandlers,
        intentName: 'HelpIntent',
        intentId: flow['HelpIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 90, y: 170 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'HelpIntent_1',
      data: {
        speak: flow['HelpIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['HelpIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'HelpIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 80
      },
      position: { x: 5, y: 55 },
      parentNode: 'HelpIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_3`, target: 'HelpIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! AboutIntent

    nodes.push({
      id: 'user_input_4',
      data: { speak: userInputs['user_input_4'][selectedLanguage] },
      position: { x: -35, y: 450 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_2`, target: 'user_input_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'AboutIntent',
      data: {
        flowHandlers,
        intentName: 'AboutIntent',
        intentId: flow['AboutIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 150, y: 450 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'AboutIntent_1',
      data: {
        speak: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'AboutIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 80
      },
      position: { x: 5, y: 55 },
      parentNode: 'AboutIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_4`, target: 'AboutIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })



    return { nodes, edges }
  } catch (error) {
    console.log(error);
    return {}
  }
}

export default createPodcastFlow