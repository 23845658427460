import { Card, CardContent } from '@material-ui/core'
import { useContext, useState } from 'react'
import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import { AuthContext } from '../../contexts/AuthContext'
import { sendEmail } from '../../utils/sendEmail'
import { useHistory } from 'react-router-dom'

export default function Upgrade() {
  const [isLoading, setIsLoading]: any = useState(false)
  const history = useHistory()

  const { getUserEmail } = useContext(AuthContext)

  async function handleChoosePlan(plan: string) {
    let route: string
    switch (plan) {
      case 'free':
        route = '/vtex-free'
        break
      case 'basic':
        route = '/vtex-basic'
        break
      case 'advanced':
        route = '/vtex-advanced'
        break
      default:
        break
    }
    localStorage.setItem('planRoute', route)
    history.push('/create-ecommerce-template')
  }

  return (
    <Layout>
      {isLoading && <Loading />}
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card
            className="plan"
            onClick={() => console.log('click')}
            style={{
              display: 'flex',
              width: '18.75rem',
              height: '25rem',
              marginTop: '50px',
              marginLeft: '50px',
              marginRight: '30px',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <h5 style={{ alignItems: 'center', display: 'block' }}>Plan Free</h5>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">About</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Contacts</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Highlights</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div>
                {' '}
                <button
                  type="submit"
                  className="btn btn-hero-primary mt-12"
                  onClick={() => handleChoosePlan('free')}
                >
                  <i className="fa fa-fw fa-check" />
                  Choose
                </button>
              </div>
            </CardContent>
          </Card>
          <Card
            className="plan"
            onClick={() => console.log('click')}
            style={{
              display: 'flex',
              width: '18.75rem',
              height: '25rem',
              marginTop: '50px',
              marginLeft: '50px',
              marginRight: '30px',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <h5 style={{ alignItems: 'center', display: 'block' }}>Plan Basic</h5>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Highlights</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Last orders</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Add to shopping cart</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Sent shopping cart by e-mail</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Others</h6>
                </i>
              </div>
              <div>
                {' '}
                <button
                  type="submit"
                  className="btn btn-hero-primary mt-12"
                  onClick={() => handleChoosePlan('basic')}
                >
                  <i className="fa fa-fw fa-check" />
                  Choose
                </button>
              </div>
            </CardContent>
          </Card>
          <Card
            className="plan"
            onClick={() => console.log('click')}
            style={{
              display: 'flex',
              width: '18.75rem',
              height: '25rem',
              marginTop: '50px',
              marginLeft: '50px',
              marginRight: '30px',
              alignItems: 'center',
            }}
          >
            <CardContent
              style={{
                textAlign: 'center',
              }}
            >
              <div>
                <h5 style={{ alignItems: 'center', display: 'block' }}>Plan Advanced</h5>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>{' '}
              <div style={{ display: 'block' }}>
                <i style={{ display: 'flex' }} className="fa fa-fw fa-check ml-2 mr-4">
                  <h6 className="ml-2">Offer</h6>
                </i>
              </div>
              <div>
                {' '}
                <button
                  type="submit"
                  className="btn btn-hero-primary mt-12"
                  onClick={() => handleChoosePlan('advanced')}
                  disabled={true}
                >
                  <i className="fa fa-fw fa-check" />
                  Soon
                </button>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </Layout>
  )
}
