import axios from 'axios'

const { REACT_APP_URL_EMAIL_API, REACT_APP_EMAIL_API_KEY } = process.env

export async function sendEmail({ emailTo, subject, text }) {
  try {
    const { data } = await axios.post(
      `${REACT_APP_URL_EMAIL_API}/envelope`,
      {
        emailTo,
        subject,
        text,
      },
      {
        headers: {
          authorization: REACT_APP_EMAIL_API_KEY,
        },
      }
    )

    return true
  } catch (error) {
    console.log('Email Error', error.message)
    return false
  }
}
