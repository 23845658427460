import { Box, Card, CardContent, Typography, IconButton } from '@material-ui/core'
import Layout from '../../components/Layout'
import { Add } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import getAccounts from '../../utils/getAccounts'
import Loading from '../../components/Loading'
import AccountCard from '../../components/AccountCard'
import './styles.css'
import { AccountTree } from '@mui/icons-material'

export default function Homepage() {
  const history = useHistory()
  const [accounts, setAccounts]: any = useState([])
  const [isLoading, setIsLoading]: any = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAccounts()
      setAccounts(result)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  // const { accounts }: any = useGlobalContext()

  async function handleClick(accountId: any) {
    await localStorage.setItem('selectedProjectId', accountId)
    await localStorage.removeItem('selectedAppId')
    await localStorage.removeItem('selectedAppName')

    history.push(`/accounts/${accountId}`)
  }

  return (
    <Layout>
      <div className="accounts-icon">
        <AccountTree className="mr-2" style={{ color: '#0665d0' }} /> Projects
      </div>

      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          {accounts.length < 1 && (
            <Box display={`inline`}>
              <Card
                className="project-card"
                onClick={() => history.push('/create-account')}
                style={{
                  width: '300px',
                  height: '300px',
                  marginTop: '50px',
                  marginLeft: '50px',
                  marginRight: '30px',
                  alignItems: 'center',
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="center">
                    {accounts ? 'Adicionar Projeto' : 'Você não possui nenhum projeto'}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconButton>
                      <Add style={{ fontSize: '70px' }} />
                    </IconButton>
                  </div>
                  <Typography color="textSecondary" align="center">
                    Clique para adicionar
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
          <div className="account-cards-container">
            {accounts.map((account: any) => {
              return (
                <AccountCard
                  text={account.name}
                  onClick={() => handleClick(account.id)}
                  key={account.id}
                />
              )
            })}
          </div>
        </div>
      )}
    </Layout>
  )
}
