import { FormEvent, useEffect, useState } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import { useAPI } from '../../services/converstionalApi'
import getMissingString from '../../utils/getMissingString'
import getStringTag, { getLoopTag } from '../../utils/getStringTag'
import IntentNode from '../IntentNode'
import Loading from '../Loading'
import SayButton from '../SayButton'
import './styles.css'

function AssistantOutputNode({ data, sourcePosition, targetPosition }: any) {
  const [speak, setSpeak] = useState(data.speak)
  const [speakId, setSpeakId] = useState(data.speakId)
  const [isLoading, setIsLoading]: any = useState(false)
  const [invalidInput, setInvalidInput]: any = useState(false)
  const [invalidInputExcess, setInvalidInputExcess]: any = useState(false)
  const [invalidLoopInput, setInvalidLoopInput]: any = useState(false)
  const [tag, setTag]: any = useState()
  const [loopTag, setLoopTag]: any = useState()
  const [tagExcessive, setTagExcessive]: any = useState()

  const api = useAPI()
  const { flow, setFlow } = data.flowHandlers

  useEffect(() => {
    setSpeak(data.speak)
    setSpeakId(data.speakId)
  }, [data.selectedLanguage])

  useEffect(() => {
    const initial = getStringTag(data.speak)
    const target = getStringTag(speak)

    const initialLoop = getLoopTag(data.speak)
    const targetLoop = getLoopTag(speak)

    let difference = initial.filter((x) => !target.includes(x))
    let tagExcess = target.filter((x) => !initial.includes(x))

    let LoopDifference = initialLoop.filter((x) => !targetLoop.includes(x))

    if (target.length > initial.length) {
      console.log('entra aqui')
      setTagExcessive(getMissingString(tagExcess))
      setInvalidInput(false)
      setInvalidInputExcess(true)
      setInvalidLoopInput(false)
    } else if (difference.length > 0) {
      setTag(getMissingString(difference))
      setInvalidInput(true)
      setInvalidInputExcess(false)
      setInvalidLoopInput(false)
    } else if (LoopDifference.length > 0) {
      setLoopTag(getMissingString(LoopDifference))
      setInvalidLoopInput(true)
      setInvalidInput(false)
      setInvalidInputExcess(false)
    } else {
      setInvalidInput(false)
      setInvalidInputExcess(false)
      setInvalidLoopInput(false)
    }
  }, [data.speak, speak])

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      const appId = localStorage.getItem('selectedAppId')

      await api.patch(`apps/${appId}/phrase/${speakId}`, {
        value: speak,
      })

      flow[data.intentName].conditionals[data.conditionalKey][data.selectedLanguage].phrase = speak
      setFlow(flow)
      console.log('Aqui', { data })
      console.log('OK!')
      setIsLoading(false)
    } catch (error) {
      console.log('ERROR')
      console.log(error)
    }
  }

  return (
    <>
      <div className="assistant-output-node">
        {sourcePosition === 'left' && <Handle type="target" position={Position.Left} />}
        {targetPosition === 'left' && <Handle type="source" position={Position.Left} />}
        {sourcePosition === 'right' && <Handle type="target" position={Position.Right} />}
        {targetPosition === 'right' && <Handle type="source" position={Position.Right} />}
        {sourcePosition === 'bottom' && <Handle type="target" position={Position.Bottom} />}
        {targetPosition === 'bottom' && <Handle type="source" position={Position.Bottom} />}
        {sourcePosition === 'top' && <Handle type="target" position={Position.Top} />}
        {targetPosition === 'top' && <Handle type="source" position={Position.Top} />}

        <div>
          {/* <label htmlFor="text">{data.intentName}</label> */}
          {/* <small>🔊 {data.flow['SearchItemIntent']?.conditionals['PT-BR']['1']?.phrase}</small> */}
          {/* <small>🔊 {data.speak}</small> */}
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              <div
                style={{
                  alignContent: 'center',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  textAlign: 'center',
                  backgroundColor: '#6995C7',
                }}
              >
                <span style={{ color: '#FFF', fontSize: '1.1rem', margin: '1rem' }}>
                  {data.intentName}
                </span>
                <hr style={{ marginBottom: '6px', marginTop: '6px', backgroundColor: '#0665D0' }} />
              </div>
              <SayButton speak={speak}>🔊</SayButton>
              <form onSubmit={submit}>
                <textarea
                  value={speak}
                  onChange={(e) => setSpeak(e.target.value)}
                  style={{ height: data.nodeHeight ? `${data.nodeHeight}px` : '100px' }}
                />
                {/*                 {(invalidInput ||
                  invalidInputExcess ||
                  invalidLoopInput ||
                  invalidInputLoopExcess) && (
                  <div>
                    <br />
                    <span style={{ fontSize: 8 }}>Erro</span>
                  </div>
                )} */}
                {/* <>{console.log({ invalidInput, invalidInputExcess, invalidLoopInput })}</> */}
                <button
                  type="submit"
                  disabled={invalidInput || invalidInputExcess || invalidLoopInput}
                  style={{
                    backgroundColor:
                      invalidInput || invalidInputExcess || invalidLoopInput ? '#F83E3E' : '',
                  }}
                >
                  {invalidInput
                    ? `Tag ${tag} is required`
                    : invalidLoopInput
                    ? `Tag ${loopTag} must be between <loop> tag`
                    : invalidInputExcess
                    ? `Tag ${tagExcessive} is not required`
                    : 'Update'}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AssistantOutputNode
