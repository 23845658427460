import { useEffect, useState } from 'react'
// import { AuthContext } from "../../contexts/AuthContext";
import axios from 'axios'
import { Link } from 'react-router-dom'
import Flow from '../../components/Flow'
import { NewHighlightModal } from '../../components/HighlightModal'
import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { useAPI } from '../../services/api'
import createFlow from '../../utils/createFlow'

const { REACT_APP_API_URL, REACT_APP_APPS_API } = process.env

interface Intent {
  id: string
  name: string
  conditionals: Conditional[]
}

interface Conditional {
  key: string
  va_type: string
  language: string
  redirect_to: string
  end_session: boolean
  speak?: Speak
  reprompt?: Reprompt
  card?: Card
}

interface Speak {
  id: string
  description: string
  language: string
  phrases_speeches: PhrasesSpeeches[]
}

interface PhrasesSpeeches {
  index: number
  phrase: Phrase
}

interface Phrase {
  value: string
  id: string
  variations: string[]
}
interface Reprompt {
  id: string
  description: string
  language: string
  phrases_speeches: PhrasesSpeeches
}

interface Card {
  speechIdTitle: string
  speechIdText: string
  language: string
  imageUrl: string
  text: string
}

export default function Attributes() {
  const [intents, setIntents] = useState<Intent[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isDataSetted, setIsDataSetted] = useState(false)
  const [isNewHighlightModalOpen, setIsNewHighlightModalOpen] = useState(false)
  const [flow, setFlow] = useState(null)
  const [appType, setAppType] = useState(null)
  const [defaultLanguage, setDefaultLanguage] = useState(null)

  const api = useAPI()

  function handleOpenNewHighlightModal() {
    setIsNewHighlightModalOpen(true)
  }

  function handleCloseNewHighlightModal() {
    setIsNewHighlightModalOpen(false)
  }

  const { selectedAppId, selectedLanguage, setSelected }: any = useGlobalContext()

  async function handleLoadContent() {
    try {
      if (!isLoading) setIsLoading(true)

      const { data } = await axios.get(
        `${REACT_APP_API_URL}/apps/${selectedAppId}/intent/all-attributes`,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )
      const flow = await createFlow(data)

      const { data: properties } = await axios.get(
        `${REACT_APP_APPS_API}/${selectedAppId}/properties`,
        {
          headers: {
            authorization: localStorage.getItem('Authorization'),
          },
        }
      )

      console.log(data)

      const appType = properties.filter((property) => {
        return property.name === 'TYPE'
      })[0]?.value

      const defaultLanguage = properties.filter((property) => {
        return property.name === 'DEFAULT_LANGUAGE'
      })[0]?.value

      setDefaultLanguage(defaultLanguage)
      setAppType(appType)
      setFlow(flow)
      console.log(flow)
      setIntents(data)
      setIsDataSetted(true)
      setIsLoading(false)
      /*         api.get<any[]>(`apps/${selectedAppId}/intent/all-attributes`)
    .then((response) => {
    }); */
    } catch (e) {
      console.log(e)
    }
  }

  function handleDelete(id: string) {
    try {
      api.delete(`apps/${selectedAppId}/intent/${id}`).then(() => {
        handleLoadContent()
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleLoadContent()
  }, [isDataSetted])

  // console.log({ intents })

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="bg-body-light">
          <div className="content content-full">
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                <i className="si si-bubbles" style={{ color: '#0665d0' }}></i> Intents & Speeches
              </h1>

              <select
                name="select"
                className="btn btn-hero-primary my-2 mx-3"
                defaultValue={defaultLanguage}
                onChange={(e) => setDefaultLanguage(e.target.value)}
              >
                <option value="PT-BR">PT-BR</option>
                <option value="EN-US">EN-US</option>
              </select>

              {/* <Link className="btn btn-hero-primary my-2" to={{ pathname: '/speech' }} style={{ margin: '10px' }}>
              <i className="fa fa-microphone-alt"></i> SPEECH
            </Link> */}
              <Link
                className="btn btn-hero-primary my-2"
                to={{ pathname: `/${selectedAppId}/highlights` }}
              >
                <i className="fa fa-microphone-alt"></i> APP HIGHLIGHTS
              </Link>
            </div>
          </div>
        </div>
      )}
      <Flow
        flowHandlers={{ flow, setFlow }}
        intents={intents}
        setIntents={setIntents}
        appType={appType}
        selectedLanguage={defaultLanguage}
      />
      <NewHighlightModal
        isOpen={isNewHighlightModalOpen}
        onRequestClose={handleCloseNewHighlightModal}
      />
    </Layout>
  )
}
