import axios, { AxiosError } from "axios";
import cookie from "js-cookie";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
const { REACT_APP_UI_USERS_API_URL } = process.env;

export function useAPI(apiURL?: any) {
  const { signOut } = useContext(AuthContext);

  const api = axios.create({
    baseURL: apiURL || REACT_APP_UI_USERS_API_URL,
    headers: {
      Authorization: `${localStorage.getItem('Authorization')}`
    }
  });

  api.interceptors.response.use(response => response, (error: AxiosError) => {
    if (error.response?.status === 401) {
      signOut();
    }

    return Promise.reject(error);
  });

  return api;
}