import { FormEvent, useContext, useEffect, useState } from "react";

import Layout from "../../components/Layout";

import { useAPI } from "../../services/converstionalApi";
import { AuthContext } from "../../contexts/AuthContext";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface ParamsProps {
  id: string;
}

export default function AddVariation() {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState('');
  const [message, setMessage] = useState('');

  const api = useAPI();
  const { user } = useContext(AuthContext);

  const { id } = useParams<ParamsProps>();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      await api.post(`apps/${user.currentAppId}/phrase/${id}/variation`, {
        value,
      });

      toast.success('Variation saved successfully!');
    } catch (err) {
      toast.error(`An error ocurred: ${err.response.data.message}`);
    }
  }

  useEffect(() => {

    async function getData() {
      const response = await api.get(`apps/${user.currentAppId}/phrase/${id}`)

      if (response.data) {
        setMessage(response.data.value);
      }
      setIsLoading(false);
    }

    try {
      getData();

    } catch (e) {
      console.log(e);
    }

  }, [])

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">Add New Phrase</h1>
            <Link to={{ pathname: "/phrase" }} className="btn btn-light">
              <i className="si si-action-undo"></i> <span className="d-none d-sm-inline-block">Back</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="block block-rounded">
          <div className="block-header block-header-default">
            <h3 className="block-title">
              Fill in the fields below to create a new dialog.
              <i className="fa fa-info-circle text-primary"></i>
            </h3>
          </div>

          <div className="row push">
            <div className="col-lg-8 col-xl-8">
              <div className="block-content">
                <form className="js-validation-signin" onSubmit={handleSubmit}>
                  <div className="form-group form-row">
                    <div className="col-4">
                      <label htmlFor="example-text-input">Application</label>
                      <select className="form-control" id="idApp" name="idApp">
                        <option>--</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="example-text-input">Language</label>
                      <select className="form-control" id="idLanguage" name="idLanguage">
                        <option>--</option>
                        <option value="1">Portuguese</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="example-text-input">Dialog Type</label>
                      <select disabled className="form-control" id="type" name="type">
                        <option value="text">Text</option>
                        <option value="image_url">Image URL</option>
                        <option value="audio_url">Audio URL</option>
                      </select>
                    </div>
                  </div>

                  <div id="normal-message">
                    <div className="form-group">
                      {isLoading ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <>
                          <label htmlFor="example-text-input">Message</label>
                          <textarea value={message} className="form-control code" disabled rows={2} placeholder="..." id="message" name="message"></textarea>
                        </>
                      )
                      }
                    </div>
                  </div>

                  <div id="normal-message">
                    <div className="form-group">
                      <label htmlFor="example-text-input">Variation</label>
                      <textarea onChange={(e) => setValue(e.target.value)} value={value} className="form-control code" rows={2} placeholder="..." id="message" name="message"></textarea>
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-hero-danger mt-2 mb-2">
                      Save Variation
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}