import { useEffect, useState } from 'react'
// import { AuthContext } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom'
import { useAPI } from '../../services/api'
import Layout from '../../components/Layout'
import { useGlobalContext } from '../../contexts/GlobalContext'
import axios from 'axios'

const { REACT_APP_API_URL } = process.env
interface Intent {
    id: string
    name: string
}

export default function Intent() {
    const [intents, setIntents] = useState<Intent[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const api = useAPI()

    // const { user } = useContext(AuthContext);
    const { selectedAppId }: any = useGlobalContext()

    async function handleLoadContent() {
        try {
            if (!isLoading) setIsLoading(true)

            const { data } = await axios.get(`${REACT_APP_API_URL}/apps/${selectedAppId}/intent`, {
                headers: {
                    authorization: localStorage.getItem('Authorization'),
                },
            })
            setIntents(data)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    console.log(intents)
    function handleDelete(id: string) {
        try {
            api.delete(`apps/${selectedAppId}/intent/${id}`).then(() => {
                handleLoadContent()
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handleLoadContent()
    }, [selectedAppId])

    return (
        <Layout>
            <div className="bg-body-light">
                <div className="content content-full">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                        <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
                            <i className="si si-bubbles" style={{ color: '#0665d0' }}></i> Intents &
                            Speeches
                        </h1>
                        <Link className="btn btn-hero-primary my-2" to={{ pathname: '/speech' }}>
                            <i className="fa fa-microphone-alt"></i> SPEECH
                        </Link>
                    </div>
                </div>
            </div>

            <div className="content">
                <h2 className="content-heading pt-0">
                    App: <strong>Teste</strong>
                </h2>
                <div className={`block block-themed ${isLoading && 'block-mode-loading'}`}>
                    <div className="block-header bg-primary-dark">
                        <h3 className="block-title">Registered intents</h3>
                        <div className="block-options">
                            <button
                                type="button"
                                className="btn-block-option"
                                data-toggle="block-option"
                                onClick={handleLoadContent}
                                data-action-mode="demo"
                            >
                                <i className="si si-refresh"></i>
                            </button>
                        </div>
                    </div>
                    <div className="block-content">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Intent</th>
                                    <th className="text-center" style={{ width: 70 }}></th>
                                    <th className="text-center" style={{ width: 70 }}>
                                        Edit
                                    </th>
                                    <th className="text-center" style={{ width: 70 }}>
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {intents.map((intent) => (
                                    <tr key={intent.id}>
                                        <td className="font-w600">
                                            <Link
                                                to={{
                                                    pathname: `/intent/edit/${intent.id}`,
                                                }}
                                                className="btn link-fx"
                                                data-toggle="tooltip"
                                                title="Click to edit"
                                            >
                                                {intent.name}
                                            </Link>
                                        </td>
                                        <td className="text-center"></td>
                                        <td className="text-center">
                                            <Link
                                                to={{
                                                    pathname: `/intent/edit/${intent.id}`,
                                                }}
                                                className="btn text-primary"
                                                data-toggle="tooltip"
                                                title="Manage Speeches"
                                            >
                                                <i className="fa fa-pencil-alt"></i>
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <a
                                                onClick={() => handleDelete(intent.id)}
                                                className="btn text-primary"
                                                data-toggle="tooltip"
                                                title="Delete Intent"
                                            >
                                                <i className="fa fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-2 text-center" style={{ width: '100%' }}>
                        <Link
                            className="btn btn-hero-primary my-2 text-center"
                            to={{ pathname: '/intent/add' }}
                        >
                            <i className="si si-plus mr-1"></i>
                            <span>Add Intent</span>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
