import axios from 'axios'
import { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../components/Layout'
import { useGlobalContext } from '../../contexts/GlobalContext'
import { isPropertyVisible } from '../../utils/appProperties'
import './styles.css'
const { REACT_APP_APPS_API } = process.env

interface IPropertiesType {
  name: string
  value: string
  isVisible: boolean
}

export default function CreateFreeTemplateWithFaq() {
  const history = useHistory()
  const [appName, setAppName] = useState('')
  const [brandName, setBrandName] = useState<IPropertiesType>()
  const [defaultLanguage, setDefaultLanguage] = useState<IPropertiesType>()
  const [properties, setProperties] = useState<IPropertiesType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { selectedProjectId }: any = useGlobalContext()
  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsLoading(true)

    let newArr = properties

    defaultLanguage
      ? newArr.push(defaultLanguage as IPropertiesType)
      : newArr.push({ name: 'DEFAULT_LANGUAGE', value: 'PT-BR', isVisible: true })

    brandName
      ? newArr.push(brandName as IPropertiesType)
      : newArr.push({ name: 'BRAND_NAME', value: '', isVisible: true })

    newArr.push({ name: 'HIGHLIGHTS_FIRST', value: 'true', isVisible: true })

    setProperties(newArr)

    const data = {
      appName,
      accountId: localStorage.getItem('selectedProjectId'),
      properties,
      templateName: 'FREE-WITH-FAQ',
      templateVersion: '0.1.0',
    }

    try {
      axios.post(`${REACT_APP_APPS_API}/app`, data, {
        headers: {
          authorization: localStorage.getItem('Authorization'),
        },
      })
      setTimeout(() => {
        setIsLoading(false)
        history.push(`/accounts/${selectedProjectId}`)
      }, 5000)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  function handleChange(e: FormEvent) {
    return {
      name: (e.target as HTMLInputElement).name,
      value: (e.target as HTMLInputElement).value,
      isVisible: isPropertyVisible((e.target as HTMLInputElement).name),
    }
  }

  return (
    <Layout>
      <div className="p-3 w-100">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-xl-6">
            <form className="js-validation-signin" onSubmit={handleSubmit}>
              <div className="py-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-lg mt-2"
                    id="appName"
                    name="appName"
                    placeholder="App Name"
                    value={appName}
                    required={true}
                    minLength={4}
                    onChange={(e) => setAppName(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control form-control-lg mt-2"
                    id="brandName"
                    name="BRAND_NAME"
                    placeholder="Brand Name"
                    onChange={(e) => setBrandName(handleChange(e))}
                    value={brandName?.value}
                  />
                  <div className="justify-content-end mt-2">
                    <label htmlFor="example-text-input">Default Language</label>
                    <select
                      onChange={(e) => setDefaultLanguage(handleChange(e))}
                      defaultValue="PT-BR"
                      className="form-control"
                      id="defaultLanguage"
                      name="DEFAULT_LANGUAGE"
                      value={defaultLanguage?.value}
                    >
                      <option value="" selected={true} disabled>
                        App Default Language
                      </option>
                      <option value="PT-BR">Portuguese Brasil</option>
                      <option value="EN-US">English</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary">
                    Create Template
                    {isLoading && (
                      <div>
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
