import { useEffect, useState } from 'react'
// import { AuthContext } from "../../contexts/AuthContext";
import { Link } from 'react-router-dom'

import Layout from '../../components/Layout'
import { useGlobalContext } from '../../contexts/GlobalContext'
import axios from 'axios'
import { Question } from 'phosphor-react'

const { REACT_APP_FEED_API } = process.env

interface FaqsProps {
  id: string
  title: string
  text: string
  ts: number
}
export default function FAQs() {
  const [faqs, setFaqs] = useState<FaqsProps[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [active, setButtonActive] = useState(true)
  const { selectedAppId, selectedAppName }: any = useGlobalContext()

  async function handleLoadContent(page) {
    try {
      if (!isLoading) setIsLoading(true)

      const { data } = await axios.get(`${REACT_APP_FEED_API}/feedItems/${selectedAppId}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('Authorization'),
        },
        params: {
          page: page,
          perPage: 5,
          types: 'FAQ',
        },
      })
      console.log('Data', data)
      setFaqs(data)
      setIsLoading(false)
    } catch (e) {
      console.log('Erro', { e })
    }
  }

  async function handleDelete(id: string) {
    try {
      if (!isLoading) setIsLoading(true)
      await axios
        .delete(`${REACT_APP_FEED_API}/feedItems/${selectedAppId}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('Authorization'),
          },
        })
        .then(() => {
          handleLoadContent(page)
        })
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleLoadContent(page)
  }, [selectedAppId, page])

  return (
    <Layout>
      <div className="bg-body-light">
        <div className="content content-full">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 className="flex-sm-fill font-size-h2 font-w400 mt-2 mb-0 mb-sm-2">
              <Question
                className="nav-main-link-icon fa-2x"
                style={{ color: '#0665d0' }}
              ></Question>{' '}
              Your FAQs
              {/* <i className="si si-star"></i> */}
            </h1>
            <Link className="btn btn-hero-primary my-2" to={{ pathname: '/new-faq' }}>
              <i className="fa fa-star"></i> New FAQ
            </Link>
          </div>
        </div>
      </div>

      <div className="content">
        <h2 className="content-heading pt-0">
          App: <strong>{selectedAppName}</strong>
        </h2>

        <div className={`block block-themed ${isLoading && 'block-mode-loading'}`}>
          <div className="block-header bg-primary-dark">
            <h3 className="block-title">Registered FAQs</h3>
            <div className="block-options">
              <button
                type="button"
                className="btn-block-option"
                data-toggle="block-option"
                onClick={handleLoadContent}
                data-action-mode="demo"
              >
                <i className="si si-refresh"></i>
              </button>
            </div>
          </div>
          <div className="block-content">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>FAQ</th>
                  <th className="text-center" style={{ width: 70, wordBreak: 'break-all' }}></th>
                  <th className="text-center" style={{ width: 70 }}>
                    Edit
                  </th>
                  <th className="text-center" style={{ width: 70 }}>
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {faqs.map((faq) => (
                  <tr key={faq.id}>
                    <td className="font-w600">
                      <Link
                        to={{
                          pathname: `/faq/edit/${faq.id}`,
                        }}
                        className="btn link-fx"
                        data-toggle="tooltip"
                        title="Click to edit"
                      >
                        {faq.title}
                      </Link>
                    </td>
                    <td className="text-center"></td>
                    <td className="text-center">
                      <Link
                        to={{
                          pathname: `/faq/edit/${faq.id}`,
                        }}
                        className="btn text-primary"
                        data-toggle="tooltip"
                        title="Edit FAQ"
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </Link>
                    </td>
                    <td className="text-center">
                      <span
                        onClick={() => handleDelete(faq.id)}
                        className="btn text-primary"
                        data-toggle="tooltip"
                        title="Delete FAQ"
                      >
                        <i className="fa fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td>
                    {' '}
                    <button
                      onClick={() => setPage(page - 1)}
                      className="btn text-primary"
                      data-toggle="tooltip"
                      title="Next Page"
                      disabled={page === 1}
                    >
                      <i className="fa fa-arrow-left"></i>
                    </button>
                  </td>
                  <td>
                    <p className="mt-2"> Page {page}</p>
                  </td>
                  <td>
                    {' '}
                    <button
                      onClick={() => setPage(page + 1)}
                      className="btn text-primary"
                      data-toggle="tooltip"
                      title="Next Page"
                      disabled={
                        (!faqs.length && page > 1) ||
                        (page >= 1 && faqs.length < 5) ||
                        (page === 1 && !faqs.length)
                      }
                    >
                      <i className="fa fa-arrow-right"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isLoading ||
        (!faqs.length && page === 1 && (
          <div className="row">
            <div className="mb-2 text-center lg" style={{ width: '100%' }}>
              <i className="si si-plus mr-1"></i>
              <span>Your app don't have FAQs</span>
            </div>
          </div>
        ))}
    </Layout>
  )
}
