import { MarkerType } from 'react-flow-renderer';

const userInputs = {
  'user_input_1': {
    'PT-BR': 'Destaques',
    'EN-US': 'Highlights'
  },
  'user_input_2': {
    'PT-BR': 'Menu',
    'EN-US': 'Menu'
  },
  'user_input_3': {
    'PT-BR': 'Ajuda',
    'EN-US': 'Help'
  },
  'user_input_4': {
    'PT-BR': 'Entrar em contato',
    'EN-US': 'Contact'
  },
  'user_input_5': {
    'PT-BR': 'Quero conhecer',
    'EN-US': 'I want to meet'
  }
}

const createFreeFlow = (flow: any, flowHandlers: any, selectedLanguage, nodes: any, edges: any) => {
  try {

    //!LaunchRequest
    nodes.push({
      id: 'LaunchRequest',
      data: {
        flowHandlers,
        intentName: 'LaunchRequest',
        intentId: flow['LaunchRequest']?.id,
        selectedLanguage,
        nodeHeight: 300
      },
      position: { x: -300, y: -100 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'LaunchRequest_1',
      data: {
        speak: flow['LaunchRequest']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['LaunchRequest']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'LaunchRequest',
        selectedLanguage,
        conditionalKey: '1',
      },
      position: { x: 5, y: 55 },
      parentNode: 'LaunchRequest',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    //! HighlightsIntent

    nodes.push({
      id: 'user_input_1',
      data: { speak: userInputs['user_input_1'][selectedLanguage] },
      position: { x: -10, y: -200 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'HighlightsIntent',
      data: {
        flowHandlers,
        intentName: 'HighlightsIntent',
        intentId: flow['HighlightsIntent']?.id,
        selectedLanguage,
        nodeHeight: 300
      },
      position: { x: 140, y: -350 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'HighlightsIntent_1',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 45
      },
      position: { x: 5, y: 55 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    nodes.push({
      id: 'HighlightsIntent_2',
      data: {
        speak: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phrase,
        speakId: flow['HighlightsIntent']?.conditionals[selectedLanguage]['2']?.phraseId,
        flowHandlers,
        intentName: 'HighlightsIntent',
        selectedLanguage,
        conditionalKey: '2',
        nodeHeight: 45
      },
      position: { x: 5, y: 170 },
      parentNode: 'HighlightsIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_1`, target: 'HighlightsIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_1`, target: 'HighlightsIntent_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! MenuIntent

    nodes.push({
      id: 'user_input_2',
      data: { speak: userInputs['user_input_2'][selectedLanguage] },
      position: { x: -10, y: 50 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_2', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'MenuIntent',
      data: {
        flowHandlers,
        intentName: 'MenuIntent',
        intentId: flow['MenuIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 0 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'MenuIntent_1',
      data: {
        speak: flow['MenuIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['MenuIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'MenuIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'MenuIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_2`, target: 'MenuIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! HelpIntent

    nodes.push({
      id: 'user_input_3',
      data: { speak: userInputs['user_input_3'][selectedLanguage] },
      position: { x: -10, y: 120 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_3', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })
    edges.push({ source: `user_input_3`, target: 'MenuIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })


    //! ContactIntent

    nodes.push({
      id: 'user_input_4',
      data: { speak: userInputs['user_input_4'][selectedLanguage] },
      position: { x: -60, y: 300 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_4', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'ContactIntent',
      data: {
        flowHandlers,
        intentName: 'ContactIntent',
        intentId: flow['ContactIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 250 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'ContactIntent_1',
      data: {
        speak: flow['ContactIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['ContactIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'ContactIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'ContactIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_4`, target: 'ContactIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    //! AboutIntent

    nodes.push({
      id: 'user_input_5',
      data: { speak: userInputs['user_input_5'][selectedLanguage] },
      position: { x: -60, y: 600 },
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'UserInputNode',
    })

    edges.push({ source: `LaunchRequest_1`, target: 'user_input_5', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    nodes.push({
      id: 'AboutIntent',
      data: {
        flowHandlers,
        intentName: 'AboutIntent',
        intentId: flow['AboutIntent']?.id,
        selectedLanguage,
        nodeHeight: 220
      },
      position: { x: 140, y: 500 },
      sourcePosition: 'right',
      targetPosition: 'left',
      type: 'IntentNode',
    })

    nodes.push({
      id: 'AboutIntent_1',
      data: {
        speak: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phrase,
        speakId: flow['AboutIntent']?.conditionals[selectedLanguage]['1']?.phraseId,
        flowHandlers,
        intentName: 'AboutIntent',
        selectedLanguage,
        conditionalKey: '1',
        nodeHeight: 75
      },
      position: { x: 5, y: 55 },
      parentNode: 'AboutIntent',
      sourcePosition: 'left',
      targetPosition: 'right',
      type: 'AssistantOutputNode'
    })

    edges.push({ source: `user_input_5`, target: 'AboutIntent_1', type: 'normal-edge', markerEnd: { type: MarkerType.ArrowClosed } })

    return { nodes, edges }
  } catch (error) {
    return {}
  }
}

export default createFreeFlow