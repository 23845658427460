import Layout from '../components/Layout'
import { FormEvent, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useGlobalContext } from '../contexts/GlobalContext'

const { REACT_APP_UI_USERS_API_URL } = process.env

export default function CreateAccount() {
    const history = useHistory()
    const [accountName, setAccountName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { accounts, setAccounts, setSelectedProjectId }: any = useGlobalContext()

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        setIsLoading(true)

        try {
            const { data }: any = await axios.post(
                `${REACT_APP_UI_USERS_API_URL}/accounts`,
                { accountName },
                {
                    headers: {
                        authorization: localStorage.getItem('Authorization'),
                    },
                }
            )

            setAccounts([...accounts, { name: data.accountName, id: data.accountId }])
            setSelectedProjectId(data.accountId)
            history.push('/homepage')
            // navigate('/templates');
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    return (
        <Layout>
            <div className="p-3 w-100">
                <div className="row no-gutters justify-content-center">
                    <div className="col-sm-8 col-xl-6">
                        <form className="js-validation-signin" onSubmit={handleSubmit}>
                            <div className="py-3">
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-align-justify"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="accountName"
                                            name="accountName"
                                            placeholder="Account Name"
                                            value={accountName}
                                            onChange={(e) => setAccountName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-hero-lg btn-hero-primary"
                                    >
                                        Create account
                                        {isLoading && (
                                            <div>
                                                <i className="fa fa-spinner fa-spin"></i>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
